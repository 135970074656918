/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import {
  APPLICATION_STATUS_CONTRACT_BASE,
  APPLICATION_STATUS_CONTRACT_DESCRIPTION,
  APPLICATION_STATUS_CONTRACT_DOCUMENTS,
  APPLICATION_STATUS_DONE,
  APPLICATION_STATUS_POWER_OF_ATTORNEY,
  APPLICATION_STATUS_SUMMARY,
} from "../applicationStatus";
import React from "react";
import { Navigate } from "react-router-dom";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import progressBarsteps from "./progressBarSteps";
import SummaryHintBox from "../../../components/Form/Common/SummaryHintBox";
import ContractBase from "../../../components/Form/ContractLaw/ContractBase";
import PowerOfAttorney from "../../../components/Form/ContractLaw/PowerOfAttorney";
import ContractDescription from "../../../components/Form/ContractLaw/ContractDescription";
import ContractDocuments from "../../../components/Form/ContractLaw/DocumentUploadForm/ContractDocuments";
import Summary from "../../../components/Form/ContractLaw/Summary/Summary";
import { getCaseLink } from "../../productService";
import ContractDocumentsWrapper from "../../../components/Form/ContractLaw/DocumentUploadForm/ContractDocumentsWrapper";
import PowerOfAttorneyIntroHintBox from "../../../components/Form/ContractLaw/PowerOfAttorneyIntroHintBox";

const formSteps = {
  [APPLICATION_STATUS_CONTRACT_BASE]: {
    title: "Antrag - Ihr Vertrag | Legalbird",
    applicationStatus: APPLICATION_STATUS_CONTRACT_BASE,
    stepUrl: "vertrag",
    render: (props) => (
      <>
        <ProgressBar steps={progressBarsteps} active={"contract"} progress={25} />
        <ContractBase {...props} hideCaseCategories={true} alternativeHeadline="Ihre Angaben" />
      </>
    ),
  },
  [APPLICATION_STATUS_CONTRACT_DESCRIPTION]: {
    title: "Antrag - Beschreibung | Legalbird",
    applicationStatus: APPLICATION_STATUS_CONTRACT_DESCRIPTION,
    stepUrl: "beschreibung",
    render: (props) => (
      <>
        <ProgressBar steps={progressBarsteps} active={"contract"} progress={25} />
        <ContractDescription {...props} />
      </>
    ),
  },
  [APPLICATION_STATUS_CONTRACT_DOCUMENTS]: {
    title: "Antrag - Dokumente | Legalbird",
    applicationStatus: APPLICATION_STATUS_CONTRACT_DOCUMENTS,
    stepUrl: "unterlagen",
    render: (props) => (
      <>
        <ProgressBar steps={progressBarsteps} active={"documents"} progress={75} />
        <ContractDocumentsWrapper {...props} DocumentsFormComponent={ContractDocuments} />
      </>
    ),
  },
  [APPLICATION_STATUS_SUMMARY]: {
    title: "Antrag - Überprüfen | Legalbird",
    applicationStatus: APPLICATION_STATUS_SUMMARY,
    stepUrl: "ueberpruefen",
    render: (props) => (
      <>
        <SummaryHintBox customer={props.customer} />
        <Summary {...props} />
      </>
    ),
  },
  [APPLICATION_STATUS_POWER_OF_ATTORNEY]: {
    title: "Antrag - Vollmacht | Legalbird",
    applicationStatus: APPLICATION_STATUS_POWER_OF_ATTORNEY,
    stepUrl: "vollmacht",
    render: (props) => <>
      <PowerOfAttorneyIntroHintBox {...props} />
      <PowerOfAttorney {...props} />
    </>,
  },
  [APPLICATION_STATUS_DONE]: {
    title: "Mein Vertrag | Legalbird",
    applicationStatus: APPLICATION_STATUS_DONE,
    stepUrl: "mein-vertrag",
    render: ({ product }) => <Navigate to={getCaseLink(product)} />,
  },
};

export default formSteps;
