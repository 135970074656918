/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import { Theme } from "@mui/material";

const bookACallStyle = {
  scrollToAnchor: {
    position: "relative",
    top: "-200px",
  },
  icon: (theme: Theme) => ({
    width: "128px",
    height: "128px",
    mt: 3,
    mb: 3,

    [theme.breakpoints.only("xs")]: {
      maxWidth: "90px",
      maxHeight: "90px",
    },
  }),
  submitButton: {
    marginTop: "3px",
    color: "primary",
    px: 5,
    mt: 3,
  },
};

export default bookACallStyle;
