/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import React, { FormEventHandler, useEffect } from "react";
import HintBox from "../../Box/HintBox/HintBox";
import Paper from "@mui/material/Paper";
import FrankRiedelImage from "../../../assets/img/jobs/frankriedel.jpg";
import Grid from "@mui/material/Grid";
import HighlightBox from "../../Box/HighlightBox/HighlightBox";
import ProbationFine from "./ProbationFine";
import PoaAgreementText from "../Common/PowerOfAttorney/PoaAgreementText";
import { useForm } from "../../../provider/Form/FormProvider";
import { useNavigate } from "react-router-dom";
import Signature from "../Common/Signature/Signature";
import powerOfAttorneyStyle from "./powerOfAttorneyStyle";
import ButtonLoading from "../../Button/ButtonLoading";
import Typography from "@mui/material/Typography";
import { getCaseLink } from "../../../services/productService";
import LegalExpenseInsuranceDisclaimer from "./LegalExpenseInsuranceDisclaimer";
import moment from "moment/moment";
import FormSubmitProps from "../../../types/FormSubmitProps";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import { FormChangeEvent, FormDataTypes } from "../../../types/FormDataTypes";
import { Box } from "@mui/material";

export default function PowerOfAttorney({ product, submitProps }: { product: AbstractCase; submitProps: FormSubmitProps }) {
  const { handleSubmit, values, handleChange }: FormDataTypes = useForm();
  const navigate = useNavigate();

  const { powerOfAttorney } = values;
  useEffect(() => {
    if (powerOfAttorney === null) {
      handleChange({
        target: {
          name: "powerOfAttorney",
          value: moment().format("DD.MM.YYYY"),
        },
      } as FormChangeEvent);
    }
  }, [powerOfAttorney]);

  const handlePoaSubmit: FormEventHandler<HTMLFormElement> = async (event: React.FormEvent<HTMLFormElement>) => {
    if (submitProps.isMinimal) {
      await handleSubmit(event);
      return;
    }

    await handleSubmit(event, { preventRedirect: true });
    navigate(getCaseLink(product) + "/fall-vollstaendig");
  };

  return (
    <>
      {!submitProps.isMinimal && (
        <HintBox headline={"Ihr Ergebnis"}>
          {product.fine && product.fine.hasResult ? (
            <HighlightBox textColor={"defaultText"} headline={""}>
              Sie müssen mit
              {product.fine.flensburgPoints === 1 && " einem Punkt und "}
              {product.fine.flensburgPoints > 1 && (
                <>
                  {" "}
                  {!product.fine.isExact && " bis zu "} {product.fine.flensburgPoints} Punkten und{" "}
                </>
              )}
              <>
                {" "}
                einer Zahlung von {!product.isExact && " bis zu "} {product.fine.fine} € rechnen!
              </>
              {product.fine.drivingBan > 0 && (
                <>
                  {" "}
                  Außerdem ist ein Fahrverbot {!product.fine.isExact ? " bis zu " : " von "} {product.fine.drivingBan === 1 ? "einem" : product.fine.drivingBan}{" "}
                  Monat
                  {product.fine.drivingBan > 1 && "en"} möglich.
                </>
              )}
              <br />
              <br />
              {product.driversLicensePeriod === "<2" && <ProbationFine traffic={product} />}
              {product.fine.fine > 50 || product.legalExpenseInsurance === true ? (
                <>
                  Sie haben gute Chancen darauf, dass Ihr Bescheid fehlerhaft ist oder die Strafe reduziert werden kann! Daher unsere Empfehlung: Jetzt
                  professionell gegen den Bescheid vorgehen!
                </>
              ) : (
                <>
                  In Ihrem Fall ist die Strafe recht gering. Einen Anwalt mit dem Fall zu beauftragen, ist daher nicht zwingend notwendig. Sollten Sie sich
                  trotzdem gegen den Bescheid wehren wollen, unterstützen wir Sie natürlich gerne!
                </>
              )}
            </HighlightBox>
          ) : (
            <p>
              Für Ihren Fall konnten wir noch kein genaues Ergebnis ermitteln. Wir werden uns in Kürze persönlich bei Ihnen melden und die möglichen Folgen
              besprechen.
            </p>
          )}
          <Typography sx={powerOfAttorneyStyle.nextStepsHeading}>
            <strong>So geht es weiter</strong>
          </Typography>
          <Grid container>
            <Grid item sm={2} xs={12} sx={powerOfAttorneyStyle.imgCircleContainer}>
              <Paper elevation={4} sx={powerOfAttorneyStyle.imageCircleContainer}>
                <img src={FrankRiedelImage} alt={"Rechtsanwalt Frank Riedel"} />
              </Paper>
            </Grid>
            <Grid item sm={10} xs={12} sx={powerOfAttorneyStyle.text}>
              <ol>
                <li>Sie beauftragen die Legalbird Rechtsanwälte, die langjährige Erfahrung im Verkehrsrecht haben.</li>
                {product.legalExpenseInsurance === true ? (
                  <li>Wir fordern Akteneinsicht an und leiten alle weiteren Schritte ein, um den Vorwurf gegen Sie abzuwehren, oder zu reduzieren.</li>
                ) : (
                  <li>
                    <p>
                      Es wird Akteneinsicht angefordert und alle weiteren Schritte eingeleitet, um den Vorwurf gegen Sie abzuwehren - hierfür fallen
                      Anwaltskosten von 250 € an.
                    </p>
                    <p>
                      <span style={powerOfAttorneyStyle.strongPrimary}>Wichtig zu wissen:</span> Sollte es zu einem Gerichtsverfahren kommen, entstehen weitere
                      Kosten. Selbstverständlich stimmen wir diesen Schritt mit Ihnen vorher ab.
                    </p>
                  </li>
                )}
              </ol>
            </Grid>
            <Grid item xs={12} sx={powerOfAttorneyStyle.highlightBoxContainer}>
              <LegalExpenseInsuranceDisclaimer insurance={product.insurance?.insurance} />
            </Grid>
          </Grid>
        </HintBox>
      )}

      <Paper sx={powerOfAttorneyStyle.paper} elevation={4}>
        <Box component={"form"} sx={powerOfAttorneyStyle.applicationForm} id={"application-form"} onSubmit={handlePoaSubmit}>
          <Typography variant={"h4"} sx={powerOfAttorneyStyle.subheadline}>
            Vollmacht
          </Typography>
          <div>
            <p>
              Hiermit erteile ich, {product.client.fullName}, {product.client.residenceAddress.streetAddress}, {product.client.residenceAddress.postalCode}{" "}
              {product.client.residenceAddress.addressLocality}, den Rechtsanwältinnen und Rechtsanwälten der Legalbird Rechtsanwälte Rechtsanwalts GmbH Michael
              Bick, Frank Riedel und Anna Wagner die Vollmacht zu meiner Verteidigung und Vertretung und zwar auch bei meiner Abwesenheit. Die Vollmacht gewährt
              unter Anerkennung aller gesetzlichen Befugnisse nach der StPO/dem OWiG das Recht,
            </p>
            <ol>
              <li>
                Rechtsmittel einzulegen und ganz bzw. teilweise zurückzunehmen oder auf sie zu verzichten sowie Rechtsmittel auf den Strafausspruch und Strafmaß
                zu beschränken
              </li>
              <li>Untervertreter - auch im Sinne des § 139 StPO - zu bestellen</li>
              <li>Anträge, soweit das Verfahren dazu Anlass gibt, zu stellen</li>
              <li>Außergerichtliche Vertretung gegenüber Behörden und anderen öffentlich-rechtlichen Institutionen zu übernehmen</li>
              <li>
                Gelder, Wertsachen und entsprechende Urkunden sowie sichergestellte Gegenstände in Empfang zu nehmen, soweit das Verfahren dazu Anlass gibt
              </li>
              <li>
                Zustellungen aller Art, insbesondere auch solche von Beschlüssen, Urteilen und Ladungen mit rechtlicher Wirkung für und gegen mich in Empfang zu
                nehmen.
              </li>
            </ol>
            <p>
              Mir ist bekannt, dass mit der Vollmacht eine Beauftragung zu meiner Verteidigung und damit eine Anwaltsvergütung entsteht, deren Höhe sich nach
              den gesetzlichen Vorschriften richtet.
            </p>
            <p>Die Bearbeitung meines Mandats erfolgt vorbehaltlich der Annahme durch die {product.lawFirm.name}.</p>
            <PoaAgreementText lawFirmName={product.lawFirm.name} />
          </div>
          <Signature product={product} person={product.client} />
          <Grid container>
            <Grid sx={powerOfAttorneyStyle.submitButtonContainer} item xs={12}>
              <ButtonLoading
                color="primary"
                variant="contained"
                type="submit"
                fullWidth={true}
                disabled={!product.client.signature}
                isLoading={submitProps.isLoading}
              >
                {product.legalExpenseInsurance === false ? "Jetzt Vollmacht erteilen und für 250 € kaufen" : "Vollmacht erteilen"}
              </ButtonLoading>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </>
  );
}
