import React, { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { FormHelperText } from "@mui/material";
import _get from "lodash/get";
import { TimePicker } from "@mui/x-date-pickers";
import { useForm } from "../../../../provider/Form/FormProvider";
import { formValue } from "../../../../services/formServiceFunctions";
import { translate, translateHelperTextIfExists } from "../../../../services/translations/translations";
import Grid from "@mui/material/Grid";
import FormLegend from "../../Common/FormLegend/FormLegend";
import { ValidatorType } from "../../../../types/ValidatorType";
import { requiredValidator, timeFieldDefault } from "../../../../services/validationRules";
import { FormDataTypes } from "../../../../types/FormDataTypes";

type TimeFieldConfiguredProps = {
  caseData: Record<string, any>;
  fieldPath: string;
  required?: boolean;
};

export default function TimeFieldConfigured({ caseData, fieldPath, required = false }: TimeFieldConfiguredProps) {
  const { values, errors, setError, handleChange, registerValidators }: FormDataTypes = useForm();

  const validators: Array<ValidatorType> = [timeFieldDefault];
  if (required) {
    validators.push(requiredValidator());
  }

  const error = !!_get(errors, fieldPath);
  const helperText = translateHelperTextIfExists(caseData, fieldPath);
  const question = translate(`${caseData.name}.${fieldPath}.question`, caseData, "");
  const label = translate(`${caseData.name}.${fieldPath}.label`, caseData);

  useEffect(() => {
    registerValidators(fieldPath, validators);
    return () => {
      registerValidators(fieldPath, []);
      setError(fieldPath, undefined);
    };
  }, [fieldPath]);

  return (
    <>
      {question && (
        <Grid item xs={12}>
          <FormLegend>{question}</FormLegend>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControl fullWidth sx={{ margin: "1rem 0 .3rem 0" }}>
          <TimePicker
            value={formValue(values, fieldPath)}
            onChange={(time) => handleChange({ target: { name: fieldPath, value: time } })}
            label={label}
            renderInput={(props) => (
              <TextField
                name={fieldPath}
                {...props}
                error={error}
                inputProps={{
                  ...props.inputProps,
                  placeholder: "hh:mm",
                }}
              />
            )}
          />
          <FormHelperText sx={error ? { color: "error.main" } : {}}>{_get(errors, fieldPath) || helperText}</FormHelperText>
        </FormControl>
      </Grid>
    </>
  );
}
