import React from "react";
import pageNavigationStyle from "./pageNavigationStyle";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { HashLink as Link } from "react-router-hash-link";
import _map from "lodash/map";
import _upperCase from "lodash/toUpper";
import { Box, Divider, List, ListItem, Theme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/system";

type PageNavigationProps = {
  navigationLinks: Array<any>;
  headerIcon: React.ComponentType<{ sx?: SxProps<Theme> }>;
  headline: string;
  subHeadline?: string;
  tertiaryHeadline?: string;
};

const PageNavigation = ({ navigationLinks, headerIcon, headline, subHeadline, tertiaryHeadline }: PageNavigationProps) => {
  const HeaderIconComponent = headerIcon;

  return (
    <Paper elevation={4} sx={pageNavigationStyle.navigationPaper}>
      <List disablePadding>
        <ListItem sx={pageNavigationStyle.headlineListItem}>
          <Grid container justifyContent={"center"} alignItems={"center"} wrap={"nowrap"}>
            <Grid item>
              <Box sx={pageNavigationStyle.imageContainer}>
                <HeaderIconComponent sx={pageNavigationStyle.headerIcon} />
              </Box>
            </Grid>
            <Grid item sx={pageNavigationStyle.headlineContainer}>
              <Typography variant={"h5"}>{headline}</Typography>
              <Box sx={pageNavigationStyle.subHeadline}>{subHeadline}</Box>
            </Grid>
          </Grid>
        </ListItem>
        {tertiaryHeadline && <Typography sx={pageNavigationStyle.tertiaryHeadline}>{tertiaryHeadline}</Typography>}
        {_map(navigationLinks, (navigationLink) => (
          <React.Fragment key={navigationLink.label}>
            <Divider />
            <ListItem
              sx={[pageNavigationStyle.listItem, pageNavigationStyle.navigationLink]}
              to={navigationLink.target}
              component={Link}
              secondaryAction={navigationLink.icon ?? null}
            >
              {_upperCase(navigationLink.label)}
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
};

export default PageNavigation;
