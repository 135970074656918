import Grid from "@mui/material/Grid";
import React from "react";
import { useForm } from "../../../../../provider/Form/FormProvider";
import Typography from "@mui/material/Typography";
import HighlightBox from "../../../../Box/HighlightBox/HighlightBox";
import { AbstractCase } from "../../../../../types/Entities/AbstractCase";

type LawsuitConsequencesProps = {
  product: AbstractCase;
};

const LawsuitConsequences = ({ product }: LawsuitConsequencesProps) => {
  const { values } = useForm();

  if (values.finalProcessFeedbackClient !== "lawsuit") {
    return null;
  }

  if (!product.insurance.iIWPI) {
    return (
      <Grid item xs={12} sx={{ marginBottom: 2 }}>
        <HighlightBox textColor={"defaultText"} textAlign={"center"}>
          <Typography>
            <strong>Wichtig: Kein Kostenrisiko für Sie!</strong>
          </Typography>
          <Typography>
            Sofern Sie den Klageweg bestreiten möchten, werden wir unmittelbar eine Deckungsanfrage bei Ihrer Rechtsschutzversicherung für das gerichtliche
            Verfahren stellen und uns auch bei Rückfragen seitens der Versicherung um alles weitere kümmern.
          </Typography>
          <Typography>
            <strong>Sollte die Rechtsschutzversicherung die Deckungszusage erteilen,</strong> fallen für Sie keine weiteren Kosten an und wir starten
            unmittelbar in das Klageverfahren. Auch ein ggf. vereinbarter Selbstbehalt fällt für Sie nicht an.
          </Typography>
          <Typography>
            <strong>Sollte die Rechtsschutzversicherung keine Deckungszusage erteilen,</strong> müssen Sie sich ebenfalls keine Sorgen machen. Wir beenden das
            Verfahren dann umgehend und es kommen keinerlei Kosten auf Sie zu.
          </Typography>
          <br />
        </HighlightBox>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} sx={{ marginBottom: 2 }}>
      <HighlightBox textColor={"defaultText"}>
        <p>
          {product.caseCategory === "defendClaim" && product.extrajudicialRecommendation !== "lawsuit" && (
            <>Sollten Sie eine Klage riskieren wollen, so sind damit bestimmte Aufwände verbunden, auf die wir hier hinweisen möchten:</>
          )}
          {product.caseCategory === "defendClaim" && product.extrajudicialRecommendation === "lawsuit" && (
            <>
              Sollten Sie sich unserer Empfehlung anschließen und eine Klage abwarten wollen, möchten wir Sie aber der Vollständigkeit halber noch auf die
              folgenden Punkte hinweisen:
            </>
          )}
          {product.caseCategory === "enforceClaim" && product.extrajudicialRecommendation !== "lawsuit" && (
            <>Sollten Sie klagen wollen, so sind damit bestimmte Aufwände verbunden, auf die wir hier hinweisen möchten:</>
          )}
          {product.caseCategory === "enforceClaim" && product.extrajudicialRecommendation === "lawsuit" && (
            <>
              Sollten Sie sich unserer Empfehlung anschließen und klagen wollen, möchten wir Sie aber der Vollständigkeit halber noch auf die folgenden Punkte
              hinweisen:
            </>
          )}
        </p>
        <ul>
          {product.legalExpenseInsurance && (
            <>
              {product.extrajudicialRecommendation !== "lawsuit" || product.acquisitionPartner == "hukCoburg" && (
                <li>
                  Die entstehenden Kosten des Gerichtsverfahrens werden zwar größtenteils von Ihrer Rechtssschutzversicherung getragen,{" "}
                  <strong>
                    Sie müssen jedoch im Falle eines Unterliegens den Selbstbehalt{" "}
                    {product.insurance.deductible > 0 && (
                      <>
                        in Höhe von{" "}
                        {product.insurance.deductible.toLocaleString("de-DE", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </>
                    )}{" "}
                    selber zahlen.
                  </strong>
                </li>
              )}
              <li>
                Es können sich gegebenenfalls die <strong>Beitragszahlungen Ihrer Rechtsschutzversicherung erhöhen</strong>
              </li>
            </>
          )}
          {!product.legalExpenseInsurance && (
            <>
              <li>
                Die entstehenden Kosten des Gerichtsverfahrens <strong>müssen vollständig von Ihnen übernommen werden</strong>, sollten Sie den Prozess
                verlieren. Dieses Kostenrisiko umfasst Ihre eigenen Anwaltskosten, die Anwaltskosten der Gegenseite und die Gerichtskosten und kann daher
                erheblich sein.
              </li>
            </>
          )}
          <li>Ein Gerichtsverfahren kann sich unter Umständen über Jahre hinziehen, weshalb die psychische Belastung für Sie berücksichtigt werden muss.</li>
          <li>
            Im weiteren Verfahren müssen Sie in der Regel <strong>persönlich vor Gericht erscheinen</strong>, sodass Sie sich hierfür entsprechend Urlaub nehmen
            müssen.
          </li>
        </ul>
      </HighlightBox>
    </Grid>
  );
};
export default LawsuitConsequences;
