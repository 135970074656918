import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconObstacleDamage = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g fill={getIconColor(active, theme)}>
        <path
          d="M231.686,192.833h-65.896c-1.381,0-2.5,1.119-2.5,2.5c0,0.002,0,0.004,0,0.006
		c0.004,1.379,1.121,2.494,2.5,2.494h65.896c1.379,0,2.496-1.115,2.5-2.494c0-0.002,0-0.004,0-0.006
		C234.186,193.952,233.066,192.833,231.686,192.833z"
        />
        <path
          d="M289.232,154.47h-63.113c-1.381,0-2.5,1.119-2.5,2.5s1.119,2.5,2.5,2.5h60.613v14.833v13.206
		c-0.232,2.669-4.596,4.908-6.113,5.485c-1.005,0.376-1.615,1.33-1.615,2.345c0,0.291,0.043,0.586,0.151,0.873
		c0.376,1.005,1.329,1.625,2.341,1.625c0.292,0,0.588-0.053,0.877-0.16c0.916-0.344,8.959-3.537,9.354-9.923
		c0.003-0.052,0.005-0.104,0.005-0.154v-30.63C291.732,155.589,290.613,154.47,289.232,154.47z"
        />
        <path
          d="M140.109,181.132c5.884,0,11.225,3.696,13.29,9.2c0.376,1.002,1.325,1.612,2.337,1.614
		c0.293,0,0.592-0.043,0.883-0.152c1.292-0.484,1.947-1.927,1.462-3.22c-2.794-7.441-10.017-12.442-17.972-12.442
		c-9.74,0-17.792,7.295-19.023,16.703h-10.338v-9.736c0.316-2.676,1.224-5.178,2.7-7.443c0.302-0.465,0.628-0.913,0.97-1.353
		c9.545-12.271,34.463-14.833,40.854-14.833h58.477c1.382,0,2.5-1.119,2.5-2.5v-16.708c0-1.381-1.118-2.5-2.5-2.5
		c-1.381,0-2.5,1.119-2.5,2.5v14.208h-52.584c5.013-4.554,12.58-11.487,16.01-14.913c4.967-4.97,8.06-7.313,14.939-7.313h58.657
		l15.047,15.336c0.966,0.986,2.55,1,3.535,0.033s1.001-2.55,0.033-3.535l-15.781-16.085c-0.47-0.479-1.113-0.749-1.784-0.749
		h-59.707c-9.009,0-13.341,3.639-18.477,8.775c-4.627,4.624-17.445,16.213-20.131,18.636c-11.346,0.881-33.25,5.146-41.752,18.273
		c-1.929,2.959-3.104,6.239-3.49,9.751c-0.012,0.091-0.017,0.183-0.017,0.273v12.38c0,1.382,1.119,2.5,2.5,2.5h12.836
		c1.23,9.413,9.283,16.712,19.025,16.712c7.974,0,15.196-5.012,17.974-12.471c0.481-1.294-0.177-2.732-1.471-3.215
		c-1.292-0.482-2.732,0.176-3.215,1.471c-2.053,5.512-7.393,9.215-13.288,9.215c-7.472,0-13.6-5.801-14.152-13.133
		c0.158-0.328,0.254-0.689,0.254-1.079c0-0.388-0.096-0.751-0.254-1.078C126.514,186.929,132.641,181.132,140.109,181.132z"
        />
        <path
          d="M258.213,181.132c5.882,0,11.222,3.697,13.289,9.2c0.377,1.002,1.33,1.613,2.338,1.615
		c0.293,0,0.592-0.044,0.882-0.154c1.293-0.484,1.946-1.927,1.461-3.219c-2.796-7.442-10.018-12.442-17.97-12.442
		c-10.591,0-19.207,8.615-19.207,19.207s8.616,19.207,19.207,19.207c7.976,0,15.197-5.012,17.973-12.47
		c0.48-1.295-0.178-2.732-1.471-3.215c-1.298-0.484-2.733,0.178-3.216,1.471c-2.051,5.513-7.39,9.214-13.286,9.214
		c-7.834,0-14.207-6.373-14.207-14.207S250.379,181.132,258.213,181.132z"
        />
        <path
          d="M102.58,164.781c1.428,0,2.585-1.157,2.585-2.585s-1.157-2.585-2.585-2.585h-7.334v-10.039l-8.867-13.982
		l-8.866,13.982v10.039H65.586v-10.039l-8.871-13.982l-8.866,13.982v10.039H35.924v-10.039l-8.871-13.982l-8.866,13.982v10.039
		h-7.334c-1.429,0-2.585,1.157-2.585,2.585s1.156,2.585,2.585,2.585h7.334v14.89h-7.334c-1.429,0-2.585,1.157-2.585,2.586
		c0,1.428,1.156,2.585,2.585,2.585h7.334v22.216h17.737v-22.216h11.925v22.216h17.737v-22.216h11.927v22.216h17.733v-22.216h7.334
		c1.428,0,2.585-1.157,2.585-2.585c0-1.429-1.157-2.586-2.585-2.586h-7.334v-14.89H102.58z M35.924,179.671v-14.89h11.924v14.89
		H35.924z M77.512,179.671H65.586v-14.89h11.926V179.671z"
        />
      </g>
      <g>
        {!active && (
          <path
            fill="#137F7B"
            d="M114.418,174.303c-0.342,0.438-0.668,0.888-0.97,1.353c-1.478,2.266-2.384,4.769-2.7,7.443v9.736h10.338
		c1.231-9.408,9.283-16.703,19.023-16.703c7.955,0,15.178,5.001,17.972,12.442c0.485,1.293-0.17,2.734-1.462,3.22
		c-0.291,0.109-0.59,0.152-0.883,0.152v3.393h7.553c0-0.003,0-0.005,0-0.007c0-1.381,1.119-2.5,2.5-2.5h65.896
		c1.381,0,2.5,1.119,2.5,2.5c0,0.002,0,0.005,0,0.007h4.82c0-10.592,8.615-19.207,19.207-19.207c7.951,0,15.173,5,17.969,12.442
		c0.486,1.292-0.168,2.733-1.461,3.219c-0.29,0.11-0.588,0.154-0.881,0.154v3.391h5.164c-0.002-1.014,0.609-1.968,1.614-2.344
		c1.519-0.577,5.882-2.816,6.113-5.485v-13.206H114.418L114.418,174.303z"
          />
        )}
        <path
          fill="#137F7B"
          d="M119.952,123.813c-0.174,0.795,0.052,1.625,0.603,2.225c0.55,0.6,1.352,0.896,2.166,0.787l12.719-1.666
		l-17.322,10.258c-0.793,0.472-1.264,1.339-1.224,2.262c0.041,0.921,0.585,1.744,1.416,2.145l11.866,5.694l-15.268,3.707
		c-1.342,0.325-2.165,1.678-1.84,3.02c0.277,1.145,1.301,1.911,2.428,1.911c0.195,0,0.395-0.023,0.592-0.071l22.074-5.36
		c1.027-0.25,1.786-1.118,1.896-2.169c0.109-1.052-0.452-2.059-1.404-2.516l-13.967-6.702l23.791-14.09
		c1.021-0.604,1.478-1.845,1.09-2.968c-0.389-1.122-1.52-1.82-2.688-1.662l-21.284,2.789l7.19-32.92
		c0.259-1.183-0.368-2.381-1.487-2.844c-1.117-0.46-2.408-0.057-3.062,0.964l-20.636,32.271L91.966,97.75
		c-0.702-0.949-1.975-1.275-3.048-0.787c-1.074,0.49-1.659,1.665-1.402,2.817l5.701,25.611c0.301,1.348,1.64,2.2,2.982,1.896
		c1.349-0.3,2.197-1.636,1.897-2.982l-3.142-14.108l10.784,14.572c0.489,0.661,1.279,1.056,2.095,1.012
		c0.822-0.028,1.578-0.459,2.021-1.152l15.061-23.551L119.952,123.813z"
        />
      </g>
    </SvgIcon>
  );
};
export default IconObstacleDamage;
