import React from "react";
import { nl2br } from "../../../../../services/formServiceFunctions";
import { Box, Grid } from "@mui/material";
import feedbackFormTenancyLawStyle from "../feedbackFormTenancyLawStyle";
import { useForm } from "../../../../../provider/Form/FormProvider";
import { AbstractCase } from "../../../../../types/Entities/AbstractCase";

type RecommendationProps = {
  product: AbstractCase;
  hasClaimStatusClient: boolean;
};

const Recommendation = ({ product, hasClaimStatusClient }: RecommendationProps) => {
  const { values } = useForm();
  if (values.clientAgrees === "yes" || values.finalClaimStatusClient === "fullyAgreed" || (hasClaimStatusClient && values.finalClaimStatusClient === "")) {
    return null;
  }

  if (product.extrajudicialRecommendation === "individualText") {
    return (
      <Grid item xs={12}>
        <Box sx={feedbackFormTenancyLawStyle.recommendation}>
          {product.extrajudicialRecommendationText && <p dangerouslySetInnerHTML={{ __html: nl2br(product.extrajudicialRecommendationText) }} />}
        </Box>
      </Grid>
    );
  }

  let recommendationText;
  switch (product.extrajudicialRecommendation) {
    case "lawsuit":
      if (product.caseCategory === "defendClaim") {
        recommendationText = (
          <p>
            Wir empfehlen Ihnen, die <strong>Forderung zunächst nicht zu erfüllen und eine Klage oder einen Mahnbescheid abzuwarten.</strong> Es kann durchaus
            sein, dass der Vermieter überhaupt keine weiteren rechtlichen Schritte gegen Sie einleitet. Sollte der Vermieter aber seine Forderung gegen Sie
            durchsetzen wollen, müsste er letztendlich eine Klage einreichen oder einen Mahnbescheid erwirken, was für den Vermieter auch mit einem Kostenrisiko
            verbunden ist. Unabhängig von dem, was der Vermieter machen wird, sollten Sie aus unserer Sicht erst einmal die Forderung nicht erfüllen, sondern
            abwarten. <strong>Sollte der Vermieter weitere rechtliche Schritte gegen Sie einleiten, werden wir selbstverständlich wieder für Sie tätig!</strong>
          </p>
        );
      }
      break;
    case "insufficientEvidence":
      recommendationText = (
        <p>
          In Ihrem Fall <strong>sind Sie vor Gericht in der Beweispflicht</strong> (nicht der Vermieter). Die Beweislage ist in Ihrem Fall leider sehr ungünstig
          und es ist unwahrscheinlich, dass wir vor diesem Hintergrund vor Gericht einen Erfolg erzielen werden.
        </p>
      );
      break;
    case "insufficientChancesOfSuccess":
      recommendationText = (
        <p>
          In der Gesamtbetrachtung Ihres Falles sehen wir die <strong>Erfolgsaussichten für ein Obsiegen vor Gericht als eher gering.</strong> Ihre rechtliche
          Position ist nicht besonders gut und wir halten es für wahrscheinlich, dass Sie das Verfahren daher verlieren könnten.
        </p>
      );
      break;
    case "costBenefitConsiderations":
      recommendationText = (
        <p>
          In der Gesamtbetrachtung Ihres Falles ist bei einem gerichtlichen Verfahren das Verhältnis von Kosten und Nutzen als eher schlecht zu bewerten. Selbst
          wenn Sie einen Erfolg vor Gericht erzielen sollten, steht dieser Erfolg in einem sehr ungünstigen Verhältnis zum Aufwand, den Sie hierdurch haben.
        </p>
      );
      break;
    default:
      return null;
  }

  return (
    <Grid item xs={12}>
      <Box sx={feedbackFormTenancyLawStyle.recommendation}>
        {recommendationText}
        {product.caseCategory === "defendClaim" && product.extrajudicialRecommendation !== "lawsuit" && (
          <p>
            <strong>Wir empfehlen Ihnen daher, die Forderung zu erfüllen,</strong> um eine Klage gegen Sie zu vermeiden.
          </p>
        )}
        {product.caseCategory === "enforceClaim" && product.extrajudicialRecommendation !== "lawsuit" && (
          <p>
            Wir empfehlen Ihnen daher, Ihre Forderungen <strong>nicht vor Gericht durchzusetzen und den Fall an dieser Stelle abzuschließen.</strong>
          </p>
        )}
        {product.caseCategory === "enforceClaim" && product.extrajudicialRecommendation === "lawsuit" && (
          <p>Um Ihre Forderungen durchzusetzen, empfehlen wir Ihnen, Klage einzureichen.</p>
        )}
        {product.extrajudicialRecommendationText && <p dangerouslySetInnerHTML={{ __html: nl2br(product.extrajudicialRecommendationText) }} />}
      </Box>
    </Grid>
  );
};

export default Recommendation;
