/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

const FlexAiChatAgentStyle = {
  button: {
    px: 3,
    justifyContent: "center",
    textAlign: "center",
  },
};

export default FlexAiChatAgentStyle;
