import React, { useEffect } from "react";
import wizardStyle from "../wizardStyle";
import { Box, Grid, Paper, Typography } from "@mui/material";
import FormFader from "../Common/Fader/FormFader";
import FormLegend from "../Common/FormLegend/FormLegend";
import FormSubmitProps from "../../../types/FormSubmitProps";
import { useForm } from "../../../provider/Form/FormProvider";
import FormSubmit from "../Common/FormSubmit/FormSubmit";
import Person from "../Common/Person/Person";
import Address from "../Common/Address/Address";
import ValidatorTextField from "../../FormFields/ValidatorTextField";
import { isIban, isPhoneNumber, isRequired, minStringLength, requiredFieldDefault, requiredValidator } from "../../../services/validationRules";
import InputAdornment from "@mui/material/InputAdornment";
import { IbanInput } from "../../FormFields/MaskedInputs/IbanInput";
import Group from "../../Wizard/Group";
import { translate } from "../../../services/translations/translations";
import CheckIcon from "../../../assets/icons/common/toggleable/IconCheck";
import CrossIcon from "../../../assets/icons/common/toggleable/IconClose";
import TextFieldConfigured from "../ConfiguredFormElements/TextFieldConfigured/TextFieldConfigured";
import DateFieldConfigured from "../ConfiguredFormElements/DateFieldConfigured/DateFieldConfigured";
import TimeFieldConfigured from "../ConfiguredFormElements/TimeFieldConfigured/TimeFieldConfigured";
import IconTrafficAccident from "../../../assets/icons/trafficLaw/toggleable/IconTrafficAccident";
import IconCarDamage from "../../../assets/icons/trafficLaw/toggleable/IconCarDamage";
import IconCarAndPersonDamage from "../../../assets/icons/trafficLaw/toggleable/IconCarAndPersonDamage";
import IconObstacleDamage from "../../../assets/icons/trafficLaw/toggleable/IconObstacleDamage";
import IconSidewaysCollision from "../../../assets/icons/trafficLaw/toggleable/IconSidewaysCollision";
import IconParkingCollision from "../../../assets/icons/trafficLaw/toggleable/IconParkingCollision";
import IconHospital from "../../../assets/icons/generic/toggleable/IconHospital";
import IconQuestionmark from "../../../assets/icons/common/toggleable/IconQuestionmark";
import HighlightBox from "../../Box/HighlightBox/HighlightBox";

type AccidentBaseProps = {
  submitProps: FormSubmitProps;
};

export default function AccidentBase({ submitProps }: AccidentBaseProps) {
  const { handleSubmit, values, registerValidators, handleChange } = useForm();

  useEffect(() => {
    if (["vehicle", "vehicleAndPerson"].includes(values.typeOfDamage)) {
      registerValidators("clientIsVehicleOwner", [
        {
          validator: isRequired,
          message: "Bitte wählen Sie eine der angezeigten Antworten aus",
        },
      ]);
      return;
    }
    registerValidators("clientIsVehicleOwner", []);
  }, [values.typeOfDamage]);

  useEffect(() => {
    if (values.hasPreciseAccidentAddress === "no") {
      handleChange({
        target: {
          name: "accidentAddress.streetAddress",
          value: null,
          type: "text",
        },
      });
      handleChange({
        target: {
          name: "accidentAddress.postalCode",
          value: null,
          type: "text",
        },
      });
      handleChange({
        target: {
          name: "accidentAddress.addressLocality",
          value: null,
          type: "text",
        },
      });
    }
  }, [values.hasPreciseAccidentAddress]);

  return (
    <Paper sx={wizardStyle.paper} elevation={4}>
      <FormFader>
        <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmit}>
          <Grid container justifyContent={"center"} alignItems={"center"} spacing={2}>
            <Person path="client" headline={"Wie heißen Sie?"} showDiverseGenderOption />
            <Grid item xs={12}>
              <ValidatorTextField
                name="customer.telephone"
                label="Telefonnummer"
                autoComplete="tel"
                validators={[
                  requiredValidator("Bitte geben Sie Ihre Telefonnummer an"),
                  {
                    validator: minStringLength,
                    params: [7],
                    message: "Ihre Telefonnummer ist zu kurz",
                  },
                  { validator: isPhoneNumber, message: "Ihre Telefonnummer enthält ungültige Zeichen" },
                ]}
              />
            </Grid>
            <Address path="client.residenceAddress" headline={"Wo wohnen Sie?"} />
            <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
              <FormLegend>{translate("trafficAccident.clientBankData.iban.question", values)}</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorTextField
                label={translate("trafficAccident.clientBankData.iban.label", values)}
                name="clientBankData.iban"
                validators={[
                  {
                    validator: isIban,
                    message: "Die eingegebene IBAN ist ungültig",
                  },
                ]}
                helperText={translate("trafficAccident.clientBankData.iban.helperText", values)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">DE</InputAdornment>,
                  inputComponent: IbanInput,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Group
                type="radio"
                name="typeOfDamage"
                question={translate("trafficAccident.typeOfDamage.question", values)}
                options={[
                  {
                    labelText: translate("trafficAccident.typeOfDamage.options.vehicle.label", values),
                    labelIcon: <IconCarDamage />,
                    labelIconActive: <IconCarDamage active />,
                    value: "vehicle",
                    optionHintText: translate("trafficAccident.typeOfDamage.options.vehicle.optionHintText", values),
                  },
                  {
                    labelText: translate("trafficAccident.typeOfDamage.options.person.label", values),
                    labelIcon: <IconHospital sx={{ zoom: 0.8 }} />,
                    labelIconActive: <IconHospital sx={{ zoom: 0.8 }} active />,
                    value: "person",
                    optionHintText: translate("trafficAccident.typeOfDamage.options.person.optionHintText", values),
                  },
                  {
                    labelText: translate("trafficAccident.typeOfDamage.options.vehicleAndPerson.label", values),
                    labelIcon: <IconCarAndPersonDamage />,
                    labelIconActive: <IconCarAndPersonDamage active />,
                    value: "vehicleAndPerson",
                  },
                  {
                    labelText: translate("trafficAccident.typeOfDamage.options.other.label", values),
                    labelIcon: <IconObstacleDamage />,
                    labelIconActive: <IconObstacleDamage active />,
                    value: "other",
                    optionHintText: translate("trafficAccident.typeOfDamage.options.other.optionHintText", values),
                  },
                ]}
              />
            </Grid>
            {["vehicle", "vehicleAndPerson"].includes(values.typeOfDamage) && (
              <Grid item xs={12}>
                <Group
                  type="radio"
                  name="clientIsVehicleOwner"
                  question={translate("trafficAccident.clientIsVehicleOwner.question", values)}
                  modalContent={
                    <Typography paragraph>
                      Um Schadenersatzansprüche aus einem Verkehrsunfall geltend zu machen, müssen Sie mindestens eine der beiden Rollen erfüllen. Der
                      Eigentümer ist die Person, die im Fahrzeugbrief eingetragen ist, während der Halter die im Fahrzeugschein vermerkte Person ist.
                    </Typography>
                  }
                  options={[
                    {
                      labelText: translate("trafficAccident.clientIsVehicleOwner.options.yes.label", values),
                      labelIcon: <CheckIcon />,
                      labelIconActive: <CheckIcon active />,
                      value: "yes",
                    },
                    {
                      labelText: translate("trafficAccident.clientIsVehicleOwner.options.no.label", values),
                      labelIcon: <CrossIcon />,
                      labelIconActive: <CrossIcon active />,
                      value: "no",
                    },
                  ]}
                  validators={[
                    {
                      validator: isRequired,
                      message: "Bitte wählen Sie eine der angezeigten Antworten aus",
                    },
                  ]}
                />
              </Grid>
            )}
            {values.clientIsVehicleOwner === "no" && (
              <Grid item xs={12}>
                <HighlightBox>
                  <Typography color={"error.main"}>
                    Um Schadenersatzansprüche aus einem Verkehrsunfall geltend zu machen, müssen Sie Halter und/oder Eigentümer des beschädigten Fahrzeugs sein.
                    Daher können Sie Legalbird leider nicht mit der rechtlichen Durchsetzung Ihrer Ansprüche beauftragen. Bitte setzen Sie sich mit dem Halter
                    oder Eigentümer in Verbindung und bitten Sie diesen, das Formular auszufüllen. Dann werden wir gerne umgehend für Sie tätig!
                  </Typography>
                </HighlightBox>
              </Grid>
            )}
            {values.clientIsVehicleOwner !== "no" && (
              <>
                <Grid item xs={12}>
                  <Group
                    type="radio"
                    name="typeOfAccident"
                    question={translate("trafficAccident.typeOfAccident.question", values)}
                    options={[
                      {
                        labelText: translate("trafficAccident.typeOfAccident.options.rearEndCollision.label", values),
                        labelIcon: <IconTrafficAccident />,
                        labelIconActive: <IconTrafficAccident active />,
                        value: "rearEndCollision",
                      },
                      {
                        labelText: translate("trafficAccident.typeOfAccident.options.laneChangeCollision.label", values),
                        labelIcon: <IconSidewaysCollision />,
                        labelIconActive: <IconSidewaysCollision active />,
                        value: "laneChangeCollision",
                      },
                      {
                        labelText: translate("trafficAccident.typeOfAccident.options.parking.label", values),
                        labelIcon: <IconParkingCollision />,
                        labelIconActive: <IconParkingCollision active />,
                        value: "parking",
                      },
                      {
                        labelText: translate("trafficAccident.typeOfAccident.options.other.label", values),
                        labelIcon: <IconQuestionmark />,
                        labelIconActive: <IconQuestionmark active />,
                        value: "other",
                      },
                    ]}
                    validators={[requiredFieldDefault]}
                  />
                </Grid>
                <TextFieldConfigured caseData={values} fieldPath={"courseOfEvents"} required multiline />
                <DateFieldConfigured caseData={values} fieldPath={"accidentDate"} required />
                <TimeFieldConfigured caseData={values} fieldPath={"accidentTime"} required />
                <Grid item xs={12}>
                  <Group
                    type="radio"
                    name="hasPreciseAccidentAddress"
                    question={translate("trafficAccident.hasPreciseAccidentAddress.question", values)}
                    options={[
                      {
                        labelText: translate("trafficAccident.hasPreciseAccidentAddress.options.yes.label", values),
                        labelIcon: <CheckIcon />,
                        labelIconActive: <CheckIcon active />,
                        value: "yes",
                      },
                      {
                        labelText: translate("trafficAccident.hasPreciseAccidentAddress.options.no.label", values),
                        labelIcon: <CrossIcon />,
                        labelIconActive: <CrossIcon active />,
                        value: "no",
                      },
                    ]}
                    validators={[
                      {
                        validator: isRequired,
                        message: "Bitte wählen Sie eine der angezeigten Antworten aus",
                      },
                    ]}
                  />
                </Grid>
                {values.hasPreciseAccidentAddress === "yes" && <Address path="accidentAddress" headline={"Unfallort"} />}
                {values.hasPreciseAccidentAddress === "no" && <TextFieldConfigured caseData={values} fieldPath={"accidentLocalityDescription"} multiline />}
                <Grid item xs={12}>
                  <Group
                    type="radio"
                    name="policeInvolved"
                    question={translate("trafficAccident.policeInvolved.question", values)}
                    options={[
                      {
                        labelText: translate("trafficAccident.policeInvolved.options.yes.label", values),
                        labelIcon: <CheckIcon />,
                        labelIconActive: <CheckIcon active />,
                        value: "yes",
                      },
                      {
                        labelText: translate("trafficAccident.policeInvolved.options.no.label", values),
                        labelIcon: <CrossIcon />,
                        labelIconActive: <CrossIcon active />,
                        value: "no",
                      },
                    ]}
                    validators={[
                      {
                        validator: isRequired,
                        message: "Bitte wählen Sie eine der angezeigten Antworten aus",
                      },
                    ]}
                  />
                </Grid>
              </>
            )}
          </Grid>
          {values.clientIsVehicleOwner !== "no" && <FormSubmit {...submitProps} />}
        </Box>
      </FormFader>
    </Paper>
  );
}
