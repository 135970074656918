/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "../../../../../components/Container/Container";
import theme from "../../../../../theme/Theme";
import IconPhone from "../../../../../assets/icons/contract/toggleable/IconPhone";
import bookACallStyle from "./BookACallStyle";
import ButtonLoading from "../../../../../components/Button/ButtonLoading";
import { createFlexCaseRequest } from "../../../../../components/Checkin/AdvocardFlexCheckin/AdvocardFlexCheckin";
import { apiPost } from "../../../../../services/apiClient";
import ReactGA from "react-ga4";
import { queryKeys } from "../../../../../services/reactQuery/reactQueryService";
import { getCaseLink } from "../../../../../services/productService";
import { useCustomer } from "../../../../../provider/Customer/CustomerProvider";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Box } from "@mui/material";

type BookACallProps = {
  createCaseRequest: createFlexCaseRequest | null;
};

const BookACall = ({ createCaseRequest }: BookACallProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { isLoggedIn } = useCustomer();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleClick = async () => {
    setIsLoading(true);

    const result = await apiPost("/advocard/flex/create_case", createCaseRequest);

    if (!isLoggedIn) {
      localStorage.setItem("token", result.token);
      ReactGA.event({
        category: "Registration",
        action: "Account created",
      });
    }
    await queryClient.invalidateQueries(queryKeys.collection("/customers"));

    navigate(getCaseLink(result.case) + "/antrag");
  };

  return (
    <>
      <Container sx={{ textAlign: "center" }} id={"erstberatung"}>
        <Box id={"erstberatung"} sx={bookACallStyle.scrollToAnchor} />
        <Typography variant={"h2"}>Erstberatung buchen</Typography>

        <Grid container>
          <Grid item xs={12}>
            <IconPhone sx={bookACallStyle.icon(theme)} active={true} />
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <p>
                <strong>Ihre Frage konnte nicht beantwortet werden oder Sie bevorzugen eine persönliche Einschätzung?</strong>
              </p>
              <p>Kein Problem! Buchen Sie jetzt eine persönliche Erstberatung, indem Sie auf “Erstberatung buchen” klicken.</p>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ButtonLoading
              variant="contained"
              sx={bookACallStyle.submitButton}
              onClick={handleClick}
              isLoading={isLoading}
              loadingText={"Erstberatung wird erstellt"}
            >
              Erstberatung buchen
            </ButtonLoading>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default BookACall;
