/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import ButtonLoading from "../../../../../components/Button/ButtonLoading";
import { Box, Button, Paper, TextField } from "@mui/material";
import { AutoAwesome } from "@mui/icons-material";
import { apiPost } from "../../../../../services/apiClient";
import { HashLink as Link } from "react-router-hash-link";
import Grid from "@mui/material/Grid";
import flexAiChatAgentStyle from "./FlexAiChatAgentStyle";

type FlexAiChatAgentProps = {
  postalCode: string;
  insurancePolicyNumber: string;
};

const FlexAiChatAgent = ({ postalCode, insurancePolicyNumber }: FlexAiChatAgentProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    setIsLoading(true);
    setError("");

    try {
      const response = await apiPost("/advocard/flex/send_ai_request", {
        question: question,
        postalCode: postalCode,
        insurancePolicyNumber: insurancePolicyNumber,
      });

      setIsLoading(false);

      if (response["status"] === "success") {
        setAnswer(response["answer"]);
      } else {
        setError("Ihre Anfrage konnte leider nicht verarbeitet werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.");
      }
    } catch (e: unknown) {
      setIsLoading(false);
      setError("Ihre Anfrage konnte leider nicht verarbeitet werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.");
    }
  };

  const handleNewQuestion = () => {
    setQuestion("");
    setAnswer("");
  };

  const convertText = (text: string) => {
    const paragraphs = text
      .split(/\n{2,}/)
      .map((p) => p.trim())
      .filter(Boolean);

    return paragraphs.map((p) => p.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>").replace(/\n/g, "<br>")).join("</p><p>");
  };

  return (
    <>
      {answer && (
        <>
          <Paper elevation={4} sx={{ p: 3, my: 3 }}>
            <Typography align={"left"}>
              <strong>Ihre Frage zum Thema Grundsteuer:</strong>
              <br />
              {question}
            </Typography>
            <Typography align={"left"} sx={{ mt: 3 }}>
              <strong>Unsere Antwort:</strong>
              <br />
            </Typography>
            <Typography align={"left"} dangerouslySetInnerHTML={{ __html: convertText(answer) }} />
          </Paper>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Button
                component={Link}
                variant={"contained"}
                to={"#frage-stellen"}
                onClick={handleNewQuestion}
                sx={flexAiChatAgentStyle.button}
                fullWidth={true}
              >
                Eine weitere Frage stellen
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button component={Link} variant={"contained"} to={"#erstberatung"} sx={flexAiChatAgentStyle.button} fullWidth={true}>
                Zur Erstberatung
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      {!answer && (
        <>
          <Box id={"frage-stellen"} sx={{ position: "relative", top: "-200px" }} />
          <Grid container justifyContent="center" alignItems="center" wrap="wrap" sx={{ mt: 3 }}>
            <Grid item xs={12}>
              <TextField
                placeholder={"Geben Sie hier Ihre Frage bezüglich der Grundsteuer ein..."}
                multiline
                value={question}
                onChange={(e) => setQuestion(e.target.value.substring(0, 800))}
                rows={10}
                fullWidth
                helperText={"[" + question.length + "/800]"}
                disabled={isLoading}
              />
            </Grid>
            {error && (
              <Grid item xs={12} sx={{ mt: 3 }} textAlign="center">
                <Typography sx={{ mt: 3, color: "error.main" }}>{error}</Typography>
              </Grid>
            )}
            <Grid item xs={12} sx={{ mt: 3 }} textAlign="center">
              <ButtonLoading
                variant={"contained"}
                disabled={question.length === 0}
                onClick={handleSubmit}
                isLoading={isLoading}
                loadingText={"Antwort wird generiert"}
                endIcon={<AutoAwesome />}
                sx={{ px: 3 }}
              >
                Antwort erhalten
              </ButtonLoading>
            </Grid>
            <Grid item xs={12} sx={{ mt: 3 }} textAlign="center">
              <Typography variant={"caption"} fontSize={"small"} color={"textSecondary"}>
                Mit dem Klick auf “Antwort erhalten” erklären Sie sich damit einverstanden, dass Ihre Eingabe zur automatisierten Beantwortung verarbeitet wird.
                Bitte geben Sie keine personenbezogenen Daten ein. Weitere Informationen finden Sie in unserer{" "}
                <Link to={"/datenschutz"} target="_blank">
                  {" "}
                  Datenschutzerklärung
                </Link>{" "}
                .
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default FlexAiChatAgent;
