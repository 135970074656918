import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconParkingCollision = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g fill={getIconColor(active, theme)}>
        <path
          d="M27.908,111.249c0.059,3.276,0.109,6.106,0.109,8.064c0,8.44,0.399,30.268,1.521,43.472
		c-0.024,0.175-0.034,0.353-0.021,0.534c0.015,0.205,0.061,0.399,0.119,0.586c0.347,3.771,0.757,6.731,1.238,8.287
		c2.633,8.493,10.573,8.853,28.283,8.853c17.709,0,25.65-0.358,28.285-8.853c0.481-1.557,0.892-4.518,1.236-8.292
		c0.061-0.186,0.104-0.378,0.119-0.581c0.014-0.182,0.004-0.357-0.021-0.531c1.118-13.203,1.52-35.033,1.52-43.475
		c0-1.958,0.052-4.788,0.109-8.064c0.096-5.282,0.215-11.911,0.188-18.671l2.444,0.958c0.3,0.117,0.608,0.174,0.911,0.174
		c0.999,0,1.941-0.604,2.329-1.59c0.503-1.285-0.132-2.735-1.416-3.239l-4.322-1.693c-0.155-9.292-0.669-18.216-1.979-23.582
		c-3.084-12.611-18.925-12.611-29.405-12.611s-26.318,0-29.405,12.611c-1.296,5.302-1.813,14.076-1.975,23.247L22.6,88.881
		c-1.285,0.504-1.919,1.954-1.416,3.239c0.387,0.986,1.33,1.59,2.329,1.59c0.304,0,0.611-0.057,0.911-0.174l3.295-1.291
		C27.69,99.119,27.811,105.88,27.908,111.249z M85.409,111.159c-0.06,3.303-0.11,6.152-0.11,8.155
		c0,10.628-0.455,29.245-1.337,41.154l-3.469-0.247c-0.302-2.304-1.078-6.23-3.032-11.411c0.541-4.171,0.887-13.866,0.889-21.982
		c0-11.157-0.519-16.926-1.223-20.011c2.596-1.8,6.447-5.279,8.475-10.978C85.584,101.401,85.488,106.757,85.409,111.159z
		 M44.889,152.371c2.677,0.2,8.269,0.576,12.41,0.576H57.3c0.665,0,1.289-0.01,1.858-0.03c0.568,0.021,1.195,0.03,1.858,0.03
		c4.145,0,9.734-0.376,12.411-0.576c1.138,3.326,1.71,5.948,1.988,7.693H42.899C43.18,158.317,43.752,155.695,44.889,152.371z
		 M74.292,102.701c-3.26-1.245-11.554-1.387-15.134-1.387c-3.451,0-11.266,0.135-14.746,1.26c-2.686-2.307-7.455-7.267-8-13.715
		c6.252-1.887,12.184-1.986,22.746-1.986s16.494,0.101,22.745,1.985C81.463,96.616,76.928,100.878,74.292,102.701z M33.018,119.314
		c0-2.003-0.052-4.854-0.11-8.155c-0.084-4.661-0.188-10.389-0.193-16.299c2.077,5.461,6.065,9.49,8.558,11.61
		c-0.743,2.993-1.305,8.77-1.304,20.355c0.001,8.119,0.349,17.814,0.89,21.984c-1.955,5.181-2.729,9.106-3.03,11.41l-3.472,0.247
		C33.475,148.561,33.018,129.943,33.018,119.314z M35.65,170.712c-0.313-1.006-0.598-2.848-0.855-5.262l3.411-0.242
		c0.774,3.402,1.317,7.104,1.267,9.42C37.096,173.808,36.223,172.56,35.65,170.712z M59.158,176.045
		c-6.686,0-11.398-0.13-14.762-0.477c0.247-3.273-0.471-7.544-1.104-10.505h31.735c-0.635,2.961-1.354,7.23-1.105,10.505
		C70.557,175.915,65.843,176.045,59.158,176.045z M82.668,170.712c-0.572,1.848-1.447,3.095-3.822,3.916
		c-0.05-2.314,0.494-6.017,1.269-9.42l3.409,0.242C83.266,167.865,82.98,169.706,82.668,170.712z M83.706,64.793
		c0.244,0.997,0.457,2.145,0.646,3.4c-6.388-0.879-8.545-5.821-9.041-10.768C79.887,58.67,82.754,60.896,83.706,64.793z
		 M34.61,64.794c0.954-3.897,3.821-6.124,8.396-7.367c-0.496,4.945-2.653,9.89-9.043,10.768
		C34.153,66.938,34.366,65.792,34.61,64.794z M33.389,73.282c8.793-0.625,14.168-6.767,14.692-16.787
		c3.186-0.368,6.878-0.501,11.077-0.501c4.2,0,7.895,0.134,11.079,0.501c0.524,10.021,5.899,16.162,14.69,16.787
		c0.297,3.477,0.476,7.407,0.572,11.501c-7.768-2.767-14.402-2.909-26.343-2.909c-11.938,0-18.572,0.144-26.343,2.91
		C32.913,80.69,33.092,76.758,33.389,73.282z"
        />
        <path
          d="M133.914,144.323c-2.049,0-4.09,0.482-6.066,1.434c-4.918,2.367-12.021,7.546-19.207,13.248l-4.856-2.708
		c-1.203-0.673-2.729-0.24-3.399,0.967c-0.673,1.206-0.239,2.729,0.967,3.399l3.09,1.724c-5.321,4.34-10.46,8.725-14.541,12.208
		c-2.497,2.131-4.653,3.972-6.167,5.216c-6.519,5.364-23.124,19.549-32.606,28.806c-0.147,0.092-0.289,0.196-0.42,0.32
		c-0.147,0.14-0.268,0.295-0.373,0.458c-2.695,2.667-4.725,4.866-5.618,6.229c-4.88,7.429-0.112,13.786,11.138,27.461
		c9.811,11.922,14.712,17.146,20.482,17.146c1.373,0,2.787-0.305,4.319-0.931c1.509-0.615,4.058-2.182,7.192-4.313
		c0.182-0.071,0.359-0.16,0.527-0.278c0.147-0.105,0.278-0.226,0.396-0.354c10.908-7.524,28.019-21.086,34.537-26.45
		c1.511-1.243,3.728-3.002,6.294-5.037c4.14-3.283,9.335-7.403,14.538-11.718l0.813,2.495c0.344,1.056,1.322,1.727,2.375,1.727
		c0.258,0,0.52-0.039,0.775-0.124c1.313-0.428,2.03-1.839,1.604-3.15l-1.438-4.414c7.076-6.024,13.642-12.092,16.952-16.518
		c7.775-10.396-2.289-22.625-8.949-30.716C149.557,152.29,142.303,144.323,133.914,144.323z M130.016,150.262
		c1.313-0.632,2.588-0.938,3.897-0.938c2.346,0,4.746,1.057,7.133,2.74c-4.138,2.764-9.334,4.252-14.077-0.132
		C128.063,151.275,129.088,150.709,130.016,150.262z M125.504,204.609c-2.188-5.159-13.316-18.729-18.212-22.274
		c0.377-3.358,1.684-10.086,6.188-14.767c4.969,3.526,8.752,7.979,15.084,15.676c6.348,7.71,9.992,12.281,12.5,17.856
		C134.713,205.53,128.492,205.165,125.504,204.609z M82.381,242.307l-20.66-25.107c1.567-0.92,4.045-2.187,7.477-3.455
		c1.811,2.565,5.865,8.217,8.595,11.285c2.485,3.271,7.248,8.336,9.417,10.607C85.305,238.76,83.583,240.948,82.381,242.307z
		 M90.342,232.494c-2.168-2.273-6.791-7.201-9.066-10.213c-0.037-0.049-0.075-0.096-0.116-0.141c-2.515-2.813-6.46-8.3-8.272-10.865
		c5.226-5.504,27.491-24.222,31.803-25.409c3.266,2.012,15.328,16.667,16.701,20.285C119.232,210.306,96.725,228.439,90.342,232.494
		z M86.908,183.671c1.548-1.273,3.721-3.127,6.235-5.273c4.388-3.744,10.018-8.549,15.735-13.181
		c-4.899,5.825-6.239,13.323-6.604,16.978c-8.104,3.837-26.926,20.399-32.519,26.058c-5.604,1.845-9.334,3.848-11.385,5.129
		l-2.395-2.521C64.613,202.609,78.701,190.426,86.908,183.671z M48.893,218.368c0.577-0.881,1.817-2.271,3.52-4.005l2.354,2.479
		c-2.138,2.759-4.647,5.529-6.468,6.963C47.424,221.45,47.831,219.983,48.893,218.368z M59.712,239.907
		c-4.247-5.162-7.142-8.884-9.012-11.701c2.685-1.891,5.524-5.156,7.407-7.526l20.164,24.504c-2.688,1.393-6.44,3.551-8.813,5.823
		C66.915,248.501,63.779,244.851,59.712,239.907z M78.764,254.67c-0.936,0.383-1.707,0.561-2.431,0.561
		c-0.909,0-1.896-0.343-3.01-1.027c1.758-1.509,4.956-3.438,8.07-5.002l1.979,2.785C81.343,253.322,79.739,254.272,78.764,254.67z
		 M126.493,218.948c-2.585,2.052-4.818,3.822-6.364,5.095c-8.209,6.756-22.874,18.232-32.63,25.117l-2.015-2.834
		c1.645-1.756,4.307-5.007,7.176-10.103c3.705-2.401,11.055-8.04,17.07-12.991c8.786-7.229,12.864-11.294,14.719-13.742
		c1.215,0.23,2.815,0.438,4.693,0.438c3.578,0,8.143-0.756,12.848-3.49C136.491,211.018,130.889,215.463,126.493,218.948z
		 M161.215,188.169c-0.617,0.825-1.373,1.724-2.229,2.672c-3.394-5.495-0.93-10.309,2.578-13.839
		C163.516,181.325,163.618,184.956,161.215,188.169z M155.434,194.51c-2.494,2.438-5.416,5.073-8.516,7.749
		c-0.094-0.26-0.186-0.524-0.282-0.778c-0.057-0.352-0.189-0.69-0.399-1.001c-2.785-6.827-6.818-11.919-13.813-20.413
		c-6.988-8.494-11.21-13.433-17.365-17.474c-0.265-0.269-0.573-0.466-0.909-0.589c-0.231-0.145-0.474-0.284-0.71-0.427
		c3.224-2.525,6.374-4.888,9.246-6.866c3.149,3.313,6.856,4.992,10.828,4.992c3.687,0,7.594-1.445,11.479-4.318
		c2.563,2.461,5.065,5.386,7.415,8.239c2.669,3.242,4.914,6.179,6.653,8.871C151.655,179.271,150.328,187.326,155.434,194.51z"
        />
        <path
          d="M292.537,186.77h-4.261v-145c0-1.104-0.896-2-2-2H13.727c-1.104,0-2,0.896-2,2v145H7.466
		c-1.104,0-2,0.896-2,2s0.896,2,2,2h12.521c1.104,0,2-0.896,2-2s-0.896-2-2-2h-4.261v-143h86.942v68.411c0,1.104,0.896,2,2,2
		c1.104,0,2-0.896,2-2V43.77h86.804v143h-4.262c-1.104,0-2,0.896-2,2s0.896,2,2,2h12.521c1.104,0,2-0.896,2-2s-0.896-2-2-2h-4.262
		v-143h86.804v143h-4.261c-1.104,0-2,0.896-2,2s0.896,2,2,2h12.521c1.104,0,2-0.896,2-2S293.641,186.77,292.537,186.77z"
        />
        <path
          d="M16.731,255.367h-5c-1.341,0-2.427,1.087-2.427,2.428c0,1.34,1.086,2.427,2.427,2.427h5
		c1.34,0,2.427-1.087,2.427-2.427C19.158,256.454,18.071,255.367,16.731,255.367z"
        />
        <path
          d="M38.275,252.956c-4.449,1.496-8.489,2.067-10.502,2.274c-1.333,0.138-2.303,1.33-2.165,2.663
		c0.129,1.248,1.183,2.178,2.411,2.178c0.082,0,0.166-0.004,0.252-0.013c2.702-0.279,6.9-0.939,11.551-2.503
		c1.271-0.427,1.954-1.803,1.525-3.073C40.921,253.214,39.548,252.525,38.275,252.956z"
        />
        <path
          d="M51.264,245.325c-1.057,1.052-2.24,2.042-3.52,2.944c-1.095,0.773-1.355,2.287-0.583,3.382
		c0.473,0.67,1.223,1.027,1.983,1.027c0.483,0,0.974-0.146,1.397-0.444c1.501-1.06,2.896-2.227,4.144-3.47
		c0.949-0.945,0.953-2.481,0.009-3.432S52.213,244.379,51.264,245.325z"
        />
      </g>
      <g>
        <path
          fill="#137F7B"
          d="M104.688,185.866c-4.312,1.188-26.577,19.905-31.804,25.409c1.813,2.565,5.759,8.052,8.273,10.865
		c0.041,0.045,0.079,0.092,0.116,0.141c2.275,3.011,6.898,7.938,9.066,10.213c6.384-4.055,28.891-22.188,31.047-26.343
		C120.016,202.533,107.953,187.877,104.688,185.866z"
        />
        <path
          fill="#137F7B"
          d="M269.45,70.395c-1.014-1.68-2.373-3.026-4.079-4.04c-1.708-1.013-3.574-1.52-5.602-1.52h-46.398v107.412
		h10.561v-44.625l35.84-0.08c2.025,0,3.893-0.493,5.601-1.48c1.706-0.986,3.065-2.332,4.079-4.04c1.014-1.706,1.521-3.572,1.521-5.6
		V75.955C270.971,73.929,270.463,72.074,269.45,70.395z M260.331,116.021c0,0.268-0.108,0.48-0.32,0.641
		c-0.214,0.159-0.454,0.239-0.72,0.239h-34.4c-0.268,0-0.493-0.08-0.68-0.239c-0.188-0.159-0.279-0.373-0.279-0.641V76.354
		c0-0.267,0.093-0.492,0.279-0.68s0.412-0.28,0.68-0.28h34.4c0.266,0,0.506,0.094,0.72,0.28c0.212,0.188,0.32,0.413,0.32,0.68
		V116.021z"
        />
        <path
          fill="#137F7B"
          d="M176.107,133.591l-25.797-2.195l16.477-34.146c0.584-1.209,0.217-2.66-0.87-3.447
		c-1.087-0.786-2.582-0.682-3.547,0.25l-23.979,23.126l-8.635-19.234c-0.539-1.199-1.854-1.853-3.131-1.567
		c-1.284,0.285-2.189,1.437-2.166,2.752l0.477,27.143l-12.606-8.101c-0.982-0.633-2.262-0.579-3.188,0.137
		c-0.927,0.715-1.305,1.936-0.942,3.049l7.018,21.604c0.475,1.455,2.037,2.248,3.49,1.778c1.455-0.473,2.252-2.035,1.779-3.489
		l-4.621-14.225l10.437,6.705c0.861,0.554,1.955,0.587,2.846,0.089c0.894-0.497,1.438-1.446,1.421-2.468l-0.33-18.813l4.715,10.503
		c0.371,0.822,1.116,1.414,2.002,1.586c0.887,0.169,1.799-0.102,2.448-0.727l15.664-15.106l-11.493,23.816
		c-0.396,0.821-0.362,1.784,0.088,2.576c0.451,0.792,1.265,1.311,2.173,1.388l16.795,1.431l-12.456,4.311
		c-1.445,0.5-2.212,2.077-1.711,3.523c0.396,1.146,1.469,1.863,2.617,1.863c0.301,0,0.605-0.049,0.905-0.152l24.795-8.581
		c1.236-0.428,2.004-1.662,1.843-2.96C178.46,134.709,177.411,133.701,176.107,133.591z"
        />
      </g>
    </SvgIcon>
  );
};
export default IconParkingCollision;
