/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import { Helmet } from "react-helmet-async";

import { gaSet } from "../../../../../services/cookieService";

const LpContractAdvocardMeta = () => {
  const title = "ADVOCARD-360°-FlexService - Infoseite Grundsteuer";
  gaSet({ title: title });

  return (
    <Helmet>
      <title>{title}</title>
      <meta
        name="description"
        content="Erfahren Sie in unseren FAQs alles zur Grundsteuerreform – von der Neubewertung über die Steuerberechnung bis zu Ihren Rechten als Eigentümer oder Mieter."
      />
      <meta name="robots" content="index,follow" />
      <meta property="og:title" content={title} />
      <meta property="og:locale" content="de_DE" />
      <meta property="og:type" content="website" />
      <meta
        property="og:description"
        content="Erfahren Sie in unseren FAQs alles zur Grundsteuerreform – von der Neubewertung über die Steuerberechnung bis zu Ihren Rechten als Eigentümer oder Mieter."
      />
      <meta property="article:tag" content="Grundsteuerreform" />
      <meta property="article:tag" content="FAQ" />
      <meta property="article:tag" content="Grundsteuer 2025" />
      <meta property="article:tag" content="Neubewertung" />
      <meta property="article:tag" content="Grundsteuerwert" />
      <meta property="article:tag" content="Steuermesszahl" />
      <meta property="article:tag" content="Hebesatz" />
      <meta property="article:tag" content="Bescheid" />
      <meta property="article:tag" content="Widerspruch" />
      <meta property="article:tag" content="Eigentümer" />
      <meta property="article:tag" content="Mieter" />
      <meta property="article:tag" content="Nebenkosten" />
      <meta property="article:tag" content="kommunale Abgabe" />
      <meta property="article:tag" content="Finanzamt" />
    </Helmet>
  );
};

export default LpContractAdvocardMeta;
