import React, { FormEvent, useState } from "react";
import Paper from "@mui/material/Paper";
import TextContainer from "../../Container/TextContainer";
import HintBox from "../../Box/HintBox/HintBox";
import EmailVerificationModal from "../../FormInfoModal/EmailVerificationModal";
import _isEmpty from "lodash/isEmpty";
import { useForm } from "../../../provider/Form/FormProvider";
import Typography from "@mui/material/Typography";
import Group from "../../Wizard/Group";
import { dateFieldDefault, isTodayOrPastDate, oneOfTheseOptionsValidator, requiredValidator } from "../../../services/validationRules";
import { IconButton, Stack } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import DialogModal from "../../DialogModal/DialogModal";
import FormLegend from "../Common/FormLegend/FormLegend";
import ValidatorDateField from "../../FormFields/ValidatorDateField";
import { translate } from "../../../services/translations/translations";
import IconData from "../../../assets/icons/generic/toggleable/IconData";
import Grid from "@mui/material/Grid";
import ButtonLoading from "../../Button/ButtonLoading";
import { AbstractCase } from "../../../types/Entities/AbstractCase";

export default function DocumentChoice({ product }: { product: AbstractCase }) {
  const { handleSubmit, values } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const isSubmittable = !(
    values.authorityNotification === "no_notice" ||
    (values.authorityNotification === "fine_notice" && _isEmpty(values.fineNoticeReceivedDate))
  );

  const handleSubmitInternal = async (e: FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    await handleSubmit(e);
  };

  const handleModalClose = () => {
    navigate(-1);
  };

  const handleInfoModalOpen = () => {
    navigate(location.pathname, { state: { infoModalOpen: true } });
  };

  const hasFineNotice = product.authorityNotification === "fine_notice";

  return (
    <>
      <HintBox headline={"Ihre Angaben sind vollständig. Jetzt " + (hasFineNotice ? "Bußgeldbescheid" : "Dokument") + " hochladen!"}>
        {product.authorityNotification !== "no_notice" ? (
          <p>
            Damit wir Ihnen eine kostenlose Ersteinschätzung geben können, benötigen wir jetzt noch
            {hasFineNotice
              ? " Ihren Bußgeldbescheid. "
              : " das dazugehörige Dokument. Laden Sie hier bitte den Bußgeldbescheid, Anhörungsbogen, Zeugenfragebogen oder ähnliches hoch. "}
            Wir melden uns dann zeitnah bei Ihnen, um das weitere Vorgehen zu besprechen.
          </p>
        ) : (
          <p>
            Da Sie bisher kein Dokument von der Behörde erhalten haben, melden wir uns nun zeitnah bei Ihnen, um das weitere Vorgehen zu besprechen. Sobald Sie
            Post von der Behörde bekommen haben, können Sie hier den Anhörungsbogen, Zeugenfragebogen oder Bußgeldbescheid hochladen und wir prüfen Ihren
            Bescheid kostenlos.
          </p>
        )}
      </HintBox>

      <Paper sx={{ padding: "2rem" }}>
        <Stack justifyContent={"center"} alignItems={"center"} component={"form"}>
          {product.authorityNotification === "no_notice" && (
            <>
              <Typography variant={"h4"} sx={{ color: (theme) => theme.palette.primary.main }}>
                Wählen Sie bitte aus welche Art von Dokument Sie hochladen
              </Typography>
              <Group
                type="radio"
                name="authorityNotification"
                question=""
                options={[
                  {
                    labelText: translate("traffic.authorityNotification.options.fine_notice.label", values),
                    labelIcon: <IconData />,
                    labelIconActive: <IconData active />,
                    value: "fine_notice",
                  },
                  {
                    labelText: translate("traffic.authorityNotification.options.hearing_report.label", values),
                    labelIcon: <IconData />,
                    labelIconActive: <IconData active />,
                    value: "hearing_report",
                  },
                  {
                    labelText: translate("traffic.authorityNotification.options.witness_report.label", values),
                    labelIcon: <IconData />,
                    labelIconActive: <IconData active />,
                    value: "witness_report",
                  },
                ]}
                validators={[
                  oneOfTheseOptionsValidator(["fine_notice", "hearing_report", "witness_report"], "Bitte wählen Sie eine der angezeigten Antworten aus"),
                ]}
              />
              {values.authorityNotification === "fine_notice" && (
                <TextContainer>
                  <FormLegend>Wann haben Sie den Bußgeldbescheid erhalten?</FormLegend>
                  <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
                    <ValidatorDateField
                      name="fineNoticeReceivedDate"
                      label="Bescheid erhalten am"
                      validators={[
                        requiredValidator("Bitte geben Sie an, wann Sie den Bußgeldbescheid erhalten haben."),
                        dateFieldDefault,
                        {
                          validator: isTodayOrPastDate,
                          message: "Das Datum darf nicht in der Zukunft liegen.",
                        },
                      ]}
                    />
                    <IconButton onClick={handleInfoModalOpen} sx={{ height: "40px", width: "40px" }}>
                      <InfoOutlinedIcon
                        sx={{
                          height: "1.25rem",
                          cursor: "help",
                        }}
                      />
                    </IconButton>
                  </Stack>
                </TextContainer>
              )}
            </>
          )}
          <Grid container justifyContent={"center"}>
            <Grid item xs={12} sm={6} md={4}>
              <ButtonLoading fullWidth variant={"contained"} color={"primary"} disabled={!isSubmittable} isLoading={isLoading} onClick={handleSubmitInternal}>
                Weiter
              </ButtonLoading>
            </Grid>
          </Grid>
        </Stack>
      </Paper>
      <EmailVerificationModal />
      <DialogModal open={!!(location.state && location.state.infoModalOpen)} onClose={handleModalClose} title="Datum der Zustellung">
        <p>
          Wichtig: Hier geht es darum, wann Ihnen der Brief (meist in gelbem Umschlag) von der Post zugestellt wurde. Auch wenn Sie zum Beispiel den Brief erst
          eine Woche nachdem er im Briefkasten lag geöffnet haben, gilt hier aus rechtlicher Sicht immer das Datum der Zustellung. Dieses Datum ist sehr
          wichtig, denn gegen einen Bußgeldbescheid kann nur innerhalb von zwei Wochen nach Zustellung bei Ihnen Einspruch eingelegt werden. Wird der Einspruch
          nicht innerhalb der Frist eingelegt, ist er unwirksam und wird von der Behörde als unzulässig verworfen.
        </p>
      </DialogModal>
    </>
  );
}
