import React, { useEffect } from "react";
import { Box, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import FormSubmitProps from "../../../types/FormSubmitProps";
import { useForm } from "../../../provider/Form/FormProvider";
import { getCaseLink } from "../../../services/productService";
import TextContainer from "../../Container/TextContainer";
import InsuranceData from "../Settlement/Summary/InsuranceData";
import FormSubmit from "../Common/FormSubmit/FormSubmit";
import EmailVerificationModal from "../../FormInfoModal/EmailVerificationModal";
import summaryStyle from "../ContractLaw/Summary/summaryStyle";
import SummaryDetailsItem from "../Common/SummaryDetailsItem/SummaryDetailsItem";
import SummaryDetailsPersonItems from "../Common/SummaryDetailsItem/SummaryDetailsPersonItems";

type SummaryProps = {
  product: AbstractCase;
  submitProps: FormSubmitProps;
};

const Summary = ({ product, submitProps }: SummaryProps) => {
  const location = useLocation();
  const stateField = location.state?.field;
  const { values, handleSubmit } = useForm();

  useEffect(() => {
    const el = document.getElementById(stateField);
    el && el.scrollIntoView();
  }, [stateField]);

  const basePath = getCaseLink(product) + "/antrag";
  const accidentBasePath = basePath + "/unfall";
  const opposingPartyPath = basePath + "/unfallgegner";
  const damagePath = basePath + "/schaden";
  const insurancePath = basePath + "/versicherung";

  return (
    <Paper elevation={4}>
      <TextContainer>
        <Typography variant={"h4"} sx={summaryStyle.headline}>
          Ihre Angaben
        </Typography>
        <Grid container>
          <Box id={"accidentBase"} sx={summaryStyle.scrollToAnchor} />
          <SummaryDetailsPersonItems basePath={"customer.person"} formUrl={accidentBasePath} caseData={product} />
          <SummaryDetailsItem fieldPath={"customer.telephone"} formUrl={accidentBasePath} caseData={product} />
          <SummaryDetailsItem fieldPath={"clientBankData.iban"} formUrl={accidentBasePath} caseData={product} />
          <SummaryDetailsItem fieldPath={"typeOfDamage"} formUrl={accidentBasePath} caseData={product} />
          {["vehicle", "vehicleAndPerson"].includes(values.typeOfDamage) && (
            <SummaryDetailsItem fieldPath={"clientIsVehicleOwner"} formUrl={accidentBasePath} caseData={product} fieldType={"yesNo"} />
          )}
          <SummaryDetailsItem fieldPath={"typeOfAccident"} formUrl={accidentBasePath} caseData={product} />
          <SummaryDetailsItem fieldPath={"courseOfEvents"} formUrl={accidentBasePath} caseData={product} />
          <SummaryDetailsItem fieldPath={"accidentDate"} formUrl={accidentBasePath} caseData={product} fieldType={"date"} />
          <SummaryDetailsItem fieldPath={"accidentTime"} formUrl={accidentBasePath} caseData={product} fieldType={"time"} />
          <SummaryDetailsItem fieldPath={"accidentLocalityDescription"} formUrl={accidentBasePath} caseData={product} />
          <SummaryDetailsItem fieldPath={"accidentAddress.streetAddress"} formUrl={accidentBasePath} caseData={product} />
          <SummaryDetailsItem fieldPath={"accidentAddress.postalCode"} formUrl={accidentBasePath} caseData={product} />
          <SummaryDetailsItem fieldPath={"accidentAddress.addressLocality"} formUrl={accidentBasePath} caseData={product} />
          <SummaryDetailsItem fieldPath={"policeInvolved"} formUrl={accidentBasePath} caseData={product} fieldType={"yesNo"} />

          <Box id={"opposingParty"} sx={summaryStyle.scrollToAnchor} />
          <SummaryDetailsItem fieldPath={"licensePlateOpposingParty"} formUrl={opposingPartyPath} caseData={product} />
          {values.opposingPartyType === "opposingPartyPerson" && (
            <SummaryDetailsPersonItems basePath={"opposingPartyPerson"} formUrl={opposingPartyPath} caseData={product} />
          )}
          {values.opposingPartyType === "opposingPartyLegalEntity" && (
            <>
              <SummaryDetailsItem fieldPath={"opposingPartyLegalEntity.legalName"} formUrl={opposingPartyPath} caseData={product} />
              <SummaryDetailsItem fieldPath={"opposingPartyLegalEntity.residenceAddress.streetAddress"} formUrl={opposingPartyPath} caseData={product} />
              <SummaryDetailsItem fieldPath={"opposingPartyLegalEntity.residenceAddress.postalCode"} formUrl={opposingPartyPath} caseData={product} />
              <SummaryDetailsItem fieldPath={"opposingPartyLegalEntity.residenceAddress.addressLocality"} formUrl={opposingPartyPath} caseData={product} />
            </>
          )}
          {values.opposingPartyType !== "null" && (
            <>
              <SummaryDetailsItem fieldPath={"opposingCarLiabilityInsurance.legalName"} formUrl={opposingPartyPath} caseData={product} />
              <SummaryDetailsItem fieldPath={"opposingInsurancePolicyNumber"} formUrl={opposingPartyPath} caseData={product} />
              <SummaryDetailsItem fieldPath={"opposingCaseReferenceNumber"} formUrl={opposingPartyPath} caseData={product} />
            </>
          )}

          <Box id={"damage"} sx={summaryStyle.scrollToAnchor} />
          {["vehicle", "vehicleAndPerson"].includes(product.typeOfDamage) && (
            <>
              <SummaryDetailsItem fieldPath={"driverOfClientVehicle"} formUrl={damagePath} caseData={product} />
              {values.driverOfClientVehicle === "other" && (
                <>
                  <SummaryDetailsPersonItems basePath={"driverPerson"} formUrl={opposingPartyPath} caseData={product} />
                  <SummaryDetailsItem fieldPath={"licensePlateClientVehicle"} formUrl={damagePath} caseData={product} />
                  <SummaryDetailsItem fieldPath={"vehicleMake"} formUrl={damagePath} caseData={product} />
                  <SummaryDetailsItem fieldPath={"vehicleModel"} formUrl={damagePath} caseData={product} />
                  <SummaryDetailsItem fieldPath={"vehicleId"} formUrl={damagePath} caseData={product} />
                </>
              )}
              {["person", "vehicleAndPerson"].includes(product.typeOfDamage) && (
                <>
                  <SummaryDetailsItem fieldPath={"descriptionInjuries"} formUrl={damagePath} caseData={product} />
                </>
              )}
              {product.typeOfDamage === "other" && (
                <>
                  <SummaryDetailsItem fieldPath={"descriptionDamage"} formUrl={damagePath} caseData={product} />
                </>
              )}
            </>
          )}

          <Box id={"insuranceData"} sx={summaryStyle.scrollToAnchor} />
          <InsuranceData title={"Versicherung"} formPath={insurancePath} product={product} />
        </Grid>
        <form onSubmit={handleSubmit}>
          <FormSubmit {...submitProps} hideBackButton={false} backLink={basePath + "/unfall"} backText={"Alles bearbeiten"} nextText={"Angaben speichern"} />
        </form>
      </TextContainer>
      <EmailVerificationModal />
    </Paper>
  );
};

export default Summary;
