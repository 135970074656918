import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconHospital = ({ active = false, sx = {} }: ToggleableIconProps)=> {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 207.04 206.51">
      <defs>
        <clipPath id="clip-path" transform="translate(-38.5 -34.94)">
          <rect x="38.54" y="35" width="206.97" height="206.42" fill="none" />
        </clipPath>
      </defs>
      <title>krankenhaus</title>
      <g clip-path="url(#clip-path)" stroke-linecap="round" fill="none" stroke={getIconColor(active, theme)}>
        <g stroke-width="5">
          <path d="M96.18,71.25H65.84c-25,0-24.42,16.55-24.42,16.55V230.32H124.5V171.25h35v59.07h83.07V158.19" transform="translate(-38.5 -34.94)" />
          <path d="M242.62,140.28V87.8s.53-16.55-24.42-16.55H187.86" transform="translate(-38.5 -34.94)" />
        </g>
        <path
          stroke-width="4.79"
          d="M116.94,37.34s-11.22,0-11.22,11.21V88.13s0,11.22,11.22,11.22h49.51s11.22,0,11.22-11.22V48.55s0-11.21-11.22-11.21Z"
          transform="translate(-38.5 -34.94)"
        />
        <g stroke-width="2.87">
          <path d="M104.9,176.73s0-6.63-6.62-6.63H68s-6.63,0-6.63,6.63v19.71s0,6.62,6.63,6.62H98.28s6.62,0,6.62-6.62Z" transform="translate(-38.5 -34.94)" />
          <path
            d="M222.88,176.73s0-6.63-6.63-6.63H185.92s-6.62,0-6.62,6.63v19.71s0,6.62,6.62,6.62h30.33s6.63,0,6.63-6.62Z"
            transform="translate(-38.5 -34.94)"
          />
          <path d="M104.9,124.32s0-6.62-6.62-6.62H68s-6.63,0-6.63,6.62V144s0,6.63,6.63,6.63H98.28s6.62,0,6.62-6.63Z" transform="translate(-38.5 -34.94)" />
          <path d="M222.88,124.32s0-6.62-6.63-6.62H185.92s-6.62,0-6.62,6.62V144s0,6.63,6.62,6.63h30.33s6.63,0,6.63-6.63Z" transform="translate(-38.5 -34.94)" />
          <path d="M164.07,124.32s0-6.62-6.62-6.62H127.12s-6.63,0-6.63,6.62V144s0,6.63,6.63,6.63h30.33s6.62,0,6.62-6.63Z" transform="translate(-38.5 -34.94)" />
        </g>
        <g stroke-width="3" stroke="#137F7B">
          <polyline points="104.09 14.79 104.09 30.77 119.93 30.77" />
          <polyline points="98.91 52.06 98.91 36.08 83.06 36.08" />
          <polyline points="98.95 14.79 98.95 30.77 83.1 30.77" />
          <polyline points="104.05 52.06 104.05 36.08 119.89 36.08" />
        </g>
        <line stroke-width="2.87" x1="1.44" y1="205.07" x2="205.6" y2="205.07" />
      </g>
    </SvgIcon>
  );
};

export default IconHospital;