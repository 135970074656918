import { useForm } from "../../provider/Form/FormProvider";
import React, { ReactNode, useEffect, useState, useCallback } from "react";
import { Autocomplete, AutocompleteChangeReason } from "@mui/material";
import { FormControl, TextField } from "@mui/material";
import { formValue } from "../../services/formServiceFunctions";
import FormHelperText from "@mui/material/FormHelperText";
import _get from "lodash/get";
import _find from "lodash/find";
import debounce from "lodash/debounce";
import { FormChangeEvent, FormDataTypes } from "../../types/FormDataTypes";
import { ValidatorType } from "../../types/ValidatorType";
import algoliasearch from "algoliasearch/lite";
import { Hit } from "@algolia/client-search";
import { ALGOLIA_APPLICATION_ID, ALGOLIA_ORGANIZATION_INDEX, ALGOLIA_SEARCH_API_KEY } from "../../config/_entrypoint";
import validatorStyle from "./validatorStyle";
import { Organization } from "../../types/Organization";

type ValidatorOrganizationAutocompleteProps = {
  name: string;
  label: string;
  type: string;
  helperText?: string | null;
  emptyText?: string;
  noOptionsText?: string;
  validators?: Array<ValidatorType>;
  dependentValidationFields?: Array<any>;
  fullWidth?: boolean;
  margin?: "dense" | "normal" | "none";
  renderOption?: (props?: any, option?: any, state?: any) => ReactNode;
};

type OrganizationHit = Hit<Organization>;

export default function ValidatorOrganizationAutocomplete({
  name,
  label,
  type,
  helperText,
  emptyText = "Bitte fangen Sie an zu tippen um zu suchen",
  noOptionsText = "Nicht gefunden",
  validators = [],
  dependentValidationFields = [],
  fullWidth = true,
  margin = "normal",
  ...rest
}: ValidatorOrganizationAutocompleteProps) {
  const { values, errors, setError, handleChange, registerValidators }: FormDataTypes = useForm();
  const error = !!_get(errors, name);
  const [options, setOptions] = useState<OrganizationHit[]>([]);
  const [inputValue, setInputValue] = useState<string>("");

  const client = algoliasearch(ALGOLIA_APPLICATION_ID, ALGOLIA_SEARCH_API_KEY);
  const index = client.initIndex(ALGOLIA_ORGANIZATION_INDEX);

  const fetchOptions = useCallback(
    debounce((searchText: string) => {
      if (!searchText.trim()) {
        setOptions([]);
        return;
      }

      index
        .search<OrganizationHit>(searchText, { filters: "type:" + type })
        .then(({ hits }) => {
          const sortedHits = hits.sort((a, b) => (a.name || "").localeCompare(b.name || ""));
          setOptions(sortedHits);
        })
        .catch((err) => console.error(err));
    }, 300),
    []
  );

  useEffect(() => {
    const initialValue = formValue(values, name).split("/").pop();

    if (initialValue && options.length === 0) {
      const objectId = `organization::${initialValue}`;

      index
        .search<OrganizationHit>("", {
          filters: `objectID:"${objectId}"`,
        })
        .then(({ hits }) => {
          if (hits.length > 0) {
            setOptions([hits[0]]);
          }
        })
        .catch((err) => console.error("Fehler beim Laden der initialen Option:", err));
    }
  }, []);

  useEffect(() => {
    registerValidators(name, validators, dependentValidationFields);
    return () => {
      registerValidators(name, [], []);
      setError(name, undefined);
    };
  }, [name]);

  const handleAutoCompleteValueChange = (e: React.SyntheticEvent, value: OrganizationHit | null, reason: AutocompleteChangeReason) => {
    setError(name, undefined);

    if (reason === "clear") {
      handleChange({
        target: {
          type: "text",
          name: name,
          value: null,
        },
      } as FormChangeEvent);
    }

    if (!value) {
      return;
    }
    const parts = value.objectID.split("::");

    handleChange({
      target: {
        type: "text",
        name: name,
        value: `/organizations/${parts[1]}`,
      },
    } as FormChangeEvent);
  };

  const handleInputChange = (e: React.SyntheticEvent, newInputValue: string) => {
    setInputValue(newInputValue);
    fetchOptions(newInputValue);
  };

  const value = formValue(values, name) ? formValue(values, name).split("/").pop() : null;

  return (
    <FormControl fullWidth={fullWidth} margin={margin} error={error}>
      <Autocomplete
        key={name}
        renderInput={(params) => <TextField {...params} label={label} sx={validatorStyle.textField} />}
        options={options}
        value={_find(options, (option) => option.objectID === (value ? `organization::${value}` : "")) || null}
        onChange={handleAutoCompleteValueChange}
        onInputChange={handleInputChange}
        getOptionLabel={(option) => option.name || ""}
        noOptionsText={inputValue.trim() ? noOptionsText : emptyText}
        isOptionEqualToValue={(option, value) => option.objectID === value.objectID}
        {...rest}
      />
      <FormHelperText>{_get(errors, name) || helperText}</FormHelperText>
    </FormControl>
  );
}
