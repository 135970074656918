import React from "react";
import wizardStyle from "../wizardStyle";
import { Box, Grid, Paper } from "@mui/material";
import FormFader from "../Common/Fader/FormFader";
import FormLegend from "../Common/FormLegend/FormLegend";
import FormSubmitProps from "../../../types/FormSubmitProps";
import { useForm } from "../../../provider/Form/FormProvider";
import FormSubmit from "../Common/FormSubmit/FormSubmit";
import Address from "../Common/Address/Address";
import Group from "../../Wizard/Group";
import { translate } from "../../../services/translations/translations";
import IconPerson from "../../../assets/icons/provision/toggleable/IconPerson";
import IconCompany from "../../../assets/icons/contract/toggleable/IconCompany";
import Person from "../Common/Person/Person";
import TextFieldConfigured from "../ConfiguredFormElements/TextFieldConfigured/TextFieldConfigured";
import ValidatorOrganizationAutocomplete from "../../FormFields/ValidatorOrganizationAutocomplete";
import IconQuestionmark from "../../../assets/icons/common/toggleable/IconQuestionmark";

type OpposingPartyProps = {
  submitProps: FormSubmitProps;
};

export default function OpposingParty({ submitProps }: OpposingPartyProps) {
  const { handleSubmit, values } = useForm();

  return (
    <Paper sx={wizardStyle.paper} elevation={4}>
      <FormFader>
        <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmit}>
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
              <FormLegend><strong>Angaben zum Unfallgegner</strong></FormLegend>
            </Grid>
            <TextFieldConfigured caseData={values} fieldPath={"licensePlateOpposingParty"} required />
            <Grid item xs={12}>
              <Group
                type="radio"
                name="opposingPartyType"
                question={translate("trafficAccident.opposingPartyType.question", values)}
                options={[
                  {
                    labelText: translate("trafficAccident.opposingPartyType.options.opposingPartyPerson.label", values),
                    labelIcon: <IconPerson />,
                    labelIconActive: <IconPerson active />,
                    value: "opposingPartyPerson",
                  },
                  {
                    labelText: translate("trafficAccident.opposingPartyType.options.opposingPartyLegalEntity.label", values),
                    labelIcon: <IconCompany />,
                    labelIconActive: <IconCompany active />,
                    value: "opposingPartyLegalEntity",
                  },
                  {
                    labelText: translate("trafficAccident.opposingPartyType.options.null.label", values),
                    labelIcon: <IconQuestionmark />,
                    labelIconActive: <IconQuestionmark active />,
                    value: "null",
                  },
                ]}
              />
            </Grid>
            {values.opposingPartyType === "opposingPartyPerson" && (
              <>
                <Person path="opposingPartyPerson" headline={"Wie heißt der Unfallgegner?"} />
                <Address
                  path="opposingPartyPerson.residenceAddress"
                  headline={
                    "Bitte geben Sie möglichst alle weiteren Daten zum Unfallgegner an, die Ihnen zum jetzigen Zeitpunkt vorliegen. Wenn Ihnen Daten (noch) nicht vorliegen, lassen Sie die Felder gerne einfach leer."
                  }
                  fieldsAreRequired={false}
                />
              </>
            )}
            {values.opposingPartyType === "opposingPartyLegalEntity" && (
              <>
                <TextFieldConfigured caseData={values} fieldPath={"opposingPartyLegalEntity.legalName"} required />
                <Address
                  path="opposingPartyLegalEntity.address"
                  headline={
                    "Bitte geben Sie möglichst alle weiteren Daten zum Unfallgegner an, die Ihnen zum jetzigen Zeitpunkt vorliegen. Wenn Ihnen Daten (noch) nicht vorliegen, lassen Sie die Felder gerne einfach leer."
                  }
                  fieldsAreRequired={false}
                />
              </>
            )}
            {values.opposingPartyType !== "null" && (
              <>
                <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
                  <FormLegend>{translate("trafficAccident.opposingCarLiabilityInsurance.question", values)}</FormLegend>
                </Grid>
                <Grid item xs={12}>
                  <ValidatorOrganizationAutocomplete
                    label={translate("trafficAccident.opposingCarLiabilityInsurance.label", values)}
                    name="opposingCarLiabilityInsurance"
                    type={"opposingCarLiabilityInsurance"}
                  />
                </Grid>

                <TextFieldConfigured caseData={values} fieldPath={"opposingInsurancePolicyNumber"} />
                <TextFieldConfigured caseData={values} fieldPath={"opposingCaseReferenceNumber"} />
              </>
            )}
          </Grid>
          <FormSubmit {...submitProps} />
        </Box>
      </FormFader>
    </Paper>
  );
}
