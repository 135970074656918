import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconCarAndPersonDamage = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g>
        {!active && (
            <path
                fill="#137F7B"
                d="M19.723,211.107c-1.113,1.711-1.8,3.598-2.043,5.611v7.117h7.17c1.204-7.453,7.664-13.17,15.452-13.17
		c6.489,0,12.381,4.08,14.66,10.152c0.485,1.293-0.17,2.734-1.463,3.221c-0.29,0.107-0.588,0.15-0.882,0.15v2.148h5.429
		c0-0.002-0.001-0.006-0.001-0.008c0-1.381,1.119-2.5,2.5-2.5h51.953c1.381,0,2.5,1.119,2.5,2.5c0,0.002-0.002,0.006-0.002,0.008
		h2.739c0-8.643,7.03-15.672,15.673-15.672c6.488,0,12.383,4.08,14.662,10.15c0.486,1.293-0.168,2.734-1.461,3.221
		c-0.289,0.109-0.588,0.152-0.881,0.152v2.146h3.542c0-1.014,0.61-1.969,1.614-2.344c1.34-0.512,4.309-2.186,4.479-3.857v-10.383
		H20.726C20.369,210.191,20.027,210.639,19.723,211.107z"
            />
        )}
        <path
          fill="#137F7B"
          d="M189.484,80.419h15.391c0.865,0,1.566-0.702,1.566-1.567s-0.701-1.566-1.566-1.566h-13.823V63.328
		c0-0.865-0.702-1.567-1.566-1.567c-0.865,0-1.567,0.702-1.567,1.567v15.524C187.917,79.717,188.619,80.419,189.484,80.419z"
        />
        <path
          fill="#137F7B"
          d="M183.045,83.85h-15.398c-0.865,0-1.567,0.702-1.567,1.567c0,0.864,0.702,1.566,1.567,1.566h13.832v13.959
		c0,0.865,0.701,1.567,1.566,1.567s1.566-0.702,1.566-1.567V85.417C184.612,84.552,183.91,83.85,183.045,83.85z"
        />
        <path
          fill="#137F7B"
          d="M183.086,61.759c-0.865,0-1.566,0.702-1.566,1.567v13.958h-13.832c-0.864,0-1.566,0.701-1.566,1.566
		s0.702,1.567,1.566,1.567h15.398c0.865,0,1.566-0.702,1.566-1.567V63.327C184.653,62.461,183.951,61.759,183.086,61.759z"
        />
        <path
          fill="#137F7B"
          d="M204.837,83.85h-15.392c-0.864,0-1.566,0.702-1.566,1.567v15.525c0,0.865,0.702,1.567,1.566,1.567
		c0.865,0,1.567-0.702,1.567-1.567V86.984h13.823c0.865,0,1.566-0.702,1.566-1.566C206.402,84.552,205.702,83.85,204.837,83.85z"
        />
      </g>
      <g fill={getIconColor(active, theme)}>
        <path
          d="M286.77,242.008v-72.58c0-1.381-1.118-2.5-2.5-2.5c-1.381,0-2.5,1.119-2.5,2.5v67.58h-75.709v-57.393
		h-39.004v59.893c0,1.381,1.118,2.5,2.5,2.5c1.381,0,2.5-1.119,2.5-2.5v-54.893h29.004v57.393H286.77z"
        />
        <path
          d="M86.335,164.65c0,1.381,1.119,2.5,2.5,2.5s2.5-1.119,2.5-2.5l-0.001-63.681
		c-0.003-0.176-0.041-4.348,3.527-7.984c3.599-3.668,9.555-5.526,17.701-5.526h29.477c1.381,0,2.5-1.119,2.5-2.5
		c0-1.382-1.119-2.5-2.5-2.5h-29.477c-9.613,0-16.805,2.397-21.371,7.13c-5.019,5.194-4.864,11.281-4.856,11.45V164.65z"
        />
        <path
          d="M162.21,114.759h48.103c4.635,0,13.4-2.801,13.4-13.401V62.903c0-4.632-2.801-13.392-13.4-13.392H162.21
		c-4.635,0-13.401,2.799-13.401,13.392v38.455C148.809,105.993,151.609,114.759,162.21,114.759z M153.809,62.903
		c0-8.07,7.554-8.383,8.401-8.392h48.103c8.08,0,8.393,7.544,8.4,8.392v38.455c0,8.067-7.528,8.393-8.41,8.401H162.21
		c-8.081,0-8.394-7.553-8.401-8.401V62.903z"
        />
        <path
          d="M221.23,206.59c0,2.768,1.673,8,8,8h29.469c2.77,0,8.008-1.672,8.008-8v-19.15
		c0-2.77-1.674-8.008-8.008-8.008H229.23c-2.767,0-8,1.674-8,8.008V206.59z M224.365,187.439c0-4.588,4.06-4.865,4.865-4.873h29.468
		c4.587,0,4.864,4.066,4.873,4.873v19.15c0,4.574-4.059,4.854-4.879,4.865H229.23c-4.579,0-4.856-4.061-4.865-4.865V187.439
		L224.365,187.439z"
        />
        <path
          d="M258.698,163.65c2.77,0,8.008-1.674,8.008-8.01V136.52c0-2.767-1.674-7.999-8.008-7.999H229.23
		c-2.768,0-8,1.672-8,7.999v19.121c0,2.771,1.672,8.01,8,8.01H258.698z M224.365,155.641V136.52c0-4.578,4.06-4.854,4.865-4.864
		h29.468c4.587,0,4.864,4.06,4.873,4.864v19.121c0,4.584-4.059,4.865-4.879,4.875H229.23
		C224.65,160.516,224.374,156.447,224.365,155.641z"
        />
        <path
          d="M201.566,163.65c2.768,0,8-1.674,8-8.01V136.52c0-2.767-1.673-7.999-8-7.999h-29.466
		c-2.771,0-8.011,1.672-8.011,7.999v19.121c0,2.771,1.674,8.01,8.011,8.01H201.566z M167.227,155.641V136.52
		c0-4.578,4.067-4.854,4.875-4.864h29.467c4.578,0,4.854,4.06,4.863,4.864v19.121c0,4.584-4.051,4.865-4.869,4.875h-29.461
		C167.512,160.516,167.234,156.447,167.227,155.641z"
        />
        <path
          d="M152.014,136.52c0-2.767-1.672-7.999-7.999-7.999h-29.466c-2.771,0-8.011,1.672-8.011,7.999v19.121
		c0,2.771,1.674,8.01,8.011,8.01h29.466c2.768,0,7.999-1.674,7.999-8.01V136.52z M148.879,155.641c0,4.584-4.051,4.865-4.87,4.875
		h-29.46c-4.59,0-4.867-4.068-4.876-4.875V136.52c0-4.578,4.068-4.854,4.876-4.864h29.466c4.578,0,4.855,4.06,4.864,4.864V155.641z"
        />
        <path
          d="M192.611,206.59c0.009,1.201,0.994,2.168,2.193,2.158c0.002,0,0.009,0,0.012,0
		c1.19-0.027,2.136-1.016,2.106-2.205c-0.026-1.191-1.013-2.137-2.204-2.107C193.547,204.461,192.611,205.418,192.611,206.59z"
        />
        <path
          d="M285.756,247.355H17.615c-0.865,0-1.567,0.701-1.567,1.566s0.702,1.566,1.567,1.566h268.141
		c0.865,0,1.566-0.701,1.566-1.566S286.621,247.355,285.756,247.355z"
        />
        <path
          d="M231.115,87.458h29.477c8.122,0,14.068,1.855,17.674,5.52c3.582,3.642,3.559,7.823,3.555,8.062v50.989
		c0,1.381,1.119,2.5,2.5,2.5s2.5-1.119,2.5-2.5l-0.001-50.909c0.008-0.258,0.144-6.35-4.89-11.544
		c-4.572-4.723-11.752-7.116-21.338-7.116h-29.477c-1.381,0-2.5,1.119-2.5,2.5S229.734,87.458,231.115,87.458z"
        />
        <path
          d="M60.545,223.832c-1.381,0-2.5,1.117-2.5,2.5c0,0.002,0.001,0.004,0.001,0.006
		c0.003,1.379,1.12,2.494,2.499,2.494h51.953c1.379,0,2.496-1.115,2.499-2.494c0-0.002,0.001-0.004,0.001-0.006
		c0-1.383-1.119-2.5-2.5-2.5H60.545z"
        />
        <path
          d="M155.365,220.137c-0.172,1.672-3.141,3.346-4.479,3.857c-1.004,0.377-1.614,1.33-1.614,2.344
		c0,0.291,0.043,0.586,0.151,0.875c0.376,1.004,1.329,1.623,2.341,1.623c0.292,0,0.59-0.053,0.878-0.16
		c0.754-0.283,7.382-2.918,7.72-8.285l0.004-26.801h-52.26c-1.381,0-2.5,1.119-2.5,2.5s1.119,2.5,2.5,2.5h47.26v11.164V220.137
		L155.365,220.137z"
        />
        <path
          d="M40.302,242.008c6.509,0,12.4-4.088,14.663-10.174c0.48-1.293-0.179-2.732-1.473-3.215
		c-1.292-0.48-2.732,0.178-3.215,1.473c-1.538,4.137-5.547,6.916-9.977,6.916c-5.563,0-10.138-4.277-10.624-9.715
		c0.123-0.295,0.192-0.617,0.192-0.957c0-0.342-0.069-0.662-0.191-0.959c0.487-5.436,5.063-9.711,10.623-9.711
		c4.418,0,8.428,2.775,9.979,6.908c0.377,1.004,1.329,1.613,2.338,1.615c0.293,0,0.591-0.043,0.881-0.15
		c1.293-0.486,1.948-1.928,1.463-3.221c-2.279-6.072-8.171-10.152-14.66-10.152c-7.788,0-14.248,5.717-15.452,13.17h-7.17v-7.117
		c0.243-2.016,0.93-3.9,2.043-5.611c0.306-0.469,0.646-0.916,1.006-1.354c7.572-9.223,26.603-11.164,31.526-11.164h48.599v-15.672
		c0-1.381-1.119-2.5-2.5-2.5c-1.382,0-2.5,1.119-2.5,2.5v10.672H56.295c3.902-3.553,9.14-8.369,11.627-10.855
		c3.813-3.813,6.182-5.607,11.403-5.607h46.024l11.702,11.932c0.969,0.986,2.55,1,3.536,0.035c0.984-0.967,1.001-2.551,0.033-3.537
		l-13.173-13.43H79.323c-7.3,0-10.796,2.932-14.938,7.072c-3.593,3.592-13.476,12.529-15.765,14.598
		c-7.759,0.654-25.827,3.375-33.093,14.588c-1.565,2.404-2.521,5.066-2.835,7.916l-0.016,12.535h12.168
		C26.051,236.291,32.512,242.008,40.302,242.008z"
        />
        <path
          d="M133.408,215.666c4.418,0,8.431,2.777,9.982,6.91c0.376,1,1.328,1.613,2.338,1.615
		c0.293,0,0.592-0.045,0.881-0.154c1.293-0.484,1.947-1.928,1.461-3.219c-2.279-6.072-8.174-10.152-14.662-10.152
		c-8.643,0-15.673,7.029-15.673,15.672c0,8.641,7.03,15.67,15.673,15.67c6.504,0,12.397-4.088,14.666-10.172
		c0.481-1.293-0.177-2.732-1.471-3.215c-1.297-0.484-2.733,0.176-3.216,1.469c-1.542,4.139-5.553,6.918-9.979,6.918
		c-5.886,0-10.673-4.785-10.673-10.67C122.736,220.453,127.523,215.666,133.408,215.666z"
        />
      </g>
    </SvgIcon>
  );
};

export default IconCarAndPersonDamage;
