/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import wizardStyle from "../wizardStyle";
import FormFader from "../Common/Fader/FormFader";
import Hidden from "@mui/material/Hidden";
import Grid from "@mui/material/Grid";
import MultipleFileUpload from "../Common/FileUpload/MultipleFileUpload";
import FormSubmit from "../Common/FormSubmit/FormSubmit";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import FormLegend from "../Common/FormLegend/FormLegend";
import CaseSpecificDocumentUploadSection from "./CaseSpecificDocumentUploadSection";
import { filterMediaObjectsByFieldname } from "../../../services/mediaObjects";
import { useForm } from "../../../provider/Form/FormProvider";
import { ContractDocumentsProps } from "../../../types/ContractDocuments";
import moment from "moment";

export default function RentalContractDocumentsFlexService({
  product,
  submitProps,
  refreshCase,
  refreshMediaObjectCollection,
  mediaObjectCollection,
  submitIsDisabled,
  setCaseSpecificSubmitDisabled,
}: ContractDocumentsProps) {
  const { handleSubmit } = useForm();

  let headline =
    "Sie können hier gerne Unterlagen zu Ihrem Fall hochladen. Wenn Sie keine Unterlagen hochladen möchten, klicken Sie unten einfach auf “WEITER”.";

  return (
    <FormFader>
      <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmit}>
        <Hidden smUp>
          <Typography variant="h4" sx={wizardStyle.headline}>
            Unterlagen
          </Typography>
        </Hidden>
        <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
          <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
            <FormLegend>{headline}</FormLegend>
          </Grid>
          <CaseSpecificDocumentUploadSection
            fetchMediaObjectCollection={refreshMediaObjectCollection}
            mediaObjectCollection={mediaObjectCollection}
            product={product}
            setCaseSpecificSubmitDisabled={setCaseSpecificSubmitDisabled}
            refreshCase={refreshCase}
          />
          <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
            <Typography variant="h5">Ihre Unterlagen</Typography>
            <p>Laden Sie hier bitte Ihre Unterlagen hoch, die zur weiteren Bearbeitung des Falls relevant sind.</p>
            <MultipleFileUpload
              product={product}
              fieldname={"applicationDocument"}
              description={"Weitere Unterlagen vom " + moment().format("DD.MM.YYYY")}
              mediaObjectCollection={filterMediaObjectsByFieldname(mediaObjectCollection, "applicationDocument")}
              fetchMediaObjects={refreshMediaObjectCollection}
            />
          </Grid>
        </Grid>
        <br />
        <br />
        <FormSubmit {...submitProps} disabled={submitIsDisabled} />
      </Box>
    </FormFader>
  );
}
