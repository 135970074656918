import React from "react";
import wizardStyle from "../wizardStyle";
import { Box, Grid, Paper } from "@mui/material";
import FormFader from "../Common/Fader/FormFader";
import FormLegend from "../Common/FormLegend/FormLegend";
import FormSubmitProps from "../../../types/FormSubmitProps";
import { useForm } from "../../../provider/Form/FormProvider";
import FormSubmit from "../Common/FormSubmit/FormSubmit";
import Address from "../Common/Address/Address";
import Group from "../../Wizard/Group";
import { translate } from "../../../services/translations/translations";
import IconPerson from "../../../assets/icons/provision/toggleable/IconPerson";
import Person from "../Common/Person/Person";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import IconFamilyDetailed from "../../../assets/icons/generic/toggleable/IconFamilyDetailed";
import IconCar from "../../../assets/icons/contract/toggleable/IconCar";
import AutocompleteSelectFieldConfigured from "../ConfiguredFormElements/AutocompleteSelectFieldConfigured/AutocompleteSelectFieldConfigured";
import TextFieldConfigured from "../ConfiguredFormElements/TextFieldConfigured/TextFieldConfigured";
import IconData from "../../../assets/icons/generic/toggleable/IconData";
import IconContract from "../../../assets/icons/contract/toggleable/IconContract";
import IconTools from "../../../assets/icons/contract/toggleable/IconTools";
import IconClose from "../../../assets/icons/common/toggleable/IconClose";
import {requiredFieldDefault} from "../../../services/validationRules";

type DamageDetailsProps = {
  submitProps: FormSubmitProps;
  product: AbstractCase;
};

export default function DamageDetails({ submitProps, product }: DamageDetailsProps) {
  const { handleSubmit, values } = useForm();

  return (
    <Paper sx={wizardStyle.paper} elevation={4}>
      <FormFader>
        <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmit}>
          <Grid container justifyContent={"center"} alignItems={"center"} spacing={2}>
            <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
              <FormLegend><strong>Details zum Schaden</strong></FormLegend>
            </Grid>
            {["vehicle", "vehicleAndPerson"].includes(product.typeOfDamage) && (
              <>
                <Grid item xs={12}>
                  <Group
                    type="radio"
                    name="driverOfClientVehicle"
                    question={translate("trafficAccident.driverOfClientVehicle.question", values)}
                    options={[
                      {
                        labelText: translate("trafficAccident.driverOfClientVehicle.options.client.label", values),
                        labelIcon: <IconPerson />,
                        labelIconActive: <IconPerson active />,
                        value: "client",
                      },
                      {
                        labelText: translate("trafficAccident.driverOfClientVehicle.options.other.label", values),
                        labelIcon: <IconFamilyDetailed />,
                        labelIconActive: <IconFamilyDetailed active />,
                        value: "other",
                      },
                      {
                        labelText: translate("trafficAccident.driverOfClientVehicle.options.nobody.label", values),
                        labelIcon: <IconCar />,
                        labelIconActive: <IconCar active />,
                        value: "nobody",
                      },
                    ]}
                    validators={[requiredFieldDefault]}
                  />
                </Grid>
                {values.driverOfClientVehicle === "other" && (
                  <>
                    <Person path="driverPerson" headline={"Wie heißt der Fahrer?"} />
                    <Address path="driverPerson.residenceAddress" headline={"Wo wohnt diese Person?"} />
                  </>
                )}
                <TextFieldConfigured caseData={values} fieldPath={"licensePlateClientVehicle"} required />
                <AutocompleteSelectFieldConfigured caseData={values} fieldPath={"vehicleMake"} />
                <TextFieldConfigured caseData={values} fieldPath={"vehicleModel"} />
                <TextFieldConfigured caseData={values} fieldPath={"vehicleId"} />
                <Grid item xs={12}>
                  <Group
                    type="radio"
                    name="damageEstimationDocumentType"
                    question={translate("trafficAccident.damageEstimationDocumentType.question", values)}
                    options={[
                      {
                        labelText: translate("trafficAccident.damageEstimationDocumentType.options.costEstimate.label", values),
                        labelIcon: <IconData />,
                        labelIconActive: <IconData active />,
                        value: "costEstimate",
                      },
                      {
                        labelText: translate("trafficAccident.damageEstimationDocumentType.options.assessment.label", values),
                        labelIcon: <IconContract />,
                        labelIconActive: <IconContract active />,
                        value: "assessment",
                      },
                      {
                        labelText: translate("trafficAccident.damageEstimationDocumentType.options.repairInvoice.label", values),
                        labelIcon: <IconTools />,
                        labelIconActive: <IconTools active />,
                        value: "repairInvoice",
                      },
                      {
                        labelText: translate("trafficAccident.damageEstimationDocumentType.options.noDocument.label", values),
                        labelIcon: <IconClose />,
                        labelIconActive: <IconClose active />,
                        value: "noDocument",
                      },
                    ]}
                    validators={[requiredFieldDefault]}
                  />
                </Grid>
              </>
            )}
            {["person", "vehicleAndPerson"].includes(product.typeOfDamage) && (
              <>
                <TextFieldConfigured caseData={values} fieldPath={"descriptionInjuries"} required />
              </>
            )}
            {product.typeOfDamage === "other" && (
              <>
                <TextFieldConfigured caseData={values} fieldPath={"descriptionDamage"} required />
              </>
            )}
          </Grid>
          <FormSubmit {...submitProps} />
        </Box>
      </FormFader>
    </Paper>
  );
}
