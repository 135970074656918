/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import {
  APPLICATION_STATUS_CONTRACT_BASE,
  APPLICATION_STATUS_CONTRACT_DESCRIPTION,
  APPLICATION_STATUS_CONTRACT_DOCUMENTS,
  APPLICATION_STATUS_DONE,
  APPLICATION_STATUS_POWER_OF_ATTORNEY,
  APPLICATION_STATUS_SUMMARY,
} from "../../contractLaw/applicationStatus";
import React from "react";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import { getCaseLink } from "../../productService";
import RentalContractBase from "../../../components/Form/RentalContract/RentalContractBase";
import progressBarSteps from "./progressBarSteps";
import { Navigate } from "react-router-dom";
import Summary from "../../../components/Form/RentalContract/Summary";
import SummaryHintBox from "../../../components/Form/Common/SummaryHintBox";
import PowerOfAttorney from "../../../components/Form/RentalContract/PowerOfAttorney";
import ContractDocumentsWrapper from "../../../components/Form/ContractLaw/DocumentUploadForm/ContractDocumentsWrapper";
import { FormSteps } from "../../../types/FormServiceBase";
import ContractDescription from "../../../components/Form/RentalContract/ContractDescription";
import RentalContractDocumentsFlexService from "../../../components/Form/RentalContract/RentalContractDocumentsFlexService";

const formSteps: FormSteps = {
  [APPLICATION_STATUS_CONTRACT_BASE]: {
    title: "Antrag - Ihr Vertrag | Legalbird",
    applicationStatus: APPLICATION_STATUS_CONTRACT_BASE,
    stepUrl: "vertrag",
    render: (props: any) => (
      <>
        <ProgressBar steps={progressBarSteps} active={"contract"} progress={25} />
        <RentalContractBase {...props} hideCaseCategories={true} alternativeHeadline="Ihre Angaben" />
      </>
    ),
  },
  [APPLICATION_STATUS_CONTRACT_DESCRIPTION]: {
    title: "Antrag - Beschreibung | Legalbird",
    applicationStatus: APPLICATION_STATUS_CONTRACT_DESCRIPTION,
    stepUrl: "beschreibung",
    render: (props: any) => (
      <>
        <ProgressBar steps={progressBarSteps} active={"contract"} progress={25} />
        <ContractDescription
          {...props}
          alternateDescription={
            "Bitte schildern Sie uns hier kurz Ihre Situation und Fragen. Jede relevante Information hilft uns, Ihren Fall besser bearbeiten zu können. Im nächsten Schritt können Sie - wenn gewünscht - noch ergänzende Dokumente hochladen."
          }
        />
      </>
    ),
  },
  [APPLICATION_STATUS_CONTRACT_DOCUMENTS]: {
    title: "Antrag - Dokumente | Legalbird",
    applicationStatus: APPLICATION_STATUS_CONTRACT_DOCUMENTS,
    stepUrl: "unterlagen",
    render: (props: any) => (
      <>
        <ProgressBar steps={progressBarSteps} active={"documents"} progress={75} />
        <ContractDocumentsWrapper {...props} DocumentsFormComponent={RentalContractDocumentsFlexService} />
      </>
    ),
  },
  [APPLICATION_STATUS_SUMMARY]: {
    title: "Antrag - Überprüfen | Legalbird",
    applicationStatus: APPLICATION_STATUS_SUMMARY,
    stepUrl: "ueberpruefen",
    render: (props: any) => (
      <>
        <SummaryHintBox />
        <Summary {...props} />
      </>
    ),
  },
  [APPLICATION_STATUS_POWER_OF_ATTORNEY]: {
    title: "Antrag - Vollmacht | Legalbird",
    applicationStatus: APPLICATION_STATUS_POWER_OF_ATTORNEY,
    stepUrl: "vollmacht",
    render: (props: any) => <PowerOfAttorney {...props} />,
  },
  [APPLICATION_STATUS_DONE]: {
    title: "Mein Vertrag | Legalbird",
    applicationStatus: APPLICATION_STATUS_DONE,
    stepUrl: "mein-vertrag",
    render: ({ product }: any) => <Navigate to={getCaseLink(product)} />,
  },
};

export default formSteps;
