/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconCallOrAiService = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g>
        <path
          style={{ fill: "#333" }}
          d="M240.7,16.3H59.3c-7.1,0-12.9,5.8-12.9,12.9v241.6c0,7.1,5.8,12.9,12.9,12.9h181.3c7.1,0,12.9-5.8,12.9-12.9V29.2c0-7.1-5.8-12.9-12.9-12.9ZM246.2,270.8c0,3-2.5,5.5-5.5,5.5H59.3c-3,0-5.5-2.5-5.5-5.5V29.2c0-3,2.5-5.5,5.5-5.5h181.3c3,0,5.5,2.5,5.5,5.5v241.6h0Z"
        />
        <path
          style={{ fill: theme.palette.primary.main }}
          d="M114.9,65.7h1.2c2,0,3.7-1.7,3.7-3.7s-1.6-3.7-3.7-3.7h-1.2c-2,0-3.7,1.6-3.7,3.7s1.6,3.7,3.7,3.7Z"
        />
        <path
          style={{ fill: theme.palette.primary.main }}
          d="M194.1,58.3h-67.3c-2,0-3.7,1.6-3.7,3.7s1.6,3.7,3.7,3.7h67.3c2,0,3.7-1.7,3.7-3.7s-1.6-3.7-3.7-3.7Z"
        />
        <path
          style={{ fill: theme.palette.primary.main }}
          d="M194.1,73.7h-79.2c-2,0-3.7,1.6-3.7,3.7s1.6,3.7,3.7,3.7h79.2c2,0,3.7-1.7,3.7-3.7s-1.6-3.7-3.7-3.7Z"
        />
        <path
          style={{ fill: theme.palette.primary.main }}
          d="M114.9,96.5h48.3c2,0,3.7-1.7,3.7-3.7s-1.6-3.7-3.7-3.7h-48.3c-2,0-3.7,1.6-3.7,3.7s1.6,3.7,3.7,3.7Z"
        />
        <rect
          style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3px" }}
          x="69.5"
          y="46.1"
          width="165.6"
          height="61.6"
          rx="6.2"
          ry="6.2"
        />
        <g id="Layer_40-2" data-name="Layer_40">
          <path
            style={{ fill: "#333" }}
            d="M191.7,207.5c-2.2-1.6-12.5-8.2-14.8-9.4-1-.5-2-.8-3.2-.8-2.1,0-4.3.8-7.4,2.7-1.8,1.1-2.9,1.8-3.7,2.2-2.4-1.9-8.5-6.9-12.2-10.3-3.7-3.4-8.5-9.5-10.4-11.9.4-.7,1.1-1.9,2.2-3.7,2-3.3,3.7-7.1,1.8-10.6-1.2-2.2-7.9-12.5-9.5-14.7-1.3-1.8-2.8-2.7-4.6-2.7s-2.6.4-4.1,1.4c-2.7,1.6-5.9,4.7-6.4,5.2-.8.7-5,4.4-5,8.6s4.1,22,24.4,40.3c19.8,17.9,36.1,23.9,40.4,23.9h0c4.2,0,7.9-4.3,8.5-5.1.5-.5,3.5-3.7,5.1-6.4.8-1.4,3.2-5.5-1.4-8.7ZM189.9,214.3c-1.5,2.5-4.6,5.7-4.6,5.7h-.1c-.8,1.1-3.5,3.9-5.7,3.9h0c-3.2,0-18.7-5.6-37.9-22.9-19.1-17.2-23.2-34.2-23.2-37.5s2.8-4.9,3.7-5.7h.1c0-.2,3.2-3.3,5.7-4.8.9-.6,1.7-.8,2.2-.8s.7,0,1.5,1.1c1.6,2.3,8.2,12.3,9.3,14.3.5,1,.8,2.6-1.7,6.8-2.5,4.1-2.9,4.8-2.9,4.8l-.7,1.1.8,1c.3.4,6.8,8.9,11.6,13.4,4.8,4.4,13.1,11,13.4,11.3l1,.8,1.1-.7s.7-.4,4.8-2.9c2.4-1.4,4.1-2.1,5.4-2.1s1,.1,1.4.3c2,1,12.1,7.6,14.4,9.1,1.2.8,1.7,1.3.3,3.7Z"
          />
        </g>
        <path
          style={{ fill: theme.palette.primary.main }}
          d="M125.9,242.5h48.3c2,0,3.7-1.7,3.7-3.7s-1.6-3.7-3.7-3.7h-48.3c-2,0-3.7,1.6-3.7,3.7s1.6,3.7,3.7,3.7Z"
        />
      </g>
    </SvgIcon>
  );
};

export default IconCallOrAiService;
