import React, { FormEvent, useState } from "react";
import Paper from "@mui/material/Paper";
import HintBox from "../../Box/HintBox/HintBox";
import FileUploadSection from "../Common/FileUploadSection/FileUploadSection";
import { useForm } from "../../../provider/Form/FormProvider";
import Typography from "@mui/material/Typography";
import { Box, Stack } from "@mui/material";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import useMediaObjectCombinationRequest from "../../../hooks/useMediaObjectCombinationRequest";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import FormSubmit from "../Common/FormSubmit/FormSubmit";
import FormSubmitProps from "../../../types/FormSubmitProps";
import { getCaseLink } from "../../../services/productService";

export default function Documents({ product, submitProps }: { product: AbstractCase; submitProps: FormSubmitProps }) {
  let mediaObjectCombinationRequestTypes = ["files"];

  const { mediaObjectCombinationRequests, isLoadingMediaObjectCombinationRequest, hasAtLeastOneFileOfType, updateAction } = useMediaObjectCombinationRequest(
    product,
    mediaObjectCombinationRequestTypes
  );

  const { handleSubmit } = useForm();
  const [isBlocked, setIsBlocked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isSubmittable = !(isBlocked || !hasAtLeastOneFileOfType(mediaObjectCombinationRequests, "files"));

  const handleSubmitInternal = async (e: FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    await handleSubmit(e);
  };

  const hasFineNotice = product.authorityNotification === "fine_notice";
  if (isLoadingMediaObjectCombinationRequest) {
    return <LegalbirdLoader />;
  }

  return (
    <>
      <HintBox headline={"Ihre Angaben sind vollständig. Jetzt " + (hasFineNotice ? "Bußgeldbescheid" : "Dokument") + " hochladen!"}>
        {product.authorityNotification !== "no_notice" ? (
          <p>
            Damit wir Ihnen eine kostenlose Ersteinschätzung geben können, benötigen wir jetzt noch
            {hasFineNotice
              ? " Ihren Bußgeldbescheid. "
              : " das dazugehörige Dokument. Laden Sie hier bitte den Bußgeldbescheid, Anhörungsbogen, Zeugenfragebogen oder ähnliches hoch. "}
            Wir melden uns dann zeitnah bei Ihnen, um das weitere Vorgehen zu besprechen.
          </p>
        ) : (
          <p>
            Da Sie bisher kein Dokument von der Behörde erhalten haben, melden wir uns nun zeitnah bei Ihnen, um das weitere Vorgehen zu besprechen. Sobald Sie
            Post von der Behörde bekommen haben, können Sie hier den Anhörungsbogen, Zeugenfragebogen oder Bußgeldbescheid hochladen und wir prüfen Ihren
            Bescheid kostenlos.
          </p>
        )}
      </HintBox>

      <Paper sx={{ padding: "2rem" }}>
        <Stack justifyContent={"center"} alignItems={"center"} component={"form"} onSubmit={handleSubmitInternal}>
          <Typography variant={"h4"} sx={{ color: (theme) => theme.palette.primary.main }}>
            Wichtig
          </Typography>
          <Box maxWidth={"700px"} margin={"0 auto"} textAlign={"center"}>
            Laden Sie hier {hasFineNotice ? "Ihren Bußgeldbescheid" : "Ihr Dokument"} als Bild oder PDF hoch, oder fotografieren Sie{" "}
            {hasFineNotice ? "ihn" : "es"} einfach ab. Bitte beachten Sie dabei, dass alles gut erkennbar und nichts abgeschnitten ist. Benutzen Sie bestenfalls
            beim Fotografieren den Blitz.
            <Box textAlign={"right"}>
              <FileUploadSection
                mediaObjectCombinationRequest={mediaObjectCombinationRequests.files}
                temporaryMediaObjectsFieldname={"files"}
                headline={""}
                helperText={""}
                productEntity={product}
                productEntityId={product.id}
                product={"traffic"}
                updateAction={updateAction}
                setIsBlocked={setIsBlocked}
                isBlocked={isBlocked}
                fieldName={hasFineNotice ? "fineNotice" : "trafficGeneralFile"}
              />
            </Box>
          </Box>
          <FormSubmit
            {...submitProps}
            disabled={!isSubmittable}
            isLoading={isLoading}
            nextText={"Kostenlose Ersteinschätzung"}
            backLink={getCaseLink(product) + "/antrag/schreiben"}
          />
        </Stack>
      </Paper>
    </>
  );
}
