/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React, { useEffect, useRef, useState } from "react";
import Container from "../../../Container/Container";
import AndreasQuaukeImage from "../../../../assets/img/employees/Andreas_Quauke.png";
import AngelaFeilImage from "../../../../assets/img/employees/Angela_Feil.png";
import BekeNeumannImage from "../../../../assets/img/employees/Beke_Neumann.png";
import ChristopherKrauseImage from "../../../../assets/img/employees/Christopher_Krause.png";
import EloiseNeuhausImage from "../../../../assets/img/employees/Eloise_Neuhaus.png";
import FrankRiedelImage from "../../../../assets/img/employees/Frank_Riedel.png";
import HaraldHoevelerImage from "../../../../assets/img/employees/Harald_Hoeveler.png";
import JacquelineDiPalmoImage from "../../../../assets/img/employees/Jacqueline_DiPalmo.png";
import KristinaBeyerImage from "../../../../assets/img/employees/Kristina_Beyer.png";
import LaraVonKolontajImage from "../../../../assets/img/employees/Lara_von_Kolontaj.png";
import LindaRoemerImage from "../../../../assets/img/employees/Linda_Roemer.png";
import MajaVonKutzschenbachImage from "../../../../assets/img/employees/Maja_von_Kutzschenbach.png";
import MelanieTreudeImage from "../../../../assets/img/employees/Melanie_Treude.png";
import MichaelBickImage from "../../../../assets/img/employees/Michael_Bick.png";
import PascalinaNzingaImage from "../../../../assets/img/employees/Pascalina_Nzinga.png";
import SonjaHartmannImage from "../../../../assets/img/employees/Sonja_Hartmann.png";
import AttorneysImage from "../../../../assets/img/jobs/Anwaelte.svg";
import Typography from "@mui/material/Typography";
import PictureTeaserCard from "../../../Cards/PictureTeaserCard/PictureTeaserCard";
import teamSectionStyle from "./teamSectionStyle";
import { Box, Button, Grid, Stack } from "@mui/material";

type TeamSectionProps = {
  headline?: string;
  subHeadline?: string;
  isSubsection?: boolean;
  showHeadings?: boolean;
  sectionPadding?: boolean;
};

type EmployeeItem = {
  title: string;
  subTitle?: string;
  image: string;
};

const TeamSection = ({ headline = "", subHeadline, isSubsection = false, showHeadings = true, sectionPadding = true }: TeamSectionProps) => {
  const [showAllEmployees, setShowAllEmployees] = useState(false);
  const toggleButtonRef = useRef<HTMLDivElement | null>(null);

  const employees: EmployeeItem[] = [
    {
      title: "Andreas Quauke",
      image: AndreasQuaukeImage,
    },
    {
      title: "Angela Feil",
      image: AngelaFeilImage,
    },
    {
      title: "Béke Neumann",
      image: BekeNeumannImage,
    },
    {
      title: "Christopher Krause",
      image: ChristopherKrauseImage,
    },
    {
      title: "Eloïse Neuhaus",
      image: EloiseNeuhausImage,
    },
    {
      title: "Frank Riedel",
      image: FrankRiedelImage,
    },
    {
      title: "Harald Höveler",
      image: HaraldHoevelerImage,
    },
    {
      title: "Jacqueline Di Palmo",
      image: JacquelineDiPalmoImage,
    },
    {
      title: "Kristina Beyer",
      image: KristinaBeyerImage,
    },
    {
      title: "Lara von Kolontaj",
      image: LaraVonKolontajImage,
    },
    {
      title: "Linda Römer",
      image: LindaRoemerImage,
    },
    {
      title: "Maja von Kutzschenbach",
      image: MajaVonKutzschenbachImage,
    },
    {
      title: "Melanie Treude",
      image: MelanieTreudeImage,
    },
    {
      title: "Michael Bick",
      image: MichaelBickImage,
    },
    {
      title: "Pascalina Nzinga",
      image: PascalinaNzingaImage,
    },
    {
      title: "Sonja Hartmann",
      image: SonjaHartmannImage,
    },
    {
      title: "… und viele Weitere",
      subTitle: "sind jederzeit für Sie da!",
      image: AttorneysImage,
    },
  ];

  useEffect(() => {
    if (!showAllEmployees && toggleButtonRef.current) {
      toggleButtonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showAllEmployees]);

  return (
    <Container sectionPadding={sectionPadding}>
      {showHeadings && (
        <>
          {!isSubsection && <Box sx={teamSectionStyle.scrollToAnchor} id="partneranwaelte" />}
          <Typography variant={"h2"} sx={teamSectionStyle.headline}>
            {!isSubsection && headline}
          </Typography>
          <Typography variant={"h3"} sx={teamSectionStyle.headline}>
            {isSubsection ? headline : subHeadline}
          </Typography>
        </>
      )}
      <Grid container spacing={2} justifyContent={"center"}>
        {employees.map((item: EmployeeItem, index: number) => (
          <Grid item key={item.title} xl={3} lg={4} md={6} xs={12} sx={{ display: { xs: index < 4 || showAllEmployees ? "block" : "none", md: "block" } }}>
            <PictureTeaserCard image={item.image} title={item.title} subTitle={item.subTitle} />
          </Grid>
        ))}
      </Grid>
      <Stack ref={toggleButtonRef} direction="row" justifyContent="center" sx={{ display: { xs: "flex", md: "none" }, mt: "1rem", scrollMarginTop: "15rem" }}>
        <Button onClick={() => setShowAllEmployees((prev) => !prev)}>{showAllEmployees ? "WENIGER ANZEIGEN" : "ALLE ANZEIGEN"}</Button>
      </Stack>
    </Container>
  );
};

export default TeamSection;
