import { scrollToAnchor } from "../../../theme/commonStyle";
import { Theme } from "@mui/material";

const pageNavigationStyle = {
  scrollToAnchor,
  imageContainer: {
    alignItems: "stretch",
    width: "70px",
    height: "70px",
    padding: "14px",
    textAlign: "center",
  },
  headerIcon: {
    minWidth: "40px",
    minHeight: "40px",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  headlineContainer: {
    wordBreak: "break-word",
  },
  subHeadline: {
    fontSize: "1rem",
  },
  tertiaryHeadline: (theme: Theme) => ({
    textAlign: "center",
    background:  theme.palette.background.light,
    marginBottom: "20px",
    borderRadius: "25px",
    padding: "5px"
  }),
  navigationPaper: {
    padding: "25px 25px 0 25px",
  },
  listItem: {
    padding: 0,
    fontWeight: 400,
  },
  headlineListItem: {
    padding: 0,
    marginBottom: "25px",
  },
  navigationLink: {
    display: "block",
    textAlign: "center",
    padding: "25px",
  },
};

export default pageNavigationStyle;
