import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconCarDamage = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g>
        {!active && (
          <path
            fill="#137F7B"
            d="M32.986,187.688c-1.903,2.92-3.069,6.146-3.472,9.598v12.818h14.062
		c1.254-11.711,11.19-20.859,23.229-20.859c9.679,0,18.463,6.084,21.86,15.137c0.484,1.293-0.17,2.734-1.463,3.219
		c-0.291,0.109-0.591,0.154-0.885,0.152v4.855h10.057c0-0.002-0.002-0.006-0.002-0.008c0-1.381,1.119-2.5,2.5-2.5h102.25
		c1.382,0,2.5,1.119,2.5,2.5c0,0.002,0,0.006,0,0.008h7.263c0-12.883,10.48-23.363,23.364-23.363
		c9.674,0,18.457,6.082,21.857,15.137c0.486,1.291-0.168,2.732-1.461,3.219c-0.291,0.109-0.589,0.154-0.883,0.154v4.854h7.07
		c-0.002-1.014,0.609-1.969,1.614-2.346c1.998-0.756,7.743-3.736,8.039-7.406v-16.518H33.96
		C33.612,186.779,33.284,187.229,32.986,187.688z"
          />
        )}
      </g>
      <g fill={getIconColor(active, theme)}>
        <path
          d="M271.491,133.681c-1.639-1.638-22.188-22.188-22.188-22.188c-0.323-0.323-0.729-0.553-1.174-0.661
		l-8.025-1.964c-0.845-0.205-1.743,0.043-2.361,0.661l-1.764,1.765l-2.27-2.27l4.43-4.431c2.48,0.993,5.158,1.513,7.842,1.513
		c5.617,0,10.898-2.188,14.869-6.158c6.395-6.395,7.996-16.124,3.982-24.213c-0.356-0.723-1.04-1.228-1.835-1.356
		c-0.791-0.132-1.603,0.129-2.172,0.699L248.922,86.98l-4.862-4.861l11.903-11.903c0.569-0.569,0.83-1.378,0.699-2.173
		s-0.636-1.479-1.356-1.835c-2.879-1.425-6.106-2.179-9.334-2.179c-5.621,0-10.903,2.188-14.877,6.16
		c-6.016,6.015-7.78,14.939-4.648,22.715l-4.429,4.428l-10.833-10.833l2.992-2.992c0.504-0.504,0.77-1.2,0.729-1.912
		s-0.385-1.372-0.943-1.814L195.05,64.797c-0.995-0.787-2.424-0.704-3.32,0.192l-5.59,5.589c-0.896,0.896-0.979,2.325-0.191,3.319
		l14.981,18.914c0.443,0.56,1.104,0.902,1.815,0.943c0.049,0.003,0.097,0.004,0.145,0.004c0.661,0,1.299-0.262,1.769-0.731
		l2.991-2.993l10.834,10.833l-13.756,13.754c-2.48-0.992-5.157-1.512-7.841-1.512c-5.618,0-10.898,2.188-14.87,6.159
		c-6.395,6.395-7.995,16.123-3.982,24.212c0.356,0.722,1.04,1.227,1.835,1.355c0.794,0.13,1.603-0.13,2.172-0.699l11.906-11.904
		l4.86,4.863L186.906,149c-0.569,0.569-0.83,1.378-0.699,2.172c0.131,0.795,0.636,1.479,1.356,1.836
		c2.879,1.426,6.105,2.178,9.334,2.178c5.621,0,10.903-2.188,14.875-6.159c6.017-6.015,7.782-14.938,4.65-22.715l13.752-13.753
		l2.27,2.271l-1.764,1.764c-0.617,0.618-0.867,1.513-0.66,2.361l1.963,8.023c0.108,0.444,0.338,0.852,0.66,1.175l22.189,22.188
		c1.477,1.478,3.184,2.226,5.072,2.226c2.758,0,5.687-1.588,8.955-4.856c3.043-3.043,4.625-5.784,4.832-8.379
		C273.862,137.212,273.122,135.313,271.491,133.681z M203.106,87.505l-11.855-14.968l2.439-2.439l14.967,11.856l-2.774,2.774
		L203.106,87.505z M231.204,95.215c0.766-0.765,0.951-1.934,0.462-2.897c-3.146-6.202-1.954-13.676,2.964-18.594
		c3.029-3.027,7.058-4.695,11.343-4.695c1.237,0,2.477,0.146,3.68,0.428l-10.896,10.895c-0.469,0.47-0.731,1.104-0.731,1.769
		s0.264,1.299,0.731,1.768l8.398,8.397c0.977,0.977,2.559,0.977,3.535,0l10.902-10.901c1.262,5.324-0.278,11.032-4.277,15.03
		c-3.027,3.026-7.053,4.692-11.334,4.692c-2.519,0-5.027-0.598-7.258-1.729c-0.966-0.488-2.137-0.304-2.898,0.462l-5.65,5.65
		l-4.621-4.622L231.204,95.215z M211.665,123.999c-0.765,0.765-0.95,1.934-0.461,2.898c3.146,6.202,1.953,13.675-2.967,18.592
		c-3.027,3.027-7.056,4.695-11.34,4.695c-1.239,0-2.477-0.146-3.681-0.428l10.894-10.895c0.978-0.978,0.978-2.56,0-3.535
		l-8.396-8.398c-0.47-0.469-1.104-0.731-1.769-0.731s-1.299,0.264-1.768,0.731l-10.904,10.902
		c-1.262-5.323,0.279-11.03,4.277-15.028c3.027-3.026,7.053-4.694,11.335-4.694c2.517,0,5.025,0.599,7.257,1.729
		c0.964,0.49,2.134,0.303,2.898-0.462l14.976-14.975l4.622,4.622L211.665,123.999z M265.325,144.172
		c-2.951,2.951-4.663,3.393-5.42,3.393c-0.384,0-0.865-0.091-1.537-0.762l-21.693-21.692l-1.463-5.979l2.532-2.534
		c0.001-0.001,0.002-0.001,0.003-0.002s0.002-0.002,0.002-0.003l2.534-2.534l5.979,1.464c3.14,3.141,20.205,20.211,21.692,21.695
		c0.585,0.586,0.804,1.081,0.753,1.712C268.648,139.684,268.128,141.37,265.325,144.172z"
        />
        <path
          d="M32.986,187.688c0.298-0.459,0.626-0.908,0.974-1.35c11.457-14.568,48.94-19.146,61.757-19.146h51.188
		c0.041,0.002,0.08,0.002,0.115,0h11.743c1.104,0,2.079-0.727,2.396-1.785l6.23-20.864c0.226-0.756,0.081-1.575-0.392-2.208
		c-0.472-0.634-1.215-1.007-2.005-1.007h-10.969c-1.072,0-2.024,0.684-2.368,1.698l-6.486,19.166H98.345
		c6.178-5.604,16.604-15.131,21.16-19.685c6.563-6.566,10.666-9.313,19.098-9.313c1.381,0,2.5-1.119,2.5-2.5s-1.119-2.5-2.5-2.5
		c-11.014,0-16.327,4.467-22.633,10.775c-5.92,5.916-22.532,20.925-25.367,23.482c-13.621,0.934-50.51,5.064-61.811,22.51
		c-2.354,3.613-3.789,7.619-4.263,11.906c-0.011,0.09-0.017,0.182-0.017,0.273V212.6c0,1.383,1.119,2.5,2.5,2.5h16.562
		c1.25,11.715,11.188,20.869,23.229,20.869c9.699,0,18.484-6.094,21.861-15.166c0.481-1.295-0.177-2.732-1.471-3.215
		c-1.291-0.482-2.732,0.176-3.215,1.471c-2.652,7.123-9.555,11.91-17.178,11.91c-9.72,0-17.678-7.596-18.303-17.16
		c0.2-0.359,0.324-0.768,0.324-1.209c0-0.439-0.123-0.848-0.323-1.207c0.628-9.561,8.585-17.15,18.302-17.15
		c7.606,0,14.511,4.779,17.18,11.893c0.376,1.002,1.326,1.611,2.335,1.615c0.294,0,0.593-0.043,0.884-0.152
		c1.293-0.484,1.948-1.928,1.463-3.219c-3.396-9.055-12.182-15.137-21.86-15.137c-12.037,0-21.974,9.148-23.228,20.857H29.513
		v-12.816C29.917,193.832,31.083,190.605,32.986,187.688z M155.817,146.328h5.82l-4.737,15.864h-6.453L155.817,146.328z"
        />
        <path
          d="M201.122,210.1H98.872c-1.381,0-2.5,1.119-2.5,2.5c0,0.002,0.002,0.006,0.002,0.008
		c0.002,1.379,1.119,2.492,2.498,2.492h102.25c1.379,0,2.496-1.113,2.5-2.492c0-0.002,0-0.006,0-0.008
		C203.622,211.219,202.503,210.1,201.122,210.1z"
        />
        <path
          d="M272.987,162.191h-98.774c-1.382,0-2.5,1.119-2.5,2.5s1.118,2.5,2.5,2.5h96.274v19.146v16.518
		c-0.296,3.67-6.041,6.65-8.039,7.406c-1.005,0.377-1.615,1.332-1.615,2.346c0,0.289,0.043,0.586,0.151,0.873
		c0.376,1.004,1.329,1.623,2.341,1.623c0.292,0,0.588-0.051,0.877-0.158c1.105-0.414,10.81-4.266,11.28-11.848
		c0.003-0.051,0.005-0.104,0.005-0.154v-38.25C275.487,163.311,274.368,162.191,272.987,162.191z"
        />
        <path
          d="M234.249,194.244c7.603,0,14.506,4.777,17.179,11.893c0.375,1.002,1.327,1.613,2.336,1.615
		c0.294,0,0.592-0.043,0.883-0.154c1.293-0.484,1.947-1.926,1.461-3.219c-3.399-9.053-12.185-15.135-21.856-15.135
		c-12.885,0-23.365,10.479-23.365,23.361c0,12.885,10.48,23.365,23.365,23.365c9.697,0,18.482-6.094,21.859-15.166
		c0.481-1.295-0.177-2.732-1.471-3.215c-1.29-0.482-2.732,0.176-3.215,1.471c-2.652,7.123-9.555,11.91-17.176,11.91
		c-10.127,0-18.364-8.236-18.364-18.365C215.884,202.48,224.122,194.244,234.249,194.244z"
        />
      </g>
    </SvgIcon>
  );
};

export default IconCarDamage;
