import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconTrafficAccident = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g fill={getIconColor(active, theme)}>
        <path
          d="M96.506,187.616h13.914c1.236,10.074,9.83,17.906,20.235,17.906c8.472,0,16.145-5.322,19.089-13.244
		c0.481-1.294-0.178-2.733-1.472-3.214c-1.294-0.483-2.732,0.177-3.214,1.472c-2.222,5.973-8.01,9.986-14.403,9.986
		c-8.121,0-14.778-6.32-15.349-14.297c0.167-0.336,0.27-0.709,0.27-1.109s-0.103-0.773-0.27-1.108
		c0.574-7.976,7.229-14.294,15.349-14.294c6.378,0,12.167,4.01,14.405,9.977c0.376,1.003,1.328,1.611,2.337,1.615
		c0.294,0,0.591-0.044,0.882-0.152c1.293-0.485,1.948-1.927,1.463-3.219c-2.967-7.907-10.638-13.221-19.087-13.221
		c-10.401,0-18.995,7.83-20.235,17.902H99.006v-10.627c0.341-2.897,1.321-5.608,2.923-8.063c0.3-0.463,0.62-0.911,0.957-1.352
		c10.217-13.304,37.131-16.076,44.019-16.076h62.665c1.382,0,2.5-1.119,2.5-2.5v-17.903c0-1.382-1.118-2.5-2.5-2.5
		c-1.381,0-2.5,1.118-2.5,2.5v15.403h-56.995c5.36-4.868,13.741-12.537,17.492-16.288c5.363-5.361,8.703-7.888,16.138-7.888h62.942
		l16.173,16.489c0.969,0.984,2.55,0.999,3.536,0.033c0.984-0.967,1.001-2.55,0.033-3.535l-16.907-17.238
		c-0.472-0.479-1.113-0.749-1.785-0.749h-63.992c-9.577,0-14.194,3.876-19.673,9.352c-4.985,4.983-18.839,17.508-21.625,20.021
		c-12.107,0.931-35.579,5.474-44.671,19.508c-2.052,3.148-3.301,6.637-3.714,10.369c-0.012,0.092-0.017,0.184-0.017,0.275v13.27
		C94.006,186.497,95.124,187.616,96.506,187.616z"
        />
        <path
          d="M155.674,185.11c0,0.003,0,0.007,0,0.01c0.006,1.376,1.123,2.49,2.5,2.49h70.623
		c1.377,0,2.493-1.114,2.498-2.49c0-0.003,0.002-0.007,0.002-0.01c0-1.381-1.119-2.5-2.5-2.5h-70.623
		C156.793,182.61,155.674,183.729,155.674,185.11z"
        />
        <path
          d="M290.469,141.499h-67.644c-1.381,0-2.5,1.119-2.5,2.5s1.119,2.5,2.5,2.5h65.144v16.076v14.158
		c-0.254,2.958-5.016,5.413-6.673,6.042c-1.005,0.376-1.614,1.331-1.614,2.345c0,0.291,0.045,0.586,0.151,0.874
		c0.376,1.004,1.329,1.623,2.341,1.623c0.292,0,0.59-0.052,0.878-0.16c0.97-0.363,9.489-3.747,9.911-10.478
		c0.003-0.053,0.005-0.104,0.005-0.156v-32.824C292.969,142.618,291.85,141.499,290.469,141.499z"
        />
        <path
          d="M257.222,169.714c6.379,0,12.171,4.011,14.413,9.978c0.375,1.002,1.326,1.614,2.337,1.614
		c0.294,0,0.591-0.044,0.882-0.153c1.293-0.485,1.947-1.927,1.461-3.219c-2.97-7.906-10.643-13.22-19.093-13.22
		c-11.252,0-20.405,9.154-20.405,20.406c0,11.25,9.153,20.402,20.405,20.402c8.47,0,16.144-5.321,19.096-13.242
		c0.481-1.294-0.176-2.733-1.47-3.216c-1.295-0.484-2.733,0.176-3.217,1.47c-2.227,5.975-8.018,9.988-14.409,9.988
		c-8.494,0-15.405-6.909-15.405-15.402C241.816,176.625,248.728,169.714,257.222,169.714z"
        />
        <path
          d="M9.533,187.61h19.229c1.377,0,2.493-1.114,2.499-2.49c0-0.003,0.001-0.007,0.001-0.01
		c0-1.381-1.119-2.5-2.5-2.5H9.533c-1.381,0-2.5,1.119-2.5,2.5S8.152,187.61,9.533,187.61z"
        />
        <path
          d="M9.533,146.499h78.4v16.076v14.157c-0.254,2.959-5.016,5.414-6.672,6.043
		c-1.006,0.376-1.615,1.331-1.614,2.345c0,0.291,0.044,0.586,0.15,0.874c0.376,1.004,1.329,1.623,2.341,1.623
		c0.293,0,0.59-0.052,0.879-0.16c0.97-0.363,9.488-3.747,9.91-10.478c0.003-0.053,0.005-0.104,0.005-0.156v-32.824
		c0-1.381-1.118-2.5-2.5-2.5H9.533c-1.381,0-2.5,1.119-2.5,2.5S8.152,146.499,9.533,146.499z"
        />
        <path
          d="M57.187,169.714c6.379,0,12.171,4.011,14.413,9.978c0.375,1.002,1.326,1.614,2.337,1.614
		c0.294,0,0.591-0.044,0.882-0.153c1.293-0.485,1.947-1.927,1.461-3.219c-2.97-7.906-10.643-13.22-19.093-13.22
		c-11.252,0-20.405,9.154-20.405,20.406c0,11.25,9.153,20.402,20.405,20.402c8.47,0,16.144-5.321,19.096-13.242
		c0.481-1.294-0.176-2.733-1.47-3.216c-1.294-0.484-2.733,0.176-3.217,1.47c-2.227,5.975-8.018,9.988-14.409,9.988
		c-8.494,0-15.405-6.909-15.405-15.402C41.781,176.625,48.692,169.714,57.187,169.714z"
        />
        <path
          d="M290.465,217.653H9.532c-1.381,0-2.5,1.119-2.5,2.5s1.119,2.5,2.5,2.5h280.933c1.382,0,2.5-1.119,2.5-2.5
		S291.847,217.653,290.465,217.653z"
        />
      </g>
      <g>
        {!active && (
          <>
            <path
              fill="#137F7B"
              d="M102.885,162.575c-0.338,0.439-0.657,0.889-0.957,1.352c-1.602,2.454-2.582,5.165-2.924,8.063v10.627
		h11.415c1.24-10.072,9.833-17.902,20.235-17.902c8.449,0,16.12,5.313,19.087,13.221c0.485,1.292-0.17,2.732-1.463,3.219
		c-0.291,0.108-0.588,0.151-0.882,0.151v3.815h8.277c0-0.003-0.002-0.007-0.002-0.01c0-1.381,1.119-2.5,2.5-2.5h70.623
		c1.382,0,2.5,1.119,2.5,2.5c0,0.003,0,0.007,0,0.01h5.521c0-11.252,9.153-20.406,20.405-20.406c8.449,0,16.123,5.313,19.093,13.22
		c0.486,1.291-0.168,2.732-1.461,3.219c-0.291,0.109-0.588,0.153-0.882,0.153v3.814h5.711c-0.001-1.014,0.608-1.969,1.613-2.345
		c1.657-0.629,6.418-3.084,6.673-6.042v-14.158L102.885,162.575L102.885,162.575z"
            />
            <path
              fill="#137F7B"
              d="M9.533,182.61h19.229c1.381,0,2.5,1.119,2.5,2.5c0,0.003-0.001,0.007-0.001,0.01h5.521
		c0-11.252,9.153-20.406,20.405-20.406c8.45,0,16.123,5.313,19.093,13.22c0.486,1.292-0.168,2.732-1.461,3.219
		c-0.291,0.109-0.588,0.153-0.882,0.153v3.814h5.711c-0.001-1.014,0.609-1.969,1.614-2.345c1.657-0.629,6.418-3.084,6.672-6.043
		v-14.157H9.535v20.035H9.533z"
            />
          </>
        )}
        <path
          fill="#137F7B"
          d="M99.84,116.521l15.059-23.55l-4.966,22.733c-0.175,0.795,0.051,1.625,0.602,2.226
		c0.552,0.599,1.355,0.896,2.166,0.786l12.72-1.666l-17.321,10.259c-0.793,0.471-1.265,1.339-1.225,2.261
		c0.041,0.921,0.585,1.745,1.416,2.145l11.866,5.694l-15.268,3.707c-1.343,0.326-2.166,1.678-1.841,3.02
		c0.278,1.145,1.302,1.911,2.429,1.911c0.194,0,0.394-0.022,0.592-0.07l22.074-5.36c1.026-0.25,1.785-1.118,1.896-2.169
		s-0.452-2.058-1.404-2.515l-13.966-6.702l23.792-14.091c1.021-0.604,1.478-1.845,1.089-2.968c-0.388-1.122-1.52-1.814-2.688-1.662
		l-21.285,2.789l7.191-32.92c0.259-1.183-0.368-2.381-1.487-2.844c-1.118-0.46-2.408-0.057-3.063,0.964l-20.636,32.271
		L81.946,89.644c-0.704-0.949-1.977-1.276-3.048-0.787c-1.074,0.489-1.659,1.665-1.402,2.816l5.701,25.611
		c0.301,1.348,1.637,2.2,2.982,1.896c1.348-0.3,2.197-1.636,1.896-2.982l-3.141-14.109l10.785,14.573
		c0.488,0.662,1.285,1.043,2.095,1.012C98.64,117.646,99.396,117.215,99.84,116.521z"
        />
      </g>
    </SvgIcon>
  );
};
export default IconTrafficAccident;
