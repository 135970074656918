/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React, { useEffect, useState } from "react";
import LandingPageTeaser from "../../../../../components/Teaser/LandingPageTeaser";
import Container from "../../../../../components/Container/Container";
import LpContractAdvocardMeta from "./LpContractAdvocardMeta";
import GreyBackgroundWrapper from "../../../../../components/BackgroundWrapper/GreyBackgroundWrapper";
import { useCustomer } from "../../../../../provider/Customer/CustomerProvider";
import AdvocardFlexCheckin, { createFlexCaseRequest } from "../../../../../components/Checkin/AdvocardFlexCheckin/AdvocardFlexCheckin";
import LayoutAdvocardFlex from "./LayoutAdvocardFlex";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import HowItWorksSectionAdvocardFlex from "./HowItWorksSectionAdvocardFlex";
import BookACall from "./BookACall";
import FlexAiChatAgent from "./FlexAiChatAgent";
import { Button } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import LpAdvocardFlexStyle from "./LpAdvocardFlexStyle";

const LpAdvocardFlex = () => {
  const [isValidUser, setIsValidUser] = useState(false);
  const [createCaseRequest, setCreateCaseRequest] = useState<createFlexCaseRequest | null>(null);
  const { isLoggedIn } = useCustomer();

  useEffect(() => {
    if (isValidUser) {
      window.scrollTo(0, 0);
    }
  }, [isValidUser]);

  const validateCallback = (success: boolean, createRequest: createFlexCaseRequest) => {
    if (success) {
      setIsValidUser(true);
      setCreateCaseRequest(createRequest);
    }
  };
  return (
    <LayoutAdvocardFlex minimal={!isLoggedIn} clickableLogo={isLoggedIn}>
      <LpContractAdvocardMeta />
      {!isValidUser && (
        <>
          <LandingPageTeaser
            teaserButtonText={"Jetzt starten"}
            headline={" ADVOCARD-360°- FlexService"}
            secondHeadline={"Der Immer-Aktuell-Service zur Beantwortung rechtlicher Fragen"}
            subheadline={
              "Legalbird bietet Ihnen als ADVOCARD-360°-Kunden präventive Unterstützung und zielgerichtete Informationen zu" +
              " wechselnden aktuellen Themen. Der Service ist in Ihrem Rechtsschutzbeitrag enthalten. Sind Sie kein" +
              " ADVOCARD-360°-Kunde? Dann rufen Sie uns einfach an: Tel. 040 237310."
            }
            teaserButtonLink={"#top-checkin"}
          >
            <Container>
              <Grid container alignContent={"center"} alignItems={"center"} justifyContent={"space-between"} wrap={"nowrap"}>
                <Grid item xs={12}>
                  <Typography textAlign={"center"} variant={"h3"} pb={3}>
                    Aktuell: Fragen zur neuen Grundsteuer?
                  </Typography>
                  <Typography textAlign={"center"} variant={"subtitle2"}>
                    Seit 01. Januar 2025 gilt eine neue Berechnungsgrundlage für die Grundsteuer. Sind alle Hauseigentümer betroffen? Können Vermieter die
                    Kosten auf die Mieter umlegen? Ob als Eigentümer, Vermieter oder Mieter- jetzt offene Fragen klären.
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </LandingPageTeaser>
          <GreyBackgroundWrapper>
            <HowItWorksSectionAdvocardFlex />
          </GreyBackgroundWrapper>
          <Container>
            <AdvocardFlexCheckin idPrefix={"top"} mode={"validate"} validateCallback={validateCallback} />
          </Container>
        </>
      )}
      {isValidUser && createCaseRequest && (
        <>
          <LandingPageTeaser
            hasTeaserButton={false}
            headline={" ADVOCARD-360°- FlexService"}
            secondHeadline={"Der Immer-Aktuell-Service zur Beantwortung rechtlicher Fragen"}
            subheadline={
              "Legalbird bietet Ihnen als ADVOCARD-360°-Kunden präventive Unterstützung und zielgerichtete Informationen zu" + " wechselnden aktuellen Themen."
            }
          >
            <Container sx={{ p: 0 }}>
              <Grid item container xs={12} justifyContent="center" spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm="auto">
                  <Button component={HashLink} variant="contained" to="#frage-stellen" sx={LpAdvocardFlexStyle.button}>
                    Ihre Frage stellen
                  </Button>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <Button component={HashLink} variant="contained" to="#erstberatung" sx={LpAdvocardFlexStyle.button}>
                    Erstberatung buchen
                  </Button>
                </Grid>
              </Grid>
              <Grid container alignContent={"center"} alignItems={"center"} justifyContent={"space-between"} wrap={"nowrap"}>
                <Grid item xs={12}>
                  <Typography textAlign={"center"} variant={"h3"} pb={3}>
                    Aktuell: Fragen zur neuen Grundsteuer?
                  </Typography>
                  <Typography textAlign={"center"} variant={"subtitle2"}>
                    Ob als Eigentümer/Vermieter oder Mieter- jetzt offene Fragen direkt hier oder durch Buchung einer Erstberatung klären. Alternativ werfen Sie
                    auch gerne einen Blick in unseren{" "}
                    <a href={"/lp/advocard/flex-grundsteuer"} target={"_blank"}>
                      Grundsteuer-FAQ
                    </a>
                    .
                  </Typography>
                  <Grid item xs={12}>
                    <FlexAiChatAgent postalCode={createCaseRequest.postalCode} insurancePolicyNumber={createCaseRequest.insurancePolicyNumber} />
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </LandingPageTeaser>
          <GreyBackgroundWrapper>
            <BookACall createCaseRequest={createCaseRequest} />
          </GreyBackgroundWrapper>
        </>
      )}
    </LayoutAdvocardFlex>
  );
};

export default LpAdvocardFlex;
