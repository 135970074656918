/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import Container from "../../../../../components/Container/Container";
import { useCustomer } from "../../../../../provider/Customer/CustomerProvider";
import LayoutAdvocardFlex from "./LayoutAdvocardFlex";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LpPropertyTaxFaqMeta from "./LpPropertyTaxFaqMeta";

const LpPropertyTaxFaq = () => {
  const { isLoggedIn } = useCustomer();

  return (
    <LayoutAdvocardFlex minimal={!isLoggedIn} clickableLogo={isLoggedIn}>
      <LpPropertyTaxFaqMeta />

      <Container>
        <Grid container alignContent={"center"} alignItems={"center"} justifyContent={"space-between"} wrap={"nowrap"}>
          <Grid item xs={12}>
            <Typography textAlign={"center"} variant={"h1"} sx={{ mb: 5 }}>
              Häufige Fragen und Antworten zur Grundsteuerreform
            </Typography>

            <Typography variant={"h4"}>1. Was ist die Grundsteuerreform und warum wurde sie eingeführt?</Typography>
            <Typography>
              <ul>
                <li>
                  <strong>Hintergrund:</strong>
                  <ul>
                    <li>
                      Die bisherige Berechnung der Grundsteuer basierte auf jahrzehntealten Einheitswerten, die nicht mehr den aktuellen Marktverhältnissen
                      entsprachen.
                    </li>
                    <li>
                      Das Bundesverfassungsgericht erklärte diese Bewertungsgrundlagen 2018 für verfassungswidrig, da sie zu Ungleichbehandlungen führten.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Ziel der Reform:</strong>
                  <ul>
                    <li>Eine Neubewertung aller Grundstücke, um eine gerechtere und zeitgemäße Besteuerung zu gewährleisten.</li>
                    <li>Durch die Reform soll sichergestellt werden, dass der Steuerbetrag den tatsächlichen Wert des Grundbesitzes widerspiegelt.</li>
                  </ul>
                </li>
                <li>
                  <strong>Ergänzende Information:</strong>
                  <ul>
                    <li>
                      Das Bundesfinanzministerium erläutert, dass die Reform eine Aktualisierung der Bewertungsverfahren darstellt und den Kommunen mehr
                      Gestaltungsspielraum (über den Hebesatz) einräumt.
                    </li>
                  </ul>
                </li>
              </ul>
            </Typography>

            <Typography variant={"h4"} sx={{ mt: 3 }}>
              2. Welche Bescheide zur Grundsteuer erhalte ich als Eigentümer?
            </Typography>
            <Typography>
              <ul>
                <li>
                  <strong>Drei Bescheidarten:</strong>
                  <ol type={"a"}>
                    <li>
                      <strong>Bescheid über den Grundsteuerwert</strong> – Dieser Bescheid wird vom Finanzamt erstellt und legt den ermittelten Wert Ihres
                      Grundstücks fest.
                    </li>
                    <li>
                      <strong>Bescheid über den Grundsteuermessbetrag</strong> – Ebenfalls vom Finanzamt verschickt, ergibt sich dieser Betrag aus der
                      Multiplikation des Grundsteuerwerts mit der Steuermesszahl.
                    </li>
                    <li>
                      <strong>Grundsteuerbescheid mit Zahlungsaufforderung</strong> – Dieser Bescheid wird von Ihrer Gemeinde oder Stadt verschickt und
                      beinhaltet den letztlich zu zahlenden Steuerbetrag, der zusätzlich durch den individuell festgelegten Hebesatz beeinflusst wird.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Hinweis:</strong>
                  <ul>
                    <li>
                      Viele der Bescheide (insbesondere Grundsteuerwert und Grundsteuermessbetrag) wurden bereits seit August 2022 versandt. Der abschließende
                      Grundsteuerbescheid, der den endgültigen Betrag festlegt, erfolgt im Laufe des Jahres 2025.
                    </li>
                  </ul>
                </li>
              </ul>
            </Typography>

            <Typography variant={"h4"} sx={{ mt: 3 }}>
              3. Wie wird die neue Grundsteuer berechnet?
            </Typography>
            <Typography>
              <ul>
                <li>
                  <strong>Berechnungsformel:</strong>
                  <ul>
                    <li>
                      Die Grundsteuer wird aus dem Grundsteuerwert multipliziert mit der <strong>Steuermesszahl</strong> und dem <strong>Hebesatz</strong> der
                      jeweiligen Kommune ermittelt:
                      <br />
                      <br />
                      <strong>Grundsteuer = Grundsteuerwert × Steuermesszahl × Hebesatz</strong>
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Einflussfaktoren:</strong>
                  <ul>
                    <li>
                      <strong>Grundsteuerwert:</strong> Bestimmt sich aus Faktoren wie Grundstücksfläche, Lage und Nutzung.
                    </li>
                    <li>
                      <strong>Steuermesszahl:</strong> Ein bundesweit einheitlicher Faktor, der den Wert in einen Messbetrag umwandelt.
                    </li>
                    <li>
                      <strong>Hebesatz:</strong> Wird von der Gemeinde individuell festgelegt und kann regional variieren, sodass auch ähnliche Immobilien in
                      unterschiedlichen Städten unterschiedliche Steuerbeträge zur Folge haben.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Ergänzung:</strong>
                  <ul>
                    <li>
                      Das Bundesfinanzministerium betont, dass durch die kommunale Festlegung des Hebesatzes die Einnahmen aus der Grundsteuer gesteuert werden
                      können.
                    </li>
                  </ul>
                </li>
              </ul>
            </Typography>

            <Typography variant={"h4"} sx={{ mt: 3 }}>
              4. Wann kann ein Grundsteuerbescheid fehlerhaft sein?
            </Typography>
            <Typography>
              <ul>
                <li>
                  <strong>Mögliche Fehlerquellen:</strong>
                  <ul>
                    <li>
                      <strong>Falsche Berechnungsgrundlagen:</strong> Beispielsweise kann ein fehlerhafter Grundsteuerwert oder eine falsche Steuermesszahl
                      verwendet werden.
                    </li>
                    <li>
                      <strong>Unkorrekte Flächenangaben:</strong> Fehlerhafte Angaben zur Grundstücksgröße führen zu fehlerhaften Berechnungen.
                    </li>
                    <li>
                      <strong>Formelle Fehler:</strong> Dazu zählen etwa falsche Adressangaben oder das Fehlen einer nachvollziehbaren Begründung im Bescheid.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Konsequenz:</strong>
                  <ul>
                    <li>Solche Fehler können zu einer zu hohen oder zu niedrigen Steuerfestsetzung führen.</li>
                  </ul>
                </li>
              </ul>
            </Typography>

            <Typography variant={"h4"} sx={{ mt: 3 }}>
              5. Wie kann ich gegen einen fehlerhaften Bescheid vorgehen?
            </Typography>
            <Typography>
              <ul>
                <li>
                  <strong>Widerspruch:</strong>
                  <ul>
                    <li>
                      Eigentümer können innerhalb eines Monats nach Erhalt des Bescheids <strong>schriftlich Widerspruch</strong> beim zuständigen Finanzamt
                      einlegen.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Begrenzung:</strong>
                  <ul>
                    <li>
                      Oft liegen die Fristen für den Widerspruch gegen den Bescheid über den Grundsteuerwert und den Grundsteuermessbetrag bereits ab, sodass in
                      diesen Fällen keine Korrektur mehr möglich ist.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Gerichtliche Schritte:</strong>
                  <ul>
                    <li>
                      Falls das Finanzamt nicht korrigiert, bleibt der Weg über eine <strong>Klage vor dem Finanzgericht</strong> als letzte Option.
                    </li>
                  </ul>
                </li>
              </ul>
            </Typography>

            <Typography variant={"h4"} sx={{ mt: 3 }}>
              6. Können Vermieter die Grundsteuer auf Mieter umlegen?
            </Typography>
            <Typography>
              <ul>
                <li>
                  <strong>Rechtliche Grundlage:</strong>
                  <ul>
                    <li>
                      Vermieter dürfen die Grundsteuer als <strong>umlagefähige Betriebskosten</strong> an Mieter weitergeben – vorausgesetzt, dies ist im
                      Mietvertrag ausdrücklich vereinbart.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Verteilung:</strong>
                  <ul>
                    <li>
                      In der Regel erfolgt die Umlage nach der <strong>Wohnfläche</strong>.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Wichtig für Mieter:</strong>
                  <ul>
                    <li>Mieter sollten ihre Nebenkostenabrechnung genau prüfen und bei Zweifeln Einsicht in die Berechnungsunterlagen verlangen.</li>
                  </ul>
                </li>
              </ul>
            </Typography>

            <Typography variant={"h4"} sx={{ mt: 3 }}>
              7. Was können Mieter tun, wenn sie Zweifel an der Abrechnung haben?
            </Typography>
            <Typography>
              <ul>
                <li>
                  <strong>Prüfung der Abrechnung:</strong>
                  <ul>
                    <li>Mieter haben das Recht, die Nebenkostenabrechnung zu überprüfen und die Belege einzusehen.</li>
                  </ul>
                </li>
                <li>
                  <strong>Widerspruch:</strong>
                  <ul>
                    <li>
                      Falls die Umlage unzulässig oder fehlerhaft erscheint, kann innerhalb von <strong>12 Monaten</strong> schriftlich Widerspruch beim
                      Vermieter eingelegt werden.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Recht auf Nachprüfung:</strong>
                  <ul>
                    <li>Sollte Unklarheit über die korrekte Berechnung bestehen, ist der Vermieter verpflichtet, alle relevanten Unterlagen vorzulegen.</li>
                  </ul>
                </li>
              </ul>
            </Typography>

            <Typography variant={"h4"} sx={{ mt: 3 }}>
              8. Gibt es regionale Unterschiede bei der Grundsteuer?
            </Typography>
            <Typography>
              <ul>
                <li>
                  <strong>Unterschiedliche Berechnungsmethoden:</strong>
                  <ul>
                    <li>Einige Bundesländer haben eigene Bewertungsmodelle entwickelt, was zu abweichenden Ergebnissen führen kann.</li>
                  </ul>
                </li>
                <li>
                  <strong>Hebesatz:</strong>
                  <ul>
                    <li>
                      Der von der jeweiligen Kommune festgelegte Hebesatz variiert erheblich, sodass die tatsächliche Steuerlast auch von der Lage abhängt.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Ergänzende Information:</strong>
                  <ul>
                    <li>
                      Das Bundesfinanzministerium weist darauf hin, dass diese Unterschiede bewusst als Instrument zur Steuerung kommunaler Einnahmen genutzt
                      werden.
                    </li>
                  </ul>
                </li>
              </ul>
            </Typography>

            <Typography variant={"h4"} sx={{ mt: 3 }}>
              9. Wann tritt die neue Grundsteuer in Kraft?
            </Typography>
            <Typography>
              <ul>
                <li>
                  <strong>Datum:</strong>
                  <ul>
                    <li>
                      Die Reform gilt ab dem <strong>1. Januar 2025.</strong>
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Bescheidsversand:</strong>
                  <ul>
                    <li>
                      Während einige Bescheide bereits Ende 2024 versandt wurden, erfolgen die endgültigen Zahlungsaufforderungen im Laufe des Jahres 2025.
                    </li>
                  </ul>
                </li>
              </ul>
            </Typography>

            <Typography sx={{ mt: 5 }}>
              <strong>Disclaimer:</strong> Die bereitgestellten Informationen stellen keine rechtsgültige Beratung dar und ersetzen nicht die individuelle
              Beratung durch einen qualifizierten Rechtsanwalt.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </LayoutAdvocardFlex>
  );
};

export default LpPropertyTaxFaq;
