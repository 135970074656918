/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import contractIcon from "../../../assets/icons/progress/icon_progressbar_vertrag.svg";
import documentsIcon from "../../../assets/icons/progress/icon_progressbar_vertrag_upload.svg";

const progressBarsteps = [
  {
    key: "contract",
    name: "Ihre Angaben",
    icon: contractIcon,
  },
  {
    key: "documents",
    name: "Unterlagen",
    icon: documentsIcon,
  },
];

export default progressBarsteps;
