/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import Grid from "@mui/material/Grid";
import powerOfAttorneyStyle from "./powerOfAttorneyStyle";
import Paper from "@mui/material/Paper";
import FrankRiedelImage from "../../../assets/img/jobs/frankriedel.jpg";
import {getPowerOfAttorneyOverviewSteps} from "../../../services/contractLaw/powerOfAttorneyInformationLists";
import HighlightBox from "../../Box/HighlightBox/HighlightBox";
import HintBox from "../../Box/HintBox/HintBox";
import {AbstractCase} from "../../../types/Entities/AbstractCase";
import FormSubmitProps from "../../../types/FormSubmitProps";

type PowerOfAttorneyHintBoxProps = {
  product: AbstractCase;
  submitProps: FormSubmitProps;
};

export default function PowerOfAttorneyIntroHintBox({ product, submitProps }: PowerOfAttorneyHintBoxProps) {

  if (submitProps.isMinimal) {
    return null;
  }

  return (
    <HintBox headline={"So geht es weiter"}>
      <Grid container>
        <Grid item sm={2} xs={12} sx={powerOfAttorneyStyle.imgCircleContainer}>
          <Paper elevation={4} sx={powerOfAttorneyStyle.imageCircleContainer}>
            <img src={FrankRiedelImage} alt={"Rechtsanwalt Frank Riedel"} />
          </Paper>
        </Grid>
        <Grid item sm={10} xs={12} sx={powerOfAttorneyStyle.text}>
          <ol>
            {getPowerOfAttorneyOverviewSteps(product).map((step) => (
              <li key={step}>{step}</li>
            ))}
          </ol>
        </Grid>
      </Grid>
      {product.insurance.iIWPI && (
        <HighlightBox textColor={"defaultText"} headline={""}>
          {`Sie sind bei der ${product.insurance.insurance.name} rechtsschutzversichert, daher ist das weitere Vorgehen für Sie komplett kostenlos!`}
        </HighlightBox>
      )}
      {product.insurance.iIWPI === false && product.legalExpenseInsurance && (
        <HighlightBox textColor={"defaultText"} headline={""}>
          Sie haben angegeben, dass Sie bei {product.insurance.insurance.name} rechtsschutzversichert sind, daher müssen Sie sich über Kosten keinerlei
          Sorgen machen! Wir stellen Deckungsanfrage bei Ihrer Versicherung und kümmern uns um alles Weitere bezüglich der Abrechnung.
        </HighlightBox>
      )}
    </HintBox>
  );
};
