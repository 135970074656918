import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconSidewaysCollision = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g fill={getIconColor(active, theme)}>
        <path
          d="M129.613,152.36l-4.509-1.767c-0.151-9.358-0.662-18.363-1.979-23.756
		c-2.804-11.454-15.951-12.413-29.18-12.413s-26.378,0.959-29.181,12.414c-1.302,5.327-1.815,14.181-1.975,23.42l-5.364,2.102
		c-1.142,0.445-1.705,1.732-1.258,2.874c0.344,0.875,1.18,1.409,2.066,1.409c0.269,0,0.542-0.049,0.808-0.152l3.695-1.446
		c-0.037,7.028,0.087,13.969,0.187,19.457c0.059,3.281,0.108,6.115,0.108,8.077c0,8.466,0.399,30.382,1.522,43.578
		c-0.022,0.158-0.032,0.318-0.021,0.484c0.014,0.188,0.056,0.363,0.111,0.534c0.345,3.778,0.754,6.742,1.235,8.298
		c2.47,7.951,9.267,8.661,28.063,8.661c18.794,0,25.59-0.71,28.061-8.662c0.481-1.554,0.892-4.515,1.235-8.29
		c0.059-0.173,0.102-0.352,0.114-0.541c0.012-0.168,0.001-0.332-0.023-0.49c1.125-13.195,1.525-35.107,1.525-43.572
		c0-1.961,0.051-4.793,0.109-8.073c0.097-5.402,0.218-12.213,0.187-19.128l2.843,1.113c0.267,0.104,0.539,0.152,0.809,0.152
		c0.887,0,1.724-0.534,2.065-1.409C131.317,154.094,130.754,152.807,129.613,152.36z M120.53,174.427
		c-0.06,3.301-0.11,6.151-0.11,8.152c0,10.729-0.465,29.591-1.365,41.534l-3.991-0.283c-0.277-2.256-1.034-6.271-3.072-11.659
		c0.543-4.092,0.894-13.877,0.894-22.063c0.002-11.481-0.556-17.226-1.274-20.198c2.845-1.981,7.409-6.153,9.114-13.292
		C120.733,163.059,120.621,169.369,120.53,174.427z M109.105,166.252c-3.106-1.271-11.542-1.417-15.161-1.417
		c-3.489,0-11.455,0.136-14.806,1.285c-2.696-2.349-7.672-7.55-8.166-14.291c6.335-1.932,12.304-2.033,22.973-2.033
		c10.665,0,16.633,0.103,22.964,2.03C116.51,159.946,111.766,164.396,109.105,166.252z M107.712,211.001
		c-3.132,0.229-9.87,0.677-13.677,0.518c-0.029-0.001-0.061-0.002-0.09-0.002c-0.03,0-0.062,0.001-0.09,0.002
		c-3.802,0.158-10.543-0.288-13.678-0.518c-0.929-7.504-1.23-36.424,0.607-40.775c1.41-0.431,5.998-0.954,13.158-0.954
		c7.166,0,11.756,0.524,13.159,0.953C108.942,174.571,108.639,203.5,107.712,211.001z M79.451,215.396
		c2.57,0.193,8.357,0.592,12.623,0.592c0.001,0,0.001,0,0.001,0c0.669,0,1.299-0.011,1.87-0.032c0.571,0.021,1.201,0.032,1.87,0.032
		c4.265,0,10.052-0.397,12.624-0.592c1.268,3.663,1.859,6.502,2.126,8.293h-33.24C77.59,221.896,78.182,219.059,79.451,215.396z
		 M118.815,127.893c0.271,1.109,0.505,2.4,0.707,3.824c-7.073-0.764-9.286-6.212-9.722-11.517
		C114.727,121.446,117.803,123.753,118.815,127.893z M69.074,127.894c1.013-4.141,4.089-6.446,9.015-7.693
		c-0.435,5.306-2.646,10.754-9.722,11.518C68.57,130.293,68.803,129.003,69.074,127.894z M67.864,136.193
		c8.858-0.528,14.257-6.692,14.707-16.799c3.259-0.394,7.054-0.531,11.374-0.531s8.115,0.139,11.374,0.531
		c0.45,10.105,5.847,16.271,14.706,16.799c0.32,3.666,0.506,7.853,0.604,12.203c-7.912-2.895-14.558-3.039-26.685-3.039
		s-18.772,0.146-26.687,3.039C67.357,144.046,67.543,139.859,67.864,136.193z M67.471,182.579c0-2.002-0.052-4.854-0.11-8.156
		c-0.095-5.262-0.213-11.878-0.191-18.588c1.746,6.58,6.476,11.421,9.195,13.757c-0.76,2.875-1.356,8.608-1.356,20.518
		c0,8.187,0.351,17.969,0.895,22.062c-2.039,5.389-2.796,9.404-3.072,11.66l-3.992,0.283
		C67.936,212.169,67.471,193.31,67.471,182.579z M70.119,234.16c-0.329-1.063-0.628-3.031-0.896-5.627l3.952-0.28
		c0.768,3.35,1.45,7.615,1.314,10.128C71.723,237.533,70.752,236.198,70.119,234.16z M93.945,239.698
		c-6.873,0-11.685-0.141-15.102-0.513c0.323-3.39-0.48-8.021-1.146-11.06h32.496c-0.667,3.039-1.473,7.669-1.148,11.059
		C105.628,239.559,100.818,239.698,93.945,239.698z M117.768,234.158c-0.634,2.04-1.605,3.375-4.375,4.224
		c-0.144-2.504,0.537-6.729,1.32-10.129l3.952,0.28C118.397,231.128,118.098,233.098,117.768,234.158z"
        />
        <path
          d="M210.281,130.711c-0.061-0.174-0.135-0.345-0.241-0.506c-0.095-0.144-0.205-0.269-0.323-0.381
		c-7.151-11.139-20.184-28.749-25.344-35.463c-1.193-1.554-2.875-3.823-4.822-6.453c-3.221-4.348-7.278-9.825-11.523-15.294
		l2.931-0.85c1.177-0.341,1.854-1.57,1.515-2.748c-0.341-1.177-1.573-1.854-2.748-1.514l-4.651,1.347
		c-5.825-7.325-11.717-14.149-16.049-17.622c-2.527-2.023-5.399-3.052-8.538-3.052c-7.256,0-14.926,5.434-22.157,10.997
		c-10.489,8.063-20.332,16.835-15.565,27.629c2.215,5.017,7.203,12.347,12.709,19.768l-2.972,4.939
		c-0.632,1.05-0.293,2.413,0.757,3.044c0.358,0.216,0.752,0.318,1.143,0.318c0.753,0,1.486-0.384,1.902-1.075l2.046-3.401
		c4.256,5.596,8.586,11.022,12.01,15.314c2.046,2.563,3.812,4.777,5.007,6.332c5.16,6.711,18.84,23.843,27.774,33.618
		c0.077,0.141,0.169,0.275,0.28,0.4c0.125,0.141,0.267,0.254,0.414,0.354c2.574,2.782,4.705,4.881,6.035,5.818
		c1.999,1.41,3.889,2.067,5.944,2.067c0.001,0,0.001,0,0.001,0c4.801,0,10.294-3.628,21.576-12.305
		c14.904-11.458,19.859-16.164,16.963-23.97C213.79,136.51,212.309,133.915,210.281,130.711z M209.301,145.582
		c-1.64-1.896-3.677-5.661-5.127-8.832l3.301-2.186c1.37,2.223,2.333,3.967,2.717,5.005
		C210.934,141.572,210.979,143.223,209.301,145.582z M175.985,90.549c1.96,2.646,3.651,4.934,4.87,6.516
		c6.543,8.515,17.668,23.749,24.23,33.761l-3.334,2.208c-1.594-1.62-4.64-4.34-9.54-7.367c-2.042-3.536-7.647-11.402-12.567-17.813
		c-7.404-9.647-11.392-13.812-13.694-15.608c0.802-3.654,1.429-10.785-3.026-18.309c0.061,0.005,0.118,0.016,0.18,0.016
		c0.103,0,0.205-0.029,0.31-0.044C168.016,79.791,172.5,85.844,175.985,90.549z M171.753,155.922
		c-0.882-1.583-2.143-4.193-3.37-7.87c2.496-1.636,8.565-5.677,11.829-8.394c3.464-2.456,8.927-7.281,11.15-9.275
		c3.237,2.131,5.438,4.021,6.741,5.279L171.753,155.922z M123.488,102.045c3.74-4.983,8.354-8.649,16.396-14.831
		c7.854-6.042,12.72-9.659,18.58-12.04c4.336,6.542,3.855,12.817,3.199,15.932c-5.05,1.712-19.945,13.118-23.408,17.86
		C134.772,108.434,128.006,106.816,123.488,102.045z M162.757,95.38c4.085,2.289,21.509,25.442,25.352,31.961
		c-2.347,2.095-7.435,6.563-10.527,8.745c-0.05,0.035-0.099,0.072-0.146,0.111c-2.899,2.428-8.526,6.196-11.154,7.927
		c-5.316-5.391-23.212-28.167-24.378-32.705C144.057,108.194,159.096,96.631,162.757,95.38z M140.484,52.613
		c2.134,0,4.02,0.68,5.765,2.078c0.894,0.715,1.864,1.597,2.896,2.603c-6.077,3.714-11.16,0.732-14.741-3.214
		C136.523,53.17,138.578,52.613,140.484,52.613z M106.819,85.01c-1.724-3.9-0.691-7.604,2.454-11.597
		c2.891,4.47,4.459,10.141-0.684,15.062C107.883,87.222,107.281,86.056,106.819,85.01z M110.92,92.327
		c6.699-5.821,7.219-13.997,1.414-22.281c2.346-2.299,5.271-4.722,8.697-7.354c2.982-2.295,6.167-4.654,9.314-6.518
		c4.024,4.787,8.631,7.233,13.295,7.233c2.897,0,5.817-0.951,8.637-2.864c2.486,2.708,5.182,5.91,7.908,9.296
		c-0.4,0.126-0.8,0.254-1.188,0.387c-0.344,0.036-0.68,0.155-0.984,0.357c-6.899,2.55-12.116,6.408-20.834,13.113
		c-8.721,6.704-13.791,10.756-18.024,16.767c-0.271,0.24-0.468,0.53-0.591,0.849c-0.231,0.345-0.458,0.699-0.686,1.059
		C115.305,98.86,112.899,95.428,110.92,92.327z M138.88,129.337c-1.22-1.585-2.998-3.813-5.057-6.395
		c-3.715-4.656-8.513-10.671-13.089-16.736c0.079-0.135,0.158-0.264,0.238-0.396c5.551,5.357,13.129,7.065,16.951,7.603
		c1.083,2.699,4.072,7.618,11.521,17.302c4.991,6.489,11.232,14.035,14.157,16.946c1.668,5.515,3.517,9.158,4.674,11.113
		l-2.992,2.66C157.288,152.516,145.42,137.845,138.88,129.337z M175.813,169.866c-1.112,0-2.157-0.389-3.389-1.256
		c-0.908-0.642-2.346-2.019-4.14-3.908l2.961-2.633c2.65,2.188,5.793,5.155,7.218,7.229
		C177.465,169.672,176.587,169.866,175.813,169.866z M194.684,158.48c-5.228,4.021-9.185,6.935-12.272,8.812
		c-1.808-2.887-5.274-6.072-7.658-8.078l25.761-19.807c1.323,2.814,3.508,6.977,5.83,9.465
		C203.862,151.249,200.135,154.292,194.684,158.48z"
        />
        <path
          d="M148.673,162.222c-1.34,0-2.427,1.087-2.427,2.427v100.104c0,1.34,1.087,2.427,2.427,2.427
		c1.341,0,2.427-1.087,2.427-2.427V164.648C151.1,163.309,150.014,162.222,148.673,162.222z"
        />
        <path
          d="M148.673,45.171c1.341,0,2.427-1.087,2.427-2.427V24.771c0-1.34-1.086-2.427-2.427-2.427
		c-1.34,0-2.427,1.087-2.427,2.427v17.974C146.246,44.084,147.333,45.171,148.673,45.171z"
        />
        <path
          d="M210.143,262.314c-1.34,0-2.427,1.088-2.427,2.428v5c0,1.34,1.087,2.427,2.427,2.427
		c1.341,0,2.428-1.087,2.428-2.427v-5C212.57,263.401,211.483,262.314,210.143,262.314z"
        />
        <path
          d="M210.143,245.266c-1.34,0-2.427,1.087-2.427,2.428v8.523c0,1.34,1.087,2.427,2.427,2.427
		c1.341,0,2.428-1.087,2.428-2.427v-8.523C212.57,246.353,211.483,245.266,210.143,245.266z"
        />
        <path
          d="M210.143,194.117c-1.34,0-2.427,1.087-2.427,2.427v8.524c0,1.34,1.087,2.427,2.427,2.427
		c1.341,0,2.428-1.087,2.428-2.427v-8.524C212.57,195.204,211.483,194.117,210.143,194.117z"
        />
        <path
          d="M209.626,190.46c1.312-0.281,2.146-1.571,1.864-2.882c-0.646-3.014-1.523-5.892-2.605-8.556
		c-0.508-1.241-1.927-1.837-3.162-1.334c-1.241,0.506-1.84,1.922-1.334,3.162c0.978,2.402,1.77,5.009,2.355,7.744
		c0.245,1.14,1.251,1.918,2.371,1.918C209.283,190.514,209.454,190.496,209.626,190.46z"
        />
        <path
          d="M210.143,228.216c-1.34,0-2.427,1.087-2.427,2.427v8.524c0,1.34,1.087,2.427,2.427,2.427
		c1.341,0,2.428-1.087,2.428-2.427v-8.524C212.57,229.303,211.483,228.216,210.143,228.216z"
        />
        <path
          d="M210.143,211.166c-1.34,0-2.427,1.087-2.427,2.427v8.525c0,1.34,1.087,2.427,2.427,2.427
		c1.341,0,2.428-1.087,2.428-2.427v-8.525C212.57,212.253,211.483,211.166,210.143,211.166z"
        />
        <path
          d="M204.584,171.063c-1.06-1.501-2.227-2.895-3.47-4.144c-0.944-0.95-2.481-0.954-3.432-0.008
		c-0.949,0.944-0.953,2.48-0.009,3.432c1.052,1.057,2.043,2.24,2.944,3.52c0.474,0.67,1.224,1.026,1.984,1.026
		c0.482,0,0.973-0.145,1.396-0.443C205.096,173.671,205.356,172.157,204.584,171.063z"
        />
        <path
          d="M33.376,16.328c-1.907,0-3.454,1.547-3.454,3.454v260.436c0,1.907,1.547,3.454,3.454,3.454
		s3.454-1.547,3.454-3.454V19.782C36.83,17.875,35.283,16.328,33.376,16.328z"
        />
        <path
          d="M266.624,16.328c-1.907,0-3.454,1.547-3.454,3.454v260.436c0,1.907,1.547,3.454,3.454,3.454
		s3.454-1.547,3.454-3.454V19.782C270.078,17.875,268.531,16.328,266.624,16.328z"
        />
        <path
          d="M93.944,257.314c-1.341,0-2.427,1.088-2.427,2.428v10c0,1.34,1.086,2.427,2.427,2.427
		c1.34,0,2.427-1.087,2.427-2.427v-10C96.371,258.401,95.284,257.314,93.944,257.314z"
        />
        <path
          d="M93.944,246.028c-1.341,0-2.427,1.087-2.427,2.427v1.286c0,1.34,1.086,2.427,2.427,2.427
		c1.34,0,2.427-1.087,2.427-2.427v-1.286C96.371,247.115,95.284,246.028,93.944,246.028z"
        />
      </g>
      <g>
        <path
          fill="#137F7B"
          d="M93.944,169.271c-7.16,0-11.748,0.523-13.158,0.954c-1.839,4.353-1.536,33.271-0.606,40.775
		c3.134,0.229,9.875,0.676,13.677,0.518c0.028-0.001,0.06-0.002,0.09-0.002s0.062,0.001,0.09,0.002
		c3.808,0.159,10.545-0.286,13.677-0.518c0.929-7.501,1.23-36.43-0.606-40.776C105.7,169.796,101.11,169.271,93.944,169.271z"
        />
        <path
          fill="#137F7B"
          d="M162.757,95.38c-3.661,1.251-18.698,12.814-20.854,16.039c1.166,4.538,19.062,27.313,24.378,32.705
		c2.628-1.729,8.255-5.499,11.154-7.927c0.047-0.039,0.096-0.076,0.146-0.111c3.094-2.182,8.181-6.65,10.527-8.745
		C184.266,120.822,166.842,97.669,162.757,95.38z"
        />
        <path
          fill="#137F7B"
          d="M100.066,95.347c-0.596-1.409-2.221-2.067-3.629-1.477l-13.781,5.81l5.797-10.968
		c0.479-0.903,0.419-1.996-0.152-2.843c-0.571-0.849-1.564-1.309-2.58-1.206l-18.716,1.924l10.064-5.588
		c0.788-0.438,1.313-1.232,1.409-2.129c0.096-0.897-0.252-1.784-0.932-2.378l-16.38-14.325l24.704,9.432
		c0.853,0.324,1.81,0.209,2.561-0.307c0.75-0.517,1.197-1.37,1.197-2.281V52.153L94.982,64.2c0.621,1.396,2.252,2.025,3.656,1.405
		c1.396-0.62,2.027-2.258,1.406-3.655L89.392,37.972c-0.532-1.194-1.828-1.853-3.106-1.585c-1.279,0.271-2.193,1.401-2.193,2.71
		v25.893l-35.42-13.522c-1.256-0.478-2.67,0.01-3.36,1.16c-0.692,1.149-0.46,2.63,0.55,3.513L70.937,78.07L52.503,88.304
		c-1.148,0.64-1.691,1.998-1.298,3.253c0.396,1.255,1.618,2.071,2.926,1.924l27.004-2.775l-7.003,13.249
		c-0.547,1.035-0.384,2.302,0.407,3.165c0.536,0.585,1.281,0.898,2.043,0.897c0.361,0,0.729-0.069,1.075-0.217l20.933-8.823
		C99.999,98.381,100.66,96.757,100.066,95.347z"
        />
      </g>
    </SvgIcon>
  );
};
export default IconSidewaysCollision;
