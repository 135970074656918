import React, { useState } from "react";
import FileUploadSection from "../Common/FileUploadSection/FileUploadSection";
import { useForm } from "../../../provider/Form/FormProvider";
import Typography from "@mui/material/Typography";
import { Box, Grid, Paper } from "@mui/material";
import FormLegend from "../Common/FormLegend/FormLegend";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import useMediaObjectCombinationRequest from "../../../hooks/useMediaObjectCombinationRequest";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import wizardStyle from "../wizardStyle";
import Hidden from "@mui/material/Hidden";
import MultipleFileUpload from "../Common/FileUpload/MultipleFileUpload";
import { filterMediaObjectsByFieldname } from "../../../services/mediaObjects";
import FormSubmit from "../Common/FormSubmit/FormSubmit";
import FormFader from "../Common/Fader/FormFader";
import { fetchCollection, queryKeys } from "../../../services/reactQuery/reactQueryService";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import FormSubmitProps from "../../../types/FormSubmitProps";

type DocumentsProps = {
  product: AbstractCase;
  submitProps: FormSubmitProps;
};
export default function Documents({ product, submitProps }: DocumentsProps) {
  const showDamageEstimationDocument = ["vehicle", "vehicleAndPerson"].includes(product.typeOfDamage) && product.damageEstimationDocumentType !== "noDocument";

  const urlParams = {
    product: product.name,
    productId: product.id,
  };
  const { data: mediaObjectCollection, isLoading } = useQuery(queryKeys.collection("/media_objects", urlParams), () =>
    fetchCollection("/media_objects", urlParams)
  );

  let mediaObjectCombinationRequestTypes = ["accidentSketch"];

  if (product.policeInvolved) {
    mediaObjectCombinationRequestTypes.push("policeReport");
  }
  if (showDamageEstimationDocument) {
    mediaObjectCombinationRequestTypes.push("damageEstimationDocument");
  }

  const { mediaObjectCombinationRequests, isLoadingMediaObjectCombinationRequest, updateAction } = useMediaObjectCombinationRequest(
    product,
    mediaObjectCombinationRequestTypes
  );
  const queryClient = useQueryClient();
  const { handleSubmit } = useForm();
  const refreshMediaObjectCollection = () => queryClient.invalidateQueries(queryKeys.collection("/media_objects", urlParams));

  const [isBlocked, setIsBlocked] = useState(false);

  if (isLoadingMediaObjectCombinationRequest || isLoading) {
    return <LegalbirdLoader />;
  }

  const damageEstimationDocumentConfig: Record<string, { headline: string; helperText: string }> = {
    costEstimate: {
      headline: "Kostenvoranschlag",
      helperText: "Bitte laden Sie hier den Kostenvoranschlag hoch, den Sie zur Abschätzung der voraussichtlichen Reparaturkosten erhalten haben.",
    },
    assessment: {
      headline: "Gutachten",
      helperText: "Bitte laden Sie hier das Sachverständigengutachten hoch, welches Sie zur Abschätzung der voraussichtlichen Reparaturkosten eingeholt haben.",
    },
    repairInvoice: {
      headline: "Reparaturrechnung",
      helperText: "Bitte laden Sie hier die Rechnung hoch, welche Sie von der Werkstatt für die Reparatur Ihres Fahrzeugs erhalten haben.",
    },
  };

  return (
    <FormFader>
      <Paper sx={{ padding: "2rem" }}>
        <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant="h4" sx={wizardStyle.headline}>
              Unterlagen
            </Typography>
          </Hidden>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"} spacing={2}>
            <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
              <FormLegend>
                Damit wir Ihren Fall bestmöglich bearbeiten können, lassen Sie uns hier bitte alle Unterlagen zukommen, die Ihnen aktuell vorliegen. Sollten
                Ihnen noch nicht alle Unterlagen vorliegen, können Sie diese natürlich jederzeit nachreichen.
              </FormLegend>
            </Grid>
            {product.policeInvolved && (
              <Grid item xs={12}>
                <FileUploadSection
                  productEntity={product}
                  mediaObjectCombinationRequest={mediaObjectCombinationRequests.policeReport}
                  temporaryMediaObjectsFieldname={"policeReportFiles"}
                  setIsBlocked={setIsBlocked}
                  isBlocked={isBlocked}
                  product={product.name}
                  productEntityId={product.id}
                  updateAction={updateAction}
                  fieldName={"policeReport"}
                  description={"Polizeibericht"}
                  headline={"Polizeibericht"}
                  helperText={"Sofern die Polizei den Unfall aufgenommen hat, laden Sie hier bitte das Dokument hoch, welches Sie erhalten haben."}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FileUploadSection
                productEntity={product}
                mediaObjectCombinationRequest={mediaObjectCombinationRequests.accidentSketch}
                temporaryMediaObjectsFieldname={"accidentSketchFiles"}
                setIsBlocked={setIsBlocked}
                isBlocked={isBlocked}
                product={product.name}
                productEntityId={product.id}
                updateAction={updateAction}
                fieldName={"accidentSketch"}
                description={"Unfallskizze"}
                headline={"Unfallskizze"}
                helperText={
                  "Eine grobe handschriftliche oder digitale Skizze des Unfallhergangs kann uns sehr dabei helfen, das Geschehen zu rekonstruieren und Ihren Fall besser einzuschätzen."
                }
              />
            </Grid>
            {showDamageEstimationDocument && (
              <Grid item xs={12}>
                <FileUploadSection
                  productEntity={product}
                  mediaObjectCombinationRequest={mediaObjectCombinationRequests.damageEstimationDocument}
                  temporaryMediaObjectsFieldname={"damageEstimationDocumentFiles"}
                  setIsBlocked={setIsBlocked}
                  isBlocked={isBlocked}
                  product={product.name}
                  productEntityId={product.id}
                  updateAction={updateAction}
                  fieldName={"damageEstimationDocument"}
                  description={damageEstimationDocumentConfig[product.damageEstimationDocumentType].headline}
                  headline={damageEstimationDocumentConfig[product.damageEstimationDocumentType].headline}
                  helperText={damageEstimationDocumentConfig[product.damageEstimationDocumentType].helperText}
                />
              </Grid>
            )}
            {["person", "vehicleAndPerson"].includes(product.typeOfDamage) && (
              <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
                <Typography variant="h5">Dokumente zum Personenschaden</Typography>
                <Typography paragraph>
                  Sofern Ihnen Dokumente vorliegen, die die gesundheitlichen Schäden belegen, stellen Sie uns diese bitte zur Verfügung. Dies können zum
                  Beispiel Arztberichte sein.
                </Typography>
                <MultipleFileUpload
                  product={product}
                  fieldname={"injuryDocumentation"}
                  description={"Arztbericht oder Krankschreibung"}
                  mediaObjectCollection={filterMediaObjectsByFieldname(mediaObjectCollection, "contractPartnerCorrespondence")}
                  fetchMediaObjects={refreshMediaObjectCollection}
                />
              </Grid>
            )}
            <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
              <Typography variant="h5">Fotos</Typography>
              <Typography paragraph>
                Jegliche fotografische Darstellung zum Unfall oder zu den Schäden kann dabei helfen, Ihren Fall nachzuvollziehen und Ihre Forderungen zu
                belegen.
              </Typography>
              <MultipleFileUpload
                product={product}
                fieldname={"trafficAccidentPhotos"}
                description={"Gerne können Sie uns Fotos der Unfallstelle, Ihres beschädigten Fahrzeugs oder der beschädigten Sache hochladen."}
                mediaObjectCollection={filterMediaObjectsByFieldname(mediaObjectCollection, "contractPartnerCorrespondence")}
                fetchMediaObjects={refreshMediaObjectCollection}
              />
            </Grid>
          </Grid>
          <br />
          <br />
          <FormSubmit {...submitProps} />
        </Box>
      </Paper>
    </FormFader>
  );
}
