import React from "react";
import SummaryDetailsItem from "./SummaryDetailsItem";
import SummaryDetailsProps from "../../../../types/SummaryDetailsProps";

type SummaryDetailsPersonItemsProps = {
  basePath: string;
  formUrl: SummaryDetailsProps["formUrl"];
  caseData: SummaryDetailsProps["caseData"];
};

export default function SummaryDetailsPersonItems({ basePath, caseData, formUrl }: SummaryDetailsPersonItemsProps) {
  return (
    <>
      <SummaryDetailsItem fieldPath={basePath + ".gender"} formUrl={formUrl} caseData={caseData} />
      <SummaryDetailsItem fieldPath={basePath + ".givenName"} formUrl={formUrl} caseData={caseData} />
      <SummaryDetailsItem fieldPath={basePath + ".familyName"} formUrl={formUrl} caseData={caseData} />
      <SummaryDetailsItem fieldPath={basePath + ".residenceAddress.streetAddress"} formUrl={formUrl} caseData={caseData} />
      <SummaryDetailsItem fieldPath={basePath + ".residenceAddress.postalCode"} formUrl={formUrl} caseData={caseData} />
      <SummaryDetailsItem fieldPath={basePath + ".residenceAddress.addressLocality"} formUrl={formUrl} caseData={caseData} />
    </>
  );
}
