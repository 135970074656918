/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import Paper from "@mui/material/Paper";
import FrankRiedelImage from "../../../assets/img/jobs/frankriedel.jpg";
import Grid from "@mui/material/Grid";
import powerOfAttorneyStyle from "./powerOfAttorneyStyle";
import HintBox from "../../Box/HintBox/HintBox";
import {AbstractCase} from "../../../types/Entities/AbstractCase";
import FormSubmitProps from "../../../types/FormSubmitProps";
import PowerOfAttorneyIntroHighlightBox from "./PowerOfAttorneyIntroHighlightBox";

type PowerOfAttorneyIntroHintBoxProps = {
  product: AbstractCase;
  submitProps: FormSubmitProps;
};

export default function PowerOfAttorneyIntroHintBox({ product, submitProps }: PowerOfAttorneyIntroHintBoxProps) {
  return (
    <>
      {!submitProps.isMinimal && (
        <HintBox headline={"So geht es weiter"}>
          <Grid container>
            <Grid item sm={2} xs={12} sx={powerOfAttorneyStyle.imgCircleContainer}>
              <Paper elevation={4} sx={powerOfAttorneyStyle.imageCircleContainer}>
                <img src={FrankRiedelImage} alt={"Rechtsanwalt Frank Riedel"} />
              </Paper>
            </Grid>
            <Grid item sm={10} xs={12} sx={powerOfAttorneyStyle.text}>
              <ol>
                <li>Sie erteilen uns die Vollmacht, damit wir Sie vertreten dürfen.</li>
                <li>Wir prüfen Ihren Verkehrsunfall unverbindlich für Sie und melden uns dann umgehend bei Ihnen zurück.</li>
                <li>
                  Wir setzen uns mit dem KFZ-Haftpflichtversicherer Ihres Unfallgegners in Verbindung und versuchen, Ihre Forderung außergerichtlich geltend zu
                  machen.
                </li>
                <li>Sollte eine außergerichtliche Klärung nicht möglich sein, vertreten wir Sie selbstverständlich auch im gerichtlichen Verfahren.</li>
              </ol>
            </Grid>
          </Grid>
          <PowerOfAttorneyIntroHighlightBox product={product} />
        </HintBox>
      )}
    </>
  );
}
