/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import HighlightBox from "../../Box/HighlightBox/HighlightBox";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import { Box } from "@mui/material";

type PowerOfAttorneyOutroHintBoxProps = {
  product: AbstractCase;
};

export default function PowerOfAttorneyOutroHighlightBox({ product }: PowerOfAttorneyOutroHintBoxProps) {
  if (product.insurance.iIWPI || !product.legalExpenseInsurance) {
    return null;
  }

  return (
    <Box textAlign={"center"}>
      <HighlightBox textColor={"defaultText"} headline={""}>
        Sie haben angegeben, dass Sie bei {product.insurance.insurance.name} rechtsschutzversichert sind, daher müssen Sie sich über Kosten keinerlei Sorgen
        machen! Wir stellen Deckungsanfrage bei Ihrer Versicherung und kümmern uns um alles Weitere bezüglich der Abrechnung.
      </HighlightBox>
    </Box>
  );
}
