/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import HighlightBox from "../../Box/HighlightBox/HighlightBox";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import { Typography } from "@mui/material";

type PowerOfAttorneyOutroHintBoxProps = {
  product: AbstractCase;
};

export default function PowerOfAttorneyIntroHighlightBox({ product }: PowerOfAttorneyOutroHintBoxProps) {
  return (
    <HighlightBox textColor={"defaultText"} headline={""}>
      <Typography sx={{ textAlign: "center" }}>
        {product.legalExpenseInsurance ? (
          <>
            Wir prüfen Ihren Fall kostenlos und unverbindlich für Sie. Sofern Ihr Unfallgegner den Unfall allein verschuldet hat, werden unsere
            Rechtsanwaltsgebühren vollständig von dessen KFZ-Haftpflichtversicherung getragen. Sollte ein beidseitiges Verschulden des Unfalls vorliegen, kommen
            auf Sie ebenfalls keine Kosten zu, da Sie bei der {product.insurance.insurance.name} rechtsschutzversichert sind.
          </>
        ) : (
          <>
            Wir prüfen Ihren Fall kostenlos und unverbindlich für Sie! Sofern Ihr Unfallgegner den Unfall allein verschuldet hat, werden unsere
            Rechtsanwaltsgebühren vollständig von dessen KFZ-Haftpflichtversicherung getragen. Sollte ein beidseitiges Verschulden des Unfalls vorliegen, kommen
            auf Sie im außergerichtlichen Verfahren ebenfalls keinerlei Kosten zu. Sollte es außergerichtlich zu keiner Einigung kommen, besprechen wir zunächst
            erneut Ihre Erfolgswahrscheinlichkeiten und die Kosten, die in einem gerichtlichen Verfahren auf Sie zukommen könnten. Sie können dann entscheiden,
            ob Sie ein Gerichtsverfahren wünschen oder nicht.
          </>
        )}
      </Typography>
    </HighlightBox>
  );
}
