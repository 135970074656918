import Button from "@mui/material/Button";
import { HashLink } from "react-router-hash-link";
import React from "react";
import { getCaseLink, getProductData } from "../productService";
import { AbstractCase } from "../../types/Entities/AbstractCase";
import { StatusConfig } from "../../components/Serviceworld/StatusBox/StatusBox";
import {
  STATUS_ACTION_REQUIRED,
  STATUS_CHECKED,
  STATUS_COMPLETED,
  STATUS_CREATED,
  STATUS_DATA_COMPLETED,
  STATUS_DATA_COMPLETED_LAWFIRM,
  STATUS_EVALUATE_JUDGEMENT,
  STATUS_EXTRAJUDICIAL_COMMUNICATION_SENT,
  STATUS_EXTRAJUDICIAL_REACTION_RECEIVED,
  STATUS_IN_PROGRESS,
  STATUS_LAWSUIT,
  STATUS_LAWSUIT_COMPLETED,
  STATUS_PREPARE_LAWSUIT,
  STATUS_TRIAL,
  STATUS_WAITING_FOR_FEEDBACK,
} from "../contractLaw/leadStatus";
import { Stack, Typography } from "@mui/material";
import { APPLICATION_STATUS_SUMMARY } from "../contractLaw/applicationStatus";
import { Link } from "react-router-dom";

const getContent = (trafficAccident: AbstractCase) => {
  switch (trafficAccident.leadStatus) {
    case STATUS_CREATED:
      if (trafficAccident.applicationStatus > APPLICATION_STATUS_SUMMARY) {
        return {
          header: "Ihre Vollmacht wird benötigt",
          text: "Um Ihren Fall zu prüfen und Ihr Anliegen als Ihr gesetzlicher Vertreter durchsetzen zu können, benötigen wir noch Ihre Vollmacht.",
          buttonTarget: getCaseLink(trafficAccident) + "/antrag",
          buttonLabel: "Zur Vollmacht",
        };
      }
      return {
        header: "Fall schildern und Vorgehen festlegen",
        text: "Sie entscheiden, ob Sie nur eine kurze Beratung benötigen, oder wir direkt für Sie tätig werden sollen - zum Beispiel durch ein Schreiben an die Gegenseite. Sie wünschen eine rechtliche Vertretung durch uns? Dann können Sie hier auch direkt Ihre Vollmacht unterzeichnen.",
        buttonTarget: getCaseLink(trafficAccident) + "/antrag",
        buttonLabel: "Weiteres Vorgehen festlegen",
      };
    case STATUS_DATA_COMPLETED:
    case STATUS_WAITING_FOR_FEEDBACK:
      return {
        header: "Ihre Angaben sind vollständig",
        text: "Vielen Dank für die Schilderung Ihres Falls. Wir prüfen nun Ihren Fall und leiten zeitnah weitere Schritte für Sie ein!",
      };
    case STATUS_DATA_COMPLETED_LAWFIRM:
      return {
        header: "Ihre Angaben sind vollständig",
        text: "Vielen Dank für die Schilderung Ihres Falls. Wir prüfen nun Ihren Fall und leiten zeitnah weitere Schritte für Sie ein!",
      };
    case STATUS_CHECKED:
      return {
        header: "Ihr Fall wurde geprüft",
        text: "Wir haben Ihren Fall geprüft und leiten jetzt weitere Schritte ein. Durch Sie ist nun zunächst nichts weiter zu veranlassen. Sie werden selbstverständlich immer direkt informiert, wenn es Neuigkeiten zu Ihrem Fall gibt!",
      };
    case STATUS_IN_PROGRESS:
      return {
        header: "Ihr Fall wird bearbeitet",
        text: "Wir setzen uns dafür ein, dass Ihr Anliegen zeitnah und professionell geklärt wird. Sie werden jederzeit von uns über Neuigkeiten informiert.",
      };
    case STATUS_EXTRAJUDICIAL_COMMUNICATION_SENT:
      return {
        header: "Schreiben an KFZ-Haftpflicht",
        text:
          "Wir haben ein Schreiben an die KFZ-Haftpflichtversicherung der Gegenseite geschickt und informieren Sie" +
          " umgehend, sobald diese auf das Schreiben reagiert. Sollte keine Reaktion stattfinden, kommen wir auf Sie zu," +
          " um Ihre Möglichkeiten zu besprechen.",
      };
    case STATUS_EXTRAJUDICIAL_REACTION_RECEIVED:
    case STATUS_ACTION_REQUIRED:
      if (!!trafficAccident.finalClaimStatusClient)
        return {
          header:
            trafficAccident.extrajudicialReaction === "noReaction"
              ? "Keine Reaktion der KFZ-Haftpflichtversicherung der Gegenseite"
              : "Die Reaktion der KFZ-Haftpflichtversicherung der Gegenseite liegt vor",
          text:
            "Wir haben Ihre Rückmeldung erhalten und werden Ihren Fall entsprechend weiter bearbeiten. Sie werden" +
            " über das weitere Vorgehen wie gewohnt per Nachricht und Ihre Servicewelt informiert.",
        };

      if (trafficAccident.extrajudicialReaction === "noReaction")
        return {
          header: "Keine Reaktion der KFZ-Haftpflichtversicherung der Gegenseite",
          text:
            "Wir haben leider keine Reaktion der KFZ-Haftpflichtversicherung der Gegenseite erhalten. Bitte" +
            " teilen Sie uns mit, wie Sie weiter vorgehen möchten.",
          buttonTarget: getCaseLink(trafficAccident) + "/rueckmeldung",
          buttonLabel: "Vorgehen festlegen",
        };
      return {
        header: "Die Reaktion der KFZ-Haftpflichtversicherung der Gegenseite liegt vor",
        text: "Bitte prüfen Sie hier die Reaktion der KFZ-Haftpflichtversicherung der Gegenseite und teilen Sie uns mit, wie Sie weiter vorgehen möchten.",
        buttonTarget: getCaseLink(trafficAccident) + "/rueckmeldung",
        buttonLabel: "Vorgehen festlegen",
      };
    case STATUS_PREPARE_LAWSUIT:
      return {
        header: "Ihre Klage wird vorbereitet",
        text: "Ihrerseits ist derzeit nichts weiter zu tun. Wir melden uns bei Neuigkeiten und Klärungsbedarf direkt bei Ihnen.",
      };
    case STATUS_LAWSUIT:
      return {
        header: "Gerichtsverfahren läuft",
        text: "Ihr Fall befindet sich im gerichtlichen Verfahren. Wir kümmern uns um alles Weitere und melden uns bei Neuigkeiten und Klärungsbedarf immer direkt bei Ihnen.",
      };
    case STATUS_TRIAL:
      return trafficAccident.courtAppointment?.courtDate?.appointmentDateTime
        ? {
            header: "Ihr Gerichtstermin steht fest",
            text: "Das Gericht hat den Termin für Ihr Verfahren festgesetzt. Weitere Informationen können Sie dem Schreiben entnehmen, das Sie in Ihren Dokumenten finden. Melden Sie sich bei Fragen gerne direkt bei uns!",
          }
        : {
            header: "Warten auf Gerichtstermin",
            text: "Es wird auf ein Ladungsschreiben für Ihren Gerichtstermin gewartet. Sobald dieses eingeht, werden Sie es hier in der Servicewelt finden können.",
          };
    case STATUS_EVALUATE_JUDGEMENT:
      return trafficAccident.nextInstanceCaseBaseData
        ? {
            header: "Berufungsverfahren läuft",
            text: "Sie haben sich dazu entschieden, Rechtsmittel gegen das Urteil einzulegen. Den eigenständigen Fall dazu finden Sie in unter “Meine Fälle” in Ihrer Servicewelt.",
          }
        : {
            header: "Urteilsprüfung",
            text: "Das Urteil in Ihrem Fall liegt vor. Unsere Experten prüfen derzeit sorgfältig die Sinnhaftigkeit und die Erfolgsaussichten einer Berufung.",
          };
    case STATUS_LAWSUIT_COMPLETED:
      return {
        header: "Der Rechtsstreit wurde beendet",
        text: trafficAccident.nextInstanceCaseBaseData
          ? "Ihr Vertragsrechtsfall ist in dieser Instanz beendet. Der Rechtsstreit wird in der nächsthöheren Instanz fortgesetzt. Sie finden dazu eigenständigen Fall in Ihrer Servicewelt."
          : "Ihr Vertragsrechtsfall wurde im gerichtlichen Verfahren entschieden. Wenn keine Partei das Ergebnis anfechtet, ist es rechtskräftig und Sie und die Gegenpartei müssen der Entscheidung des Gerichts Folge leisten.",
      };
    case STATUS_COMPLETED:
      return {
        header: "Ihr Fall ist abgeschlossen",
        text: "Vielen Dank für Ihr Vertrauen und dafür, dass wir Sie unterstützen durften! Sie sind der Meinung, dass ihr Fall nicht abgeschlossen ist oder es sind im Nachgang doch noch Probleme aufgetreten? Dann schreiben Sie uns gerne jederzeit eine Nachricht und wir werden selbstverständlich umgehend für Sie tätig!",
      };
    default:
      return {
        header: "Technischer Fehler",
        text: "Leider kam es scheinbar zu einem technischen Fehler in Ihrem Fall. Bitte melden Sie sich per E-Mail an service@legalbird.de, damit wir Ihnen zeitnah weiterhelfen können.",
      };
  }
};

const getButton = (trafficAccident: AbstractCase) => {
  const content: Record<string, any> = getContent(trafficAccident);
  if (!content.buttonLabel) {
    return null;
  }
  return (
    <Button
      component={Link}
      to={content?.linkType === "external" ? { pathname: content.buttonTarget } : content.buttonTarget}
      target={content?.linkType === "external" ? "_blank" : undefined}
      variant="contained"
      color="primary"
      fullWidth
    >
      {content.buttonLabel}
    </Button>
  );
};

const getPercentage = (trafficAccident: AbstractCase) => {
  return (Math.max(1, trafficAccident.leadStatus) / 13) * 10;
};

const ExtraContent = ({ trafficAccident }: { trafficAccident: AbstractCase }) => {
  if (trafficAccident.leadStatus === STATUS_EXTRAJUDICIAL_COMMUNICATION_SENT) {
    return (
      <Stack spacing={3} sx={{ marginTop: 2 }}>
        <Typography>Die Versicherung hat sich bei Ihnen gemeldet, statt wie erwartet bei uns? Dann laden Sie uns das Schreiben bitte hier hoch:</Typography>
        <Button component={HashLink} to={getCaseLink(trafficAccident) + "#upload"} variant="contained" color="primary" fullWidth>
          Dokument hochladen
        </Button>
      </Stack>
    );
  }

  return null;
};

export const getStatusBoxConfigTrafficAccident = (trafficAccident: AbstractCase) => {
  let statusBoxConfig: StatusConfig = {
    header: "Es ist ein Fehler aufgetreten",
    text: "",
    ctaButton: null,
    productName: "",
    percentage: 100,
  };

  const ctaButton = getButton(trafficAccident);
  const content = getContent(trafficAccident);

  statusBoxConfig.header = content.header;
  statusBoxConfig.text = content.text;
  statusBoxConfig.ctaButton = ctaButton;
  statusBoxConfig.productName = getProductData(trafficAccident.name, "label");
  statusBoxConfig.percentage = getPercentage(trafficAccident);
  statusBoxConfig.extraContent = <ExtraContent trafficAccident={trafficAccident} />;

  return statusBoxConfig;
};
