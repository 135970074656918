/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

const LpAdvocardFlexStyle = {
  button: {
    width: { xs: "100%", sm: "auto" },
    px: 3,
    justifyContent: "center",
    textAlign: "center",
  },
};

export default LpAdvocardFlexStyle;
