/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import * as loginActions from "../../../store/login/actions";
import { useLocation, useNavigate } from "react-router-dom";
import { getLpByLocation } from "../../../services/landingPagesList";
import Typography from "@mui/material/Typography";
import { FormProvider } from "../../../provider/Form/FormProvider";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../../services/reactQuery/reactQueryService";
import { advocardFlexCheckinStyles } from "./advocardFlexCheckinStyle";
import AdvocardFlexCheckinForm from "./AdvocardFlexCheckinForm";
import { useCustomer } from "../../../provider/Customer/CustomerProvider";
import ReactGA from "react-ga4";
import { apiPost } from "../../../services/apiClient";
import { Box } from "@mui/material";
import { getCaseLink } from "../../../services/productService";

type AdvocardFlexCheckinProps = {
  idPrefix: string;
  mode?: "register" | "validate";
  validateCallback?: Function | null;
};

export type createFlexCaseRequest = {
  email: string;
  gclid: string | null;
  contractType: string;
  partyType: string;
  affiliateCode: string | null;
  postalCode: string;
  insurancePolicyNumber: string;
  landingPage: string;
};

const AdvocardFlexCheckin = ({ idPrefix, mode = "register", validateCallback = null }: AdvocardFlexCheckinProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const [submitErrorMessage, setSubmitErrorMessage] = useState("");
  const { isLoggedIn, customer } = useCustomer();
  const initialValues = {
    contractType: "rentalContract",
    partyType: "landlord",
    email: isLoggedIn ? customer!.email : "",
    insurancePolicyNumber: "",
    postalCode: "",
  };

  useEffect(() => {
    if (isLoggedIn) {
      setEmailErrorText("");
    }
  }, [isLoggedIn]);

  const handleSubmit = async ({ values }: { values: Record<string, any> }) => {
    setIsLoading(true);
    setSubmitErrorMessage("");

    if (!isLoggedIn) {
      const emailCheck = await loginActions.checkEmail(values.email.toLowerCase());
      if (emailCheck.hasError) {
        setEmailErrorText(emailCheck.errorText);
        setIsLoading(false);
        return;
      }
    }

    const landingPage = getLpByLocation(location.pathname) || { keyword: location.pathname };

    try {
      const createCaseRequest: createFlexCaseRequest = {
        email: values.email.toLowerCase(),
        gclid: localStorage.getItem("gclid"),
        contractType: values.contractType,
        partyType: values.partyType,
        affiliateCode: localStorage.getItem("gclid"),
        postalCode: values.postalCode,
        insurancePolicyNumber: values.insurancePolicyNumber,
        landingPage: landingPage.keyword,
      };

      if (mode === "validate") {
        const result = await apiPost("/advocard/flex/validate_account", createCaseRequest);

        if (validateCallback) {
          validateCallback(result["status"] === "success", createCaseRequest);
        }
      } else {
        const result = await apiPost("/advocard/flex/create_case", createCaseRequest);

        if (!isLoggedIn) {
          localStorage.setItem("token", result.token);
          ReactGA.event({
            category: "Registration",
            action: "Account created",
          });
        }
        await queryClient.invalidateQueries(queryKeys.collection("/customers"));

        navigate(getCaseLink(result.case) + "/antrag");
      }
    } catch (error) {
      setSubmitErrorMessage(
        "Die angegebenen Informationen konnten nicht verifiziert werden oder Sie sind nicht berechtigt, diesen Service zu nutzen. Gerne beraten wir Sie dazu unter 040 237310."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Paper elevation={4} sx={advocardFlexCheckinStyles.formPaper}>
      <Box id={idPrefix + "-checkin"} sx={advocardFlexCheckinStyles.scrollToAnchor} />
      <Typography variant={"h4"} textAlign={"center"}>
        Jetzt starten
      </Typography>
      <FormProvider initialValues={initialValues} submitAction={handleSubmit}>
        <AdvocardFlexCheckinForm
          isLoading={isLoading}
          emailErrorText={emailErrorText}
          setEmailErrorText={setEmailErrorText}
          externalError={submitErrorMessage}
        />
      </FormProvider>
    </Paper>
  );
};

export default AdvocardFlexCheckin;
